import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectPeople = get('people');
export const selectPeopleList = createSelector(selectPeople, (people) => people.data);
export const selectIsLoading = createSelector(selectPeople, (people) => people.isLoading);
export const selectPeopleInclExternalList = createSelector(
  selectPeople,
  (people) => people.listInclExternal,
);
