import { put, call, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  MutateDeleteProjectCall,
  QueryAllProjectCalls,
} from '../../graphql/queries/projectCalls';
import {
  DELETE_PROJECT_CALL,
  deleteProjectCallError,
  deleteProjectCallSuccess,
  FETCH_ALL_PROJECT_CALLS,
  fetchAllProjectCalls,
  fetchAllProjectCallsError,
  fetchAllProjectCallsSuccess,
} from './projectCallsActions';
import catchErrors from '../../graphql/sagas';

export function* getAllProjectCalls() {
  const response = yield call(axios.post, '/', { query: QueryAllProjectCalls });
  yield put(fetchAllProjectCallsSuccess(response.data.data.calls));
}

export function* deleteProjectCall(action) {
  const response = yield call(axios.post, '/', { query: MutateDeleteProjectCall(action.projectCallId) });
  if (response.data.data.deleteProjectCall === false) {
    throw new Error('Project Call could not be deleted');
  }

  yield put(deleteProjectCallSuccess(response.data));
  yield put(fetchAllProjectCalls());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Project Call deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    DELETE_PROJECT_CALL,
    catchErrors({
      onErrorAction: deleteProjectCallError,
      overrideErrorMessage: 'Unable to delete project call',
      includeTechnicalError: true,
    })(deleteProjectCall),
  );

  yield takeLatest(
    FETCH_ALL_PROJECT_CALLS,
    catchErrors({
      onErrorAction: fetchAllProjectCallsError,
      overrideErrorMessage: 'Unable to retrieve project calls',
    })(getAllProjectCalls),
  );
}
