export const FETCH_USER_ID = () => ({ type: 'FETCH_USER_ID' });
export const FETCH_USER_ID_SUCCESS = (data) => ({ type: 'FETCH_USER_ID_SUCCESS', data });
export const FETCH_USER_ID_ERROR = (error) => ({ type: 'FETCH_USER_ID_ERROR', error });
export const USER_NOT_AUTHENTICATED = () => ({ type: 'USER_NOT_AUTHENTICATED' });
export const INIT_DATA = () => ({ type: 'INIT_DATA' });
export const INIT_DATA_SUCCESS = (data) => ({ type: 'INIT_DATA_SUCCESS', data });
export const INIT_DATA_ERROR = (error) => ({ type: 'INIT_DATA_ERROR', error });

export const FETCH_ALL_YEARS = () => ({ type: 'FETCH_ALL_YEARS' });
export const FETCH_ALL_YEARS_SUCCESS = (data) => ({ type: 'FETCH_ALL_YEARS_SUCCESS', data });
export const FETCH_ALL_YEARS_ERROR = (error) => ({ type: 'FETCH_ALL_YEARS_ERROR', error });

export const LOGOUT = () => ({ type: 'LOGOUT' });
