import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

// const columns = [
//   {
//     name: 'ID',
//     selector: 'id',
//     sortable: true,
//   },
//   {
//     name: 'Acronym',
//     selector: 'acronym',
//     sortable: true,
//   },
//   {
//     name: 'Funder',
//     selector: 'funder.name',
//     sortable: true,
//   },
//   {
//     name: 'Start Date',
//     selector: 'beginDate',
//     sortable: true,
//   },
//   {
//     name: 'End Date',
//     selector: 'endDate',
//     sortable: true,
//   },
// ];

function Settings() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Tab.Container id="left-tabs-example" defaultActiveKey="countries">
              <Card.Header>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="countries">Countries</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="types">Project Types</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="apikeys">API Keys</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="countries">
                    countries
                  </Tab.Pane>
                  <Tab.Pane eventKey="types">
                    to do
                  </Tab.Pane>
                  <Tab.Pane eventKey="apikeys">
                    to do
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>

          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Settings;
