const QueryAllDatesAndPeople = `
{
  allDates
  allPeopleWithoutLists{
    id
    first
    last
    unumber
  }
}`;

export default QueryAllDatesAndPeople;
