import { createAction } from 'redux-actions';

export const CREATE_PROCEEDING = 'CREATE_PROCEEDING';
export const CREATE_PROCEEDING_ERROR = 'CREATE_PROCEEDING_ERROR';
export const CREATE_PROCEEDING_SUCCESS = 'CREATE_PROCEEDING_SUCCESS';
export const FETCH_PROCEEDING = 'FETCH_PROCEEDING';
export const FETCH_PROCEEDING_ERROR = 'FETCH_PROCEEDING_ERROR';
export const FETCH_PROCEEDING_SUCCESS = 'FETCH_PROCEEDING_SUCCESS';
export const LOAD_PROCEEDING_REQUIREMENTS = 'LOAD_REQUIREMENTS_PROCEEDING';
export const LOAD_PROCEEDING_REQUIREMENTS_ERROR = 'LOAD_PROCEEDING_REQUIREMENTS_ERROR';
export const LOAD_PROCEEDING_REQUIREMENTS_SUCCESS = 'LOAD_PROCEEDING_REQUIREMENTS_SUCCESS';
export const UPDATE_PROCEEDING = 'UPDATE_PROCEEDING';
export const UPDATE_PROCEEDING_ERROR = 'UPDATE_PROCEEDING_ERROR';
export const UPDATE_PROCEEDING_SUCCESS = 'UPDATE_PROCEEDING_SUCCESS';

export const createProceeding = createAction(CREATE_PROCEEDING);
export const createProceedingError = createAction(CREATE_PROCEEDING_ERROR);
export const createProceedingSuccess = createAction(CREATE_PROCEEDING_SUCCESS);

export const fetchProceeding = createAction(FETCH_PROCEEDING);
export const fetchProceedingError = createAction(FETCH_PROCEEDING_ERROR);
export const fetchProceedingSuccess = createAction(FETCH_PROCEEDING_SUCCESS);

export const loadProceedingRequirements = createAction(LOAD_PROCEEDING_REQUIREMENTS);
export const loadProceedingRequirementsError = createAction(LOAD_PROCEEDING_REQUIREMENTS_ERROR);
export const loadProceedingRequirementsSuccess = createAction(LOAD_PROCEEDING_REQUIREMENTS_SUCCESS);

export const updateProceeding = createAction(UPDATE_PROCEEDING);
export const updateProceedingError = createAction(UPDATE_PROCEEDING_ERROR);
export const updateProceedingSuccess = createAction(UPDATE_PROCEEDING_SUCCESS);
