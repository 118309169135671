const QueryAllPeople = `
{
    allPeopleWithoutLists{
      id
      first
      last
      unumber
      }
}`;

const QueryAllPeopleInclExternal = `
{
  allPeoplePeople{
    id
    first
    last
    userid
  }
}`;

const QueryPeopleTypes = `
query{
  peopleTypes{
    id
    abbrv
  }
}`;

const QueryPeoplePromoters = `
query{
  peoplePromoters{
    id
    first
    last
  }
}`;

const QueryTeams = `
query{
  teams{
    id
    name
  }
}`;

const QueryAllFilteredPeople = (filter) => {
  let query = `
  query{
    filterPeopleFast(`;
  if (filter.active) {
    query += `  isActive:${filter.active === 'Active'},\n`;
  }
  if (filter.statusType && filter.statusType !== 'Any') {
    query += `  statusType:${filter.statusType},\n`;
  }
  if (filter.startDate && filter.startDate !== '') {
    query += `  startDate:"${filter.startDate}",\n`;
  }

  if (filter.team && filter.team !== '') {
    query += `  teamId:"${filter.team}",\n`;
  }

  query += `  page:0,
    rows:500000
  ) {
    id
    userid
    uNumber
    firstname
    first: firstname
    lastname
    last: lastname
    currentStatus
    role
  }
}`;
  return query;
};

export default {
  QueryAllPeople,
  QueryAllPeopleInclExternal,
  QueryAllFilteredPeople,
  QueryPeopleTypes,
  QueryPeoplePromoters,
  QueryTeams,
};
