export const journalsByYear = (year) => `
{
  Category(years: ${year}){
    journalVenue{
      category
      count
    }
  }
}`;

export const conferencesByYear = (year) => `
{
  Category(years: ${year}){
    conferencesVenue{
      category
      count
    }
  }
}`;

export const statusHistoryCountByYear = (year) => `
query{
  statusHistoryCount(year: ${year}){
    count
    peopleType{
      id
      name
      kultype
    }
  }
}`;

export const visitorsByYearBetween = (startDate, endDate) => `
query{
  visitorsAmountBetween(startDate: ${startDate}, endDate: ${endDate})
}`;

export const bestPapersByYear = (year) => `
query{
  bestPaperAmounts(year: ${year})
}`;

export const awardsWonByYear = (year) => `
query{
  awardsWon(year: ${year})
}`;

export const grantSuccessRate = () => `
query{
  grantSuccessRate
}`;

export const projectSuccessRate = () => `
query{
  projectSuccessRate
}`;

export const bilateralSuccessRate = () => `
query{
  bilateralSuccessRate
}`;

export const totalSuccessRate = () => `
query{
  totalSuccessRate
}`;

export const projectLocation = () => `
query{
  projectLocation{
    location
    number
  }
}
`;

export const projectFunder = () => `
query{
  projectFunder{
    funder
    number
  }
}
`;

export const allDates = 'query{allDates}';
