const queryPersonPublications = (personId) => `
  {
    publicationsByPeople (id:${personId}) {
    author
    publications {
      patents {
        id
        title
        status
        description
        date
        timestamp
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      abstractOfTalks {
        id
        title
        status
        pages
        city
        state
        country
        volume
        edition
        from
        proceedings
        publisher
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      talks {
        id
        title
        status
        event
        city
        state
        country
        from
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      theses {
        id
        title
        status
        type
        pages
        date
        visibility
        institution
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
        promotors {
          first
          last
          author
          cosic
        }
      }
      proceedings {
        id
        volume
        conference
        appeared
        edition
        proceedings
        seriesName
        publisher
        visibility
        webVersion
        webVersionRecording
        status
        editors {
          first
          last
          author
          cosic
        }
      }
      rumps {
        id
        title
        status
        pages
        from
        proceedings
        edition
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      books {
        id
        title
        status
        publisher
        volume
        date
        pages
        seriesName
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
        editors {
          first
          last
          author
          cosic
        }
      }
      reports {
        id
        title
        status
        pages
        date
        note
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      bookArticles {
        id
        title
        status
        pages
        bookTitle
        date
        publisher
        seriesName
        visibility
        webVersion
        webVersionRecording
      }
      journalArticles {
        id
        title
        status
        pages
        number
        date
        from
        volume
        journalTitle
        visibility
        webVersion
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
      }
      conferenceArticles {
        id
        title
        status
        pages
        proceedingid
        city
        state
        country
        edition
        volume
        from
        publisher
        conference
        series
        visibility
        acronym
        proceedingTitle
        proceedingsFrom
        webVersion
        webVersionSourceCode
        webVersionRecording
        authors {
          first
          last
          author
          cosic
        }
        editors {
          first
          last
          author
          cosic
        }
      }
    }
  }
}
`;

export default queryPersonPublications;
