export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_PARTNER_ERROR = 'DELETE_PARTNER_ERROR';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const FETCH_ALL_PARTNERS = 'FETCH_ALL_PARTNERS';
export const FETCH_ALL_PARTNERS_ERROR = 'FETCH_ALL_PARTNERS_ERROR';
export const FETCH_ALL_PARTNERS_SUCCESS = 'FETCH_ALL_PARTNERS_SUCCESS';
export const SET_PARTNER_FILTER_NAME = 'SET_PARTNER_FILTER_NAME';

export const deletePartner = (partnerId) => ({ type: DELETE_PARTNER, partnerId });
export const deletePartnerError = (error) => ({ type: DELETE_PARTNER_ERROR, error });
export const deletePartnerSuccess = () => ({ type: DELETE_PARTNER_SUCCESS });

export const fetchAllPartners = () => ({ type: FETCH_ALL_PARTNERS });
export const fetchAllPartnersSuccess = (data) => ({ type: FETCH_ALL_PARTNERS_SUCCESS, data });
export const fetchAllPartnersError = (error) => ({ type: FETCH_ALL_PARTNERS_ERROR, error });
export const setPartnerFilterName = (data) => ({ type: SET_PARTNER_FILTER_NAME, data });
