import {
  DELETE_TYPE,
  DELETE_TYPE_ERROR,
  DELETE_TYPE_SUCCESS,
  FETCH_ALL_TYPES,
  FETCH_ALL_TYPES_ERROR,
  FETCH_ALL_TYPES_SUCCESS,
  SET_TYPE_FILTER_NAME,
} from './typesActions';

const defaultState = {
  isLoading: false,
  data: [],
  filter: '',
  error: '',
};

const types = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_TYPES:
      return { ...state, isLoading: true };
    case FETCH_ALL_TYPES_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ALL_TYPES_ERROR:
      return { ...state, data: action.error, isLoading: false };

    case SET_TYPE_FILTER_NAME:
      return { ...state, filter: action.data };

    case DELETE_TYPE:
      return { ...state, isLoading: true };
    case DELETE_TYPE_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_TYPE_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default types;
