import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import Person from '../admin/people/person';

function Profile(props) {
  const {
    user,
  } = props;

  return (
    <Container fluid className="dashboard">
      <Row>
        <Person personId={user.id} />
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <p>User data</p>
              <p>
                <a href="https://www.esat.kuleuven.be/cosic/sites/forms/cosic-gdpr/">COSIC GDPR policy</a>
                {' '}
                ( password = welcometocosic! )
              </p>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

Profile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
