import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { Loader } from '../../index';

const EditPeopleEventsForm = (props) => {
  const { eventData } = props;
  return (
    <div>
      {eventData.events && eventData.loading
        ? <Loader />
        : (
          <div>
            {(eventData.events && eventData.events.length === 0)
              ? <div>No data to display</div>
              : (
                <Table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Location</th>
                      <th>City</th>
                      <th>Country</th>
                      <th>Start date</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    { eventData.events && eventData.events.map((el) => (
                      <tr key={`event_${el.id}`}>
                        <td>{ el.title }</td>
                        <td>{ el.type }</td>
                        <td>{ el.location }</td>
                        <td>{ el.city }</td>
                        <td>{ el.country }</td>
                        <td>{ el.startDate }</td>
                        <td>{ el.endDate }</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
          </div>
        )}
    </div>
  );
};

EditPeopleEventsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  eventData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditPeopleEventsForm;
