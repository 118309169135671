export const QueryAllProjectCalls = `
{
  calls {
    id
    name
  }
}`;

export const QueryOneProjectCall = (id) => `
{ 
  call (id:"${id}") {
    id
    name
  }
}`;

export const MutateCreateProjectCall = (call) => `
mutation {
  createCall (call: { name:"${call.name}"}) {
    id
    name
  }
}`;

export const MutateUpdateProjectCall = (call) => `
mutation {
    updateCall (id:"${call.id}", call: { name:"${call.name}" }) {
      id
      name
    }
}`;

export const MutateDeleteProjectCall = (callId) => `
mutation {
  deleteCall(id:${callId})
}`;
