// @flow

type UPLOAD_PROFILE_IMAGE_ACTION = { type: 'UPLOAD_PROFILE_IMAGE', data: Object }
type UPLOAD_PROFILE_IMAGE_SUCCESS_ACTION = { type: 'UPLOAD_PROFILE_IMAGE_SUCCESS' }
type UPLOAD_PROFILE_IMAGE_ERROR_ACTION = { type: 'UPLOAD_PROFILE_IMAGE_ERROR', error: string }

export type ProfileAction =
  UPLOAD_PROFILE_IMAGE_ACTION
  | UPLOAD_PROFILE_IMAGE_SUCCESS_ACTION
  | UPLOAD_PROFILE_IMAGE_ERROR_ACTION

export const UPLOAD_PROFILE_IMAGE = (data): ProfileAction => ({ type: 'UPLOAD_PROFILE_IMAGE', data });
export const UPLOAD_PROFILE_IMAGE_SUCCESS = (): ProfileAction => ({ type: 'UPLOAD_PROFILE_IMAGE_SUCCESS' });
export const UPLOAD_PROFILE_IMAGE_ERROR = (error): ProfileAction => ({ type: 'UPLOAD_PROFILE_IMAGE_ERROR', error });
