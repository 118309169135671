export const QueryAllProjects = `
{
  filteredProjects{
    id
    name
    acronym
    beginDate
    endDate
    status
    dateStatus
    type {
      id
    }
    funder {
      id
      name
    }
  }
}
`;

export const QueryAllFilteredProjects = (filters) => {
  const queryFilter = [];
  if (filters.nameOrAcronym) {
    queryFilter.push(`nameOrAcronym: "${filters.nameOrAcronym}"`);
  }
  if (filters.status) {
    const s = filters.status.toUpperCase();
    queryFilter.push(`status: [${s}]`);
  }
  if (filters.beginDate) {
    queryFilter.push(`beginDate: "${filters.beginDate}"`);
  }
  if (filters.endDate) {
    queryFilter.push(`endDate: "${filters.endDate}"`);
  }
  if (filters.type) {
    queryFilter.push(`type: "${filters.type}"`);
  }
  if (filters.team) {
    queryFilter.push(`teams: [${filters.team}]`);
  }
  if (filters.dateStatus) {
    queryFilter.push(`dateStatus: ${filters.dateStatus}`);
  }

  const queryString = queryFilter.length > 0
    ? `(${queryFilter.join(',')})`
    : '';

  return (`{
    filteredProjects ${queryString} {
      id
      name
      acronym
      beginDate
      endDate
      status
      dateStatus
      type {
        id
      }
      funder {
        id
        name
      }
    }
  }`);
};

export const QueryProjectTypes = `{
  types {
    id
    name
  }
}`;

export const QueryProjectCalls = `{
  calls {
    id
    name
  }
  teams {
    id
    name
  }
}`;

const projectPublicationsProjection = `
          date
          publications {
            patents {
              id
              title
              description
              date
              timestamp
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
              number
              applicant
            }
            abstractOfTalks {
              id
              title
              pages
              city
              state
              country
              volume
              edition
              from
              proceedings
              proceedingsFrom
              publisher
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            talks {
              id
              title
              event
              city
              state
              country
              from
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            theses {
              id
              title
              type
              institution
              pages
              date
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              promotors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            proceedings {
              id
              volume
              conference
              appeared
              edition
              proceedings
              seriesName
              city
              country
              publisher
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            rumps {
              id
              title
              pages
              from
              proceedings
              edition
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            books {
              id
              title
              publisher
              volume
              date
              pages
              seriesName
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            reports {
              id
              title
              pages
              date
              note
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            bookArticles {
              id
              title
              pages
              bookTitle
              date
              publisher
              seriesName
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors{
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            journalArticles {
              id
              title
              pages
              number
              date
              from
              volume
              journalTitle
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
            conferenceArticles {
              id
              title
              pages
              proceedingid
              city
              state
              country
              edition
              volume
              from
              publisher
              conference
              series
              acronym
              proceedingTitle
              proceedingsFrom
              webVersion
              webVersionSourceCode
              webVersionRecording
              visibility
              hideFromPublic
              status
              authors {
                first
                last
                author
                cosic
              }
              editors {
                first
                last
                author
                cosic
              }
              files {
                fileName
                visibility
                path
              }
            }
          }`;
export const QueryProjectDetails = (id) => (`
    {
      project(id:"${id}"){
        id
        call{
          id
          name
        }
        type {
          id
          name
        }
        funder {
          id
          name
        }
        team {
          id
          name
        }
        workPackets {
          id
          number
          startPM
          endPM
          title
          description
        }
        partners{
          id
          name
          country
          contact
        }
        teamMembers{
          id
          firstname
          lastname
          role
          people{
            id
            gn
            sn
          }
        }
        acronym
        webVersionRepository
        name
        number
        beginDate
        endDate
        status
        abs
        logo
        published
        budget
        budgetMime
        totalBudget
        cosicBudget
        cosicContact {
          id
          firstname
          lastname
        }
        cosicContactRole
        website
        employeeBudget
        action
        sap
        publications {
        ${projectPublicationsProjection}
        }
      }
    }`
);
export const QueryProjectPublications = (id) => (`
    {
      publicationsByProject(projectId:"${id}"){
        ${projectPublicationsProjection}
      }
    }`
);

export const createOneProject = (data) => (`
mutation {
  createProject(
  project: {
    acronym: "${data.acronym}",
    name: "${data.name}",
    number: "${data.number}",
    cosicContact: ${data.cosicContact},
    cosicContactRole: "${data.cosicContactRole}",
    website: ${data.website ? `"${data.website}"` : null},
    beginDate: "${data.beginDate}",
    endDate:  "${data.endDate}",
    status: "${data.status}",
    abs: """${data.abstract}\n""",
    cosicBudget: ${data.cosicBudget ? data.cosicBudget : 0},
    published: ${data.published},
    projectType: "project",
    action: "${data.action ? data.action : ''}"
    sap: "${data.sap}",
    webVersionRepository: "${data.webVersionRepository ? data.webVersionRepository : ''}"
  },
  callId: ${data.call},
  funderId: ${data.funder}
  typeId: ${data.type}
  teamId: [${data.team.join(',')}]
  ) {
    id
    partners {
      id
      name
      country
      contact
    }
    teamMembers{
      id
      firstname
      lastname
      role
      people{
        id
      }
    }
    people {
      id
      name
    }
    call {
      id
      name
    }
    funder {
      id
      name
    }
    role
    type {
      id
      name
    }
    acronym
    webVersionRepository
    cosicBudget
    cosicContact {
      id
      firstname
      lastname
    }
    cosicContactRole
    website
    name
    number
    beginDate
    endDate
    published
    status
    abs
    siteAbs
    sap
    action
    team {
      id
      name
    }
    publications{
      date
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          authors {
            first
            last
          }
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          publisher
          authors {
            first
            last
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          authors {
            first
            last
          }
        }
        theses {
          id
          title
          type
          institution
          pages
          date
          authors {
            first
            last
          }
          promotors {
            first
            last
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          publisher
          editors {
            first
            last
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          authors {
            first
            last
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
        reports {
          id
          title
          pages
          date
          note
          authors {
            first
            last
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          authors{
            first
            last
          }
        }
        journalArticles {
          id
          title
          pages
          date
          number
          from
          volume
          journalTitle
          authors {
            first
            last
          }
        }
        conferenceArticles {
          id
          title
          pages
          proceedingid
          city
          state
          country
          edition
          volume
          from
          publisher
          conference
          series
          acronym
          proceedingTitle
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
      }
    }
  }
}
`);

export const updateOneProject = (data) => (`
mutation {
  updateProject(id: ${data.id},
  project: {
    acronym: "${data.acronym}",
    name: "${data.name}",
    number: "${data.number}",
    cosicContact: ${data.cosicContact},
    cosicContactRole: "${data.cosicContactRole}",
    website: ${data.website ? `"${data.website}"` : null},
    beginDate: "${data.beginDate}",
    endDate:  "${data.endDate}",
    status: "${data.status}",
    abs: """${data.abstract}\n""",
    cosicBudget: ${data.cosicBudget ? data.cosicBudget : 0},
    published: ${data.published},
    action: "${data.action}"
    sap: "${data.sap}"
    webVersionRepository: "${data.webVersionRepository ? data.webVersionRepository : ''}"
  },
  callId: "${data.call}",
  funderId: "${data.funder}",
  typeId: "${data.type}"
  teamId: [${data.team.join(',')}]
  )
 {
    id
    partners {
      id
      name
      country
      contact
    }
    teamMembers{
      id
      firstname
      lastname
      role
      people{
        id
      }
    }
    people {
      id
      name
    }
    call {
      id
      name
    }
    funder {
      id
      name
    }
    role
    type {
      id
      name
    }
    acronym
    webVersionRepository
    cosicBudget
    cosicContact {
      id
      firstname
      lastname
    }
    cosicContactRole
    website
    name
    number
    beginDate
    endDate
    status
    published
    abs
    siteAbs
    sap
    action
    team {
      id
      name
    }
    publications{
      date
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          authors {
            first
            last
          }
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          publisher
          authors {
            first
            last
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          authors {
            first
            last
          }
        }
        theses {
          id
          title
          type
          institution
          pages
          date
          authors {
            first
            last
          }
          promotors {
            first
            last
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          publisher
          editors {
            first
            last
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          authors {
            first
            last
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
        reports {
          id
          title
          pages
          date
          note
          authors {
            first
            last
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          authors{
            first
            last
          }
        }
        journalArticles {
          id
          title
          pages
          date
          number
          from
          volume
          journalTitle
          authors {
            first
            last
          }
        }
        conferenceArticles {
          id
          title
          pages
          proceedingid
          city
          state
          country
          edition
          volume
          from
          publisher
          conference
          series
          acronym
          proceedingTitle
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
      }
    }
  }
}
`);

export const QueryProjectWorkPackets = (id) => (`
query{
  project(id: ${id}){
    workPackets{
      id
      number
      startPM
      endPM
      title
      description
      cosicLead
      cosicParticipation
      personMonth
    }
  }
}
`);

export const deleteProjectWorkPacket = (workPacketId) => (`
mutation {
  deleteWorkpacket(id: ${workPacketId})
}`);

export const addWorkPacketToProject = (data) => (`
mutation{
  addWorkPacketToProject(
    projectId: ${data.projectId},
    workPacket: {
      number: ${data.number},
      startPM: ${data.startPM},
      endPM: ${data.endPM},
      title: "${data.title}",
      description: """${data.description}\n""",
      cosicLead: ${data.cosicLead},
      cosicParticipation: ${data.cosicParticipation},
    }){
    id
    number
    startPM
    endPM
    title
    description
    cosicLead
    cosicParticipation
    personMonth
    project{
      id
    }
    workPacketPeople{
      id
    }
  }
}
`);

export const updateProjectWorkPacket = (data) => (`
mutation {
  updateWorkpacket(id: ${data.id}, workpacket: {
    number: ${data.number},
    startPM: ${data.startPM},
    endPM: ${data.endPM},
    title: "${data.title}",
    description: """${data.description}\n""",
    cosicLead: ${data.cosicLead},
    cosicParticipation: ${data.cosicParticipation},
  }), {
    id
    number
    startPM
    endPM
    title
    description
    cosicLead
    cosicParticipation
    personMonth
    project{
      id
    }
  }
}
`);

export const addPartnerToProjectMutation = (data) => (`
mutation{
  addPartnerToProject(projectId: ${data.projectId}, partnerId: ${data.partnerId}){
    id
    call{
      id
      name
    }
    type {
      id
      name
    }
    funder {
      id
      name
    }
    workPackets {
      id
      startPM
      endPM
      title
      description
    }
    partners{
      id
      name
      country
      contact
    }
    teamMembers{
      id
      firstname
      lastname
      role
      people{
        id
        gn
        sn
      }
    }
    acronym
    webVersionRepository
    name
    number
    beginDate
    endDate
    status
    abs
    logo
    published
    budget
    budgetMime
    totalBudget
    cosicBudget
    cosicContact {
      id
      firstname
      lastname
    }
    cosicContactRole
    website
    employeeBudget
    publications{
      date
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          authors {
            first
            last
          }
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          publisher
          authors {
            first
            last
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          authors {
            first
            last
          }
        }
        theses {
          id
          title
          type
          institution
          pages
          date
          authors {
            first
            last
          }
          promotors {
            first
            last
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          publisher
          editors {
            first
            last
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          authors {
            first
            last
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
        reports {
          id
          title
          pages
          date
          note
          authors {
            first
            last
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          authors{
            first
            last
          }
        }
        journalArticles {
          id
          title
          pages
          number
          date
          from
          volume
          journalTitle
          authors {
            first
            last
          }
        }
        conferenceArticles {
          id
          title
          pages
          proceedingid
          city
          state
          country
          edition
          volume
          from
          publisher
          conference
          series
          acronym
          proceedingTitle
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
      }
    }
  }
}
`);

export const removePartnerFromProjectMutation = (data) => (`
mutation{
  removePartnerFromProject(projectId: ${data.projectId}, partnerId: ${data.partnerId}){
    id
    call{
      id
      name
    }
    type {
      id
      name
    }
    funder {
      id
      name
    }
    workPackets {
      id
      number
      startPM
      endPM
      title
      description
    }
    partners{
      id
      name
      country
      contact
    }
    teamMembers{
      id
      firstname
      lastname
      role
      people{
        id
      }
    }
    acronym
    webVersionRepository
    name
    number
    beginDate
    endDate
    status
    abs
    logo
    published
    budget
    budgetMime
    totalBudget
    cosicBudget
    cosicContact {
      id
      firstname
      lastname
    }
    cosicContactRole
    website
    employeeBudget
    publications{
      date
      publications {
        patents {
          id
          title
          description
          date
          timestamp
          authors {
            first
            last
          }
        }
        abstractOfTalks {
          id
          title
          pages
          city
          state
          country
          volume
          edition
          from
          proceedings
          publisher
          authors {
            first
            last
          }
        }
        talks {
          id
          title
          event
          city
          state
          country
          from
          authors {
            first
            last
          }
        }
        theses {
          id
          title
          type
          institution
          pages
          date
          authors {
            first
            last
          }
          promotors {
            first
            last
          }
        }
        proceedings {
          id
          volume
          conference
          appeared
          edition
          proceedings
          seriesName
          publisher
          editors {
            first
            last
          }
        }
        rumps {
          id
          title
          pages
          from
          proceedings
          edition
          authors {
            first
            last
          }
        }
        books {
          id
          title
          publisher
          volume
          date
          pages
          seriesName
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
        reports {
          id
          title
          pages
          date
          note
          authors {
            first
            last
          }
        }
        bookArticles {
          id
          title
          pages
          bookTitle
          date
          publisher
          seriesName
          authors{
            first
            last
          }
        }
        journalArticles {
          id
          title
          pages
          number
          date
          from
          volume
          journalTitle
          authors {
            first
            last
          }
        }
        conferenceArticles {
          id
          title
          pages
          proceedingid
          city
          state
          country
          edition
          volume
          from
          publisher
          conference
          series
          acronym
          proceedingTitle
          authors {
            first
            last
          }
          editors {
            first
            last
          }
        }
      }
    }
  }
}
`);

export const addTeamMemberToProjectMutation = (data) => (`
mutation{
  addTeamMemberToProject(projectId: ${data.projectId}, teamMember: {role: "${data.teamMember.role}", peopleId: "${data.teamMember.peopleId}"}){
    role
    people {
      id
      gn
      sn
    }
    project {
      id
    }
  }
}
`);

export const removeTeamMemberFromProjectMutation = (data) => (`
mutation{
  removeTeamMemberFromProject(projectId: ${data.projectId}, teamMemberId: ${data.teamMemberId}){
    role
    people {
      id
      gn
      sn
    }
    project {
      id
    }
  }
}
`);

export const removeProject = (projectId) => (`
mutation{
  deleteProject(id: ${projectId})
}
`);

export default {
  addWorkPacketToProject,
};
