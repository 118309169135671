import React, { useEffect, useState } from 'react';
import { merge } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import { theseValidationSchema as validationSchema } from '../ValidationSchema';
import { Loader } from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';
import { theseTypeOptions } from '../../../../config/optionValues';
import {
  createThese as createTheseAction,
  loadTheseRequirements as loadTheseRequirementsAction,
  updateThese as updateTheseAction,
} from './actions';
import {
  selectIsLoading,
  selectProjects,
  selectPublicationTheseDetails,
} from './selectors';
import { selectPublicationAuthors } from '../../../../state/publication/selectors';
import { selectAllCountriesAsOptions } from '../../../../state/countries/selectors';
import { calculatePageCount } from '../../../../utils';

const These = ({
  authors,
  countries,
  createThese,
  isLoading,
  isResearcher,
  loadTheseRequirements,
  theseInfo,
  projects,
  updateThese,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadTheseRequirements({ id, reset: true });
  }, [id, loadTheseRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadTheseRequirements({ reset: false });
    }
  }, [reloads, loadTheseRequirements]);

  const initialFormValues = merge({
    types: 'these',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    pages: '',
    pageCount: 0,
    country: '',
    institution: '',
    thesisType: null,
    promotors: [],
  }, theseInfo);

  const handleTheseSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      promotors: values.promotors.map((promotor) => promotor.value),
      type: values.thesisType.value,
    };

    if (id) {
      updateThese({ ...updatedValues, id });
    } else {
      createThese(updatedValues);
    }
    setSubmitting(false);
  };

  const handlePageChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('pages', value);
    setFieldValue('pageCount', calculatePageCount(value));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleTheseSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="these"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>These specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Page(s)</Form.Label>
                          <Form.Control
                            name="pages"
                            onChange={(event) => handlePageChange(event, setFieldValue)}
                            value={values.pages}
                            className={touched.pages && errors.pages ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="pages" />
                          </div>
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Page Count</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Fill pages..."
                            value={values.pageCount}
                            className={values.pageCount && !parseInt(values.pageCount, 10) ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="pageCount" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            className={touched.country && errors.country ? 'error' : null}
                            onChange={(event) => setFieldValue('country', event.value)}
                            options={countries}
                            value={countries && values.country ? (
                              countries.find((country) => country.value === values.country)
                            ) : ''}
                          />
                          <div className="error-message">
                            <ErrorMessage name="country" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Institution</Form.Label>
                          <Form.Text muted>
                            e.g. KU Leuven
                          </Form.Text>
                          <Form.Control
                            name="institution"
                            onChange={handleChange}
                            value={values.institution}
                            className={touched.institution && errors.institution ? 'error' : null}
                          />
                          <ErrorMessage name="institution" />
                        </Col>
                        <Col>
                          <Form.Label>Type</Form.Label>
                          <Select
                            name="thesisType"
                            onChange={(s) => setFieldValue('thesisType', s.value)}
                            options={theseTypeOptions}
                            value={theseTypeOptions && values.thesisType ? (
                              theseTypeOptions.find((type) => type.value === values.thesisType)
                            ) : null}
                            className={touched.thesisType && errors.thesisType ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="thesisType" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Promotor(s)</Form.Label>
                          <Select
                            name="promotors"
                            isMulti
                            onChange={(s) => setFieldValue('promotors', s)}
                            options={authors}
                            value={values.promotors}
                            className={touched.promotors && errors.promotors ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="promotors" />
                          </div>
                        </Col>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="these"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={theseInfo.visibility}
        />
      )}
    </Container>
  );
};

These.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  createThese: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadTheseRequirements: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theseInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateThese: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authors: selectPublicationAuthors,
  countries: selectAllCountriesAsOptions,
  isLoading: selectIsLoading,
  projects: selectProjects,
  theseInfo: selectPublicationTheseDetails,
});

const mapDispatchToProps = {
  createThese: createTheseAction,
  loadTheseRequirements: loadTheseRequirementsAction,
  updateThese: updateTheseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(These);
