import {
  DELETE_PROJECT_CALL,
  DELETE_PROJECT_CALL_ERROR,
  DELETE_PROJECT_CALL_SUCCESS,
  FETCH_ALL_PROJECT_CALLS,
  FETCH_ALL_PROJECT_CALLS_ERROR,
  FETCH_ALL_PROJECT_CALLS_SUCCESS,
  SET_PROJECT_CALL_FILTER_NAME,
} from './projectCallsActions';

const defaultState = {
  isLoading: false,
  data: [],
  filter: '',
  error: '',
};

const projectCalls = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_PROJECT_CALLS:
      return { ...state, isLoading: true };
    case FETCH_ALL_PROJECT_CALLS_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ALL_PROJECT_CALLS_ERROR:
      return { ...state, data: action.error, isLoading: false };

    case SET_PROJECT_CALL_FILTER_NAME:
      return { ...state, filter: action.data };

    case DELETE_PROJECT_CALL:
      return { ...state, isLoading: true };
    case DELETE_PROJECT_CALL_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_PROJECT_CALL_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default projectCalls;
