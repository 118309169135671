import {
  CREATE_THESE,
  CREATE_THESE_ERROR,
  CREATE_THESE_SUCCESS,
  FETCH_THESE,
  FETCH_THESE_ERROR,
  FETCH_THESE_SUCCESS,
  LOAD_THESE_REQUIREMENTS_SUCCESS,
  UPDATE_THESE,
  UPDATE_THESE_ERROR,
  UPDATE_THESE_SUCCESS,
} from './actions';
import { calculatePageCount } from '../../../../utils';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateTheseRespones = (response) => ({
  types: 'these',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  country: response.country,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
  institution: response.institution,
  thesisType: response.type,
  promotors: response.promotors.map((promotor) => ({
    value: promotor.id, label: `${promotor.last}, ${promotor.first}`,
  })),
  projects: response.projects,
});

const publicationThese = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_THESE_SUCCESS:
    case FETCH_THESE_SUCCESS:
      return { ...state, details: dehydrateTheseRespones(action.payload), isLoading: false };

    case CREATE_THESE_ERROR:
    case UPDATE_THESE_ERROR:
    case FETCH_THESE_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_THESE_SUCCESS:
    case LOAD_THESE_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_THESE:
    case FETCH_THESE:
    case UPDATE_THESE:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationThese;
