import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClickText } from '..';
import {
  authorNameFormatter, authorNameFormatterText, getYearFromDate, idFormatter, statusFormatter,
} from './typeHelper';
import {
  findPdf,
  pagesView,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function volumeNumber(journalTitle, volume, number) {
  if (number) {
    return `${journalTitle} ${volume || ''}(${number})`;
  }
  return `${journalTitle} ${volume || ''}`;
}

function JournalArticle(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);
  const {
    // eslint-disable-next-line max-len
    id, canEdit, authors, title, status, journalTitle, number, volume, pages, index, webVersion, webVersionRecording, visibility, files, date, isAuthenticated,
  } = props;

  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };

  const BibTex = (`
  @ARTICLE{article${id},
    authors =   {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
    title =     {${title}},
    year =      {${getYearFromDate(date)}},
    pages =     {${pages}},
    journal =   {${journalTitle}},
    volume =    {${volume || ''}},
    number =    {${number}},
  }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title},'' { ${journalTitle || ''} ${volume || ''}(${number || ''})}, pp.~${pages || ''}, ${getYearFromDate(date)}.
  `);

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;
                {titleView(id, title, visibility, pdfFile, showPublication, 'journal', isAdmin)}
                &quot;,
                {' '}
                {volumeNumber(journalTitle, volume, number)}
                {pagesView(pages)}
                ,
                {' '}
                {getYearFromDate(date)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'journal')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateJournal/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {BibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

JournalArticle.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  from: PropTypes.string.isRequired,
  pages: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  volume: PropTypes.string,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  number: PropTypes.string.isRequired,
  journalTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

JournalArticle.defaultProps = {
  volume: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(JournalArticle);
