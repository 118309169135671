import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationBook = get('publicationBook');
export const selectPublicationBookDetails = createSelector(
  selectPublicationBook,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationBook, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationBookDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
