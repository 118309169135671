import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import Select from 'react-select';

const PartnersContainer = ({
  partners,
  allPartners,
  addPartner,
  removePartner,
  id,
}) => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [formattedPartners, setFormattedPartners] = useState([]);

  const handlePartnerChange = (val) => {
    setSelectedPartner(val);
  };

  const handleAddPartner = () => {
    const data = { projectId: id, partnerId: selectedPartner.value };
    addPartner(data);
    setFormattedPartners(
      formattedPartners.filter((partner) => partner.id !== selectedPartner.value),
    );
    setSelectedPartner(null);
  };

  const handleDeletePartner = (partnerId) => {
    const data = { projectId: id, partnerId };
    removePartner(data);
  };

  useEffect(() => {
    const filtered = [];
    allPartners.filter((l) => !partners.map((ls) => ls.id).includes(l.id)).forEach((partner) => {
      filtered.push({ value: partner.id, label: partner.name });
    });
    setFormattedPartners(filtered);
  }, [allPartners, partners]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Select
            name="type"
            options={formattedPartners}
            onChange={(val) => handlePartnerChange(val)}
            value={selectedPartner}
          />
        </Col>
        <Col>
          <Button disabled={!selectedPartner} variant="primary" onClick={() => handleAddPartner()}>Add partner</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card className="filter-card">
            <table className="table table-card table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">Partner Name</th>
                  <th scope="col">Country</th>
                  <th scope="col">Contact Person</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  partners.map((partner) => (
                    <tr key={partner.id}>
                      <th>{partner.name}</th>
                      <td>{partner.country}</td>
                      <td>{partner.contact || 'None'}</td>
                      <td className="text-right" style={{ width: 1 }}>
                        <button onClick={() => handleDeletePartner(partner.id)} className="btn btn-sm btn-danger ml-auto">Delete</button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

PartnersContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPartner: PropTypes.func.isRequired,
  removePartner: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default PartnersContainer;
