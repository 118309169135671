import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../../component/index';
import {
  fetchOneCountry as fetchOneCountryAction,
  updateCountry as updateCountryAction,
} from '../../../state/country/countryActions';
import { fetchAllCountries } from '../../../state/countries/countriesActions';

function EditCountry(props) {
  const { id } = useParams();
  const {
    loading, country, fetchCountry, fetchCountries, updateCountry, countries,
  } = props;
  useEffect(() => {
    if (!countries) {
      fetchCountries();
    }
    if (id) {
      fetchCountry({ id });
    }
  }, [countries, fetchCountry, fetchCountries, id]);

  const countryNameRegex = /[A-Za-z]/g;
  const countryCodeRegex = /[a-zA-Z]{2,}/g;
  const isRequired = 'Required Field';
  const isCorrectFormat = 'The country name may contain only letters';

  const countryValidationSchema = Yup.object().shape({
    name: Yup.string()
      .notOneOf(
        countries.map((c) => c.name).filter((elem) => elem !== country.name),
        'Country name already exist',
      )
      .min(3, 'country name must contain at least 3 characters')
      .matches(
        countryNameRegex,
        {
          message: isCorrectFormat,
          excludeEmptyStrings: true,
        },
      )
      .label('Country Name')
      .required(isRequired)
      .isValidCountryName(Yup.ref('isRightFormat')),
    code: Yup.string()
      .notOneOf(countries.map((c) => c.code).filter((elem) => elem !== country.code), 'Country code exist')
      .length(2, 'The country code must be exactly 2 letters')
      .matches(
        countryCodeRegex,
        {
          message: isCorrectFormat,
          excludeEmptyStrings: true,
        },
      )
      .label('Country Code')
      .required(isRequired),
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{id ? 'Edit country' : 'Create country'}</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <>
                    { /*  =============================================== */ }
                    <Formik
                      initialValues={{
                        id: country.id,
                        name: country.name || '',
                        code: country.code || '',
                      }}
                      enableReinitialize
                      validationSchema={countryValidationSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        updateCountry(values);
                        setSubmitting(false);
                      }}
                    >
                      { ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <Form.Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Country name</Form.Label>
                                <Form.Control
                                  placeholder="Country name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.name && errors.name ? 'error' : null}
                                />
                                { touched.name && errors.name ? (<div className="error-message">{errors.name}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Form.Label>Country code</Form.Label>
                                <Form.Control
                                  placeholder="Country code"
                                  name="code"
                                  value={values.code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={touched.code && errors.code ? 'error' : null}
                                />
                                { touched.code && errors.code ? (<div className="error-message">{errors.code}</div>) : null}
                              </Form.Group>
                            </Col>
                          </Form.Row>
                          <Form.Row style={{ marginTop: '2rem' }}>
                            <Col>
                              <Form.Group>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={isSubmitting || errors.code || errors.name}
                                >
                                  {id ? 'Save' : 'Create'}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Form.Row>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditCountry.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  country: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchCountry: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  updateCountry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.country.isLoading,
  country: state.country.data,
  countries: state.countries.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountry: (data) => dispatch(fetchOneCountryAction(data)),
  fetchCountries: () => dispatch(fetchAllCountries()),
  updateCountry: (data) => dispatch(updateCountryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCountry);
