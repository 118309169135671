// @flow

import type { PeopleAction } from './types';

export const FETCH_ALL_PEOPLE = () => ({ type: 'FETCH_ALL_PEOPLE' }: PeopleAction);
export const FETCH_ALL_PEOPLE_SUCCESS = (data) => ({ type: 'FETCH_ALL_PEOPLE_SUCCESS', data }: PeopleAction);
export const FETCH_ALL_PEOPLE_ERROR = (error) => ({ type: 'FETCH_ALL_PEOPLE_ERROR', error }: PeopleAction);

export const FETCH_ALL_FILTERED_PEOPLE = () => ({ type: 'FETCH_ALL_FILTERED_PEOPLE' }: PeopleAction);
export const FETCH_ALL_FILTERED_PEOPLE_SUCCESS = (data) => ({ type: 'FETCH_ALL_FILTERED_PEOPLE_SUCCESS', data }: PeopleAction);
export const FETCH_ALL_FILTERED_PEOPLE_ERROR = (error) => ({ type: 'FETCH_ALL_FILTERED_PEOPLE_ERROR', error }: PeopleAction);

export const FETCH_ALL_PEOPLE_PROMOTERS = () => ({ type: 'FETCH_ALL_PEOPLE_PROMOTERS' }: PeopleAction);
export const FETCH_ALL_PEOPLE_PROMOTERS_SUCCESS = (data) => ({ type: 'FETCH_ALL_PEOPLE_PROMOTERS_SUCCESS', data }: PeopleAction);
export const FETCH_ALL_PEOPLE_PROMOTERS_ERROR = (error) => ({ type: 'FETCH_ALL_PEOPLE_PROMOTERS_ERROR', error }: PeopleAction);

export const SET_PEOPLE_FILTER_ACTIVE = (data) => ({ type: 'SET_PEOPLE_FILTER_ACTIVE', data }: PeopleAction);
export const SET_PEOPLE_FILTER_STATUSTYPE = (data) => ({ type: 'SET_PEOPLE_FILTER_STATUSTYPE', data }: PeopleAction);
export const SET_PEOPLE_FILTER_TEAM = (data) => ({ type: 'SET_PEOPLE_FILTER_TEAM', data }: PeopleAction);
export const SET_PEOPLE_FILTER_STARTDATE = (data) => ({ type: 'SET_PEOPLE_FILTER_STARTDATE', data }: PeopleAction);

export const FETCH_ALL_PEOPLE_TYPES = () => ({ type: 'FETCH_ALL_PEOPLE_TYPES' }: PeopleAction);
export const FETCH_ALL_PEOPLE_TYPES_SUCCESS = (data) => ({ type: 'FETCH_ALL_PEOPLE_TYPES_SUCCESS', data }: PeopleAction);
export const FETCH_ALL_PEOPLE_TYPES_ERROR = (error) => ({ type: 'FETCH_ALL_PEOPLE_TYPES_ERROR', error }: PeopleAction);

export const FETCH_ALL_PEOPLE_TEAMS = () => ({ type: 'FETCH_ALL_PEOPLE_TEAMS' }: PeopleAction);
export const FETCH_ALL_PEOPLE_TEAMS_SUCCESS = (data) => ({ type: 'FETCH_ALL_PEOPLE_TEAMS_SUCCESS', data }: PeopleAction);
export const FETCH_ALL_PEOPLE_TEAMS_ERROR = (error) => ({ type: 'FETCH_ALL_PEOPLE_TEAMS_ERROR', error }: PeopleAction);

export const FETCH_ALL_PEOPLE_INCL_EXTERNAL = 'FETCH_ALL_PEOPLE_INCL_EXTERNAL';
export const FETCH_ALL_PEOPLE_INCL_EXTERNAL_SUCCESS = 'FETCH_ALL_PEOPLE_INCL_EXTERNAL_SUCCESS';
export const FETCH_ALL_PEOPLE_INCL_EXTERNAL_ERROR = 'FETCH_ALL_PEOPLE_INCL_EXTERNAL_ERROR';

export const fetchAllPeopleInclExternal = () => ({ type: FETCH_ALL_PEOPLE_INCL_EXTERNAL });
export const fetchAllPeopleInclExternalSuccess = (data) => ({
  type: FETCH_ALL_PEOPLE_INCL_EXTERNAL_SUCCESS,
  data,
});
export const fetchAllPeopleInclExternalError = (error) => ({
  type: FETCH_ALL_PEOPLE_INCL_EXTERNAL_ERROR,
  error,
});
