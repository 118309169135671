import {
  CREATE_EVENT,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT,
  DELETE_EVENT_ERROR,
  DELETE_EVENT_SUCCESS,
  FETCH_ONE_EVENT,
  FETCH_ONE_EVENT_ERROR,
  FETCH_ONE_EVENT_SUCCESS,
  UPDATE_EVENT,
  UPDATE_EVENT_ERROR,
  UPDATE_EVENT_SUCCESS,
} from './eventActions';

const defaultState = {
  isLoading: false,
  data: {},
  error: '',
};

const event = (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_EVENT:
      return { ...state, isLoading: true };
    case CREATE_EVENT_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case CREATE_EVENT_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case DELETE_EVENT:
      return { ...state, isLoading: true };
    case DELETE_EVENT_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_EVENT_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case FETCH_ONE_EVENT:
      return { ...state, isLoading: true };
    case FETCH_ONE_EVENT_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_EVENT_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case UPDATE_EVENT:
      return { ...state, isLoading: true };
    case UPDATE_EVENT_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case UPDATE_EVENT_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default event;
