import { createAction } from 'redux-actions';

export const CREATE_JOURNAL = 'CREATE_JOURNAL';
export const CREATE_JOURNAL_ERROR = 'CREATE_JOURNAL_ERROR';
export const CREATE_JOURNAL_SUCCESS = 'CREATE_JOURNAL_SUCCESS';
export const FETCH_JOURNAL = 'FETCH_JOURNAL';
export const FETCH_JOURNAL_ERROR = 'FETCH_JOURNAL_ERROR';
export const FETCH_JOURNAL_SUCCESS = 'FETCH_JOURNAL_SUCCESS';
export const LOAD_JOURNAL_REQUIREMENTS = 'LOAD_REQUIREMENTS_JOURNAL';
export const LOAD_JOURNAL_REQUIREMENTS_ERROR = 'LOAD_JOURNAL_REQUIREMENTS_ERROR';
export const LOAD_JOURNAL_REQUIREMENTS_SUCCESS = 'LOAD_JOURNAL_REQUIREMENTS_SUCCESS';
export const UPDATE_JOURNAL = 'UPDATE_JOURNAL';
export const UPDATE_JOURNAL_ERROR = 'UPDATE_JOURNAL_ERROR';
export const UPDATE_JOURNAL_SUCCESS = 'UPDATE_JOURNAL_SUCCESS';

export const createJournal = createAction(CREATE_JOURNAL);
export const createJournalError = createAction(CREATE_JOURNAL_ERROR);
export const createJournalSuccess = createAction(CREATE_JOURNAL_SUCCESS);

export const fetchJournal = createAction(FETCH_JOURNAL);
export const fetchJournalError = createAction(FETCH_JOURNAL_ERROR);
export const fetchJournalSuccess = createAction(FETCH_JOURNAL_SUCCESS);

export const loadJournalRequirements = createAction(LOAD_JOURNAL_REQUIREMENTS);
export const loadJournalRequirementsError = createAction(LOAD_JOURNAL_REQUIREMENTS_ERROR);
export const loadJournalRequirementsSuccess = createAction(LOAD_JOURNAL_REQUIREMENTS_SUCCESS);

export const updateJournal = createAction(UPDATE_JOURNAL);
export const updateJournalError = createAction(UPDATE_JOURNAL_ERROR);
export const updateJournalSuccess = createAction(UPDATE_JOURNAL_SUCCESS);
