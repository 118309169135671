import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectPerson = get('person');
export const selectIsLoading = createSelector(selectPerson, (person) => person.isLoading);
export const selectPersonDetail = createSelector(selectPerson, (person) => person.data);
export const selectPersonId = createSelector(
  selectPersonDetail,
  (person) => person.id,
);
export const selectPersonStatusHistory = createSelector(
  selectPersonDetail,
  (person) => person.statusHistory,
);

export const selectPersonPublications = createSelector(
  selectPerson,
  (person) => person.publications,
);
