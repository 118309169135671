import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Container, Card, Form, Button,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { Loader } from '../../../component/index';
import {
  fetchOnePartner as fetchOnePartnerAction,
  updatePartner as updatePartnerAction,
} from '../../../state/partner/partnerActions';
import { fetchAllCountries } from '../../../state/countries/countriesActions';
import { fetchAllPartners as fetchAllPartnersAction } from '../../../state/partners/partnersActions';
import '../../../styles/forms.scss';
import { projectTypes } from '../../../config/optionValues';

function EditPartner(props) {
  const { id } = useParams();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const {
    loading,
    partner,
    fetchPartner,
    fetchCountries,
    fetchAllPartners,
    updatePartner,
    countries,
    partners,
  } = props;

  useEffect(() => {
    fetchPartner({ id });
    fetchCountries();
    fetchAllPartners();
  }, [
    fetchPartner,
    fetchCountries,
    fetchAllPartners,
    id,
  ]);

  useEffect(() => {
    if (!loading && partner) {
      if (partner.country !== '') {
        setSelectedCountry({ value: partner.country, label: partner.country });
      } else {
        setSelectedCountry(null);
      }
      if (partner.type) {
        setSelectedType({ value: partner.type, label: partner.type });
      } else {
        setSelectedType(null);
      }
    }
  }, [partner, loading]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const convertToOption = (objs) => {
    const options = [];

    objs.forEach((obj) => {
      options.push({ value: obj.name, label: obj.name });
    });

    return options;
  };

  const validationSchema = yup.object().shape({
    name: yup.string()
      .test(
        'unique-name',
        'Name already exists',
        (value) => {
          const lowerCaseValue = value ? value.toLowerCase() : '';
          const lowerCasePartnerName = partner.name ? partner.name.toLowerCase() : '';
          // eslint-disable-next-line max-len
          return !partners.some((p) => p.name && p.name.toLowerCase() === lowerCaseValue && p.name.toLowerCase() !== lowerCasePartnerName);
        },
      )
      .required('The partner name is mandatory'),
    country: yup.string()
      .nullable(),
    contact: yup.string(),
    type: yup.string()
      .nullable(),
  });

  if (loading || !partners) {
    return <Loader />;
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>{partner.id !== 'new' ? 'Edit partner' : 'Create partner'}</Card.Header>
            <Card.Body>
              <Formik
                initialValues={{
                  id: partner.id,
                  name: partner.name,
                  contact: partner.contact || '',
                  country: partner.country,
                  type: partner.type || '',
                }}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnBlur
                validateOnChange
                onSubmit={(values, { setSubmitting, validateForm }) => {
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0) {
                      let country = null;
                      let contact = null;
                      let type = null;
                      if (selectedCountry) {
                        country = selectedCountry.value;
                      }
                      if (values.contact) {
                        contact = values.contact;
                      }
                      if (selectedType) {
                        type = selectedType.value;
                      }
                      const data = {
                        id: values.id,
                        name: values.name,
                        country,
                        contact,
                        type,
                      };
                      updatePartner(data);
                    }
                    setSubmitting(false);
                  });
                }}
              >
                { ({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            placeholder="Partner name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={touched.name && errors.name ? 'error' : null}
                          />
                          { touched.name && errors.name ? (<div className="error-message">{errors.name}</div>) : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            options={convertToOption(countries)}
                            onChange={(val) => handleCountryChange(val)}
                            value={selectedCountry}
                          />
                        </Form.Group>
                        { touched.country && errors.country && (<div className="error-message">{errors.country}</div>) }
                      </Col>
                    </Form.Row>
                    <Form.Row style={{ marginTop: '2rem' }}>
                      <Col>
                        <Form.Group>
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            placeholder="Partner contact"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={touched.contact && errors.contact ? 'error' : null}
                          />
                          { touched.contact && errors.contact && (<div className="error-message">{errors.contact}</div>) }
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Type</Form.Label>
                          <Select
                            name="type"
                            options={projectTypes}
                            onChange={(val) => handleTypeChange(val)}
                            value={selectedType}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                    <Form.Row style={{ marginTop: '2rem' }}>
                      <Col>
                        <Form.Group>
                          <Button variant="primary" type="submit" disabled={isSubmitting || errors.name || values.name === ''}>
                            {partner.id !== 'new' ? 'Save' : 'Create'}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

EditPartner.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partner: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchPartner: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  fetchAllPartners: PropTypes.func.isRequired, // Add this prop type
  updatePartner: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.partner.isLoading,
  partner: state.partner.data,
  countries: state.countries.data,
  partners: state.partners.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPartner: (data) => dispatch(fetchOnePartnerAction(data)),
  fetchCountries: () => dispatch(fetchAllCountries()),
  fetchAllPartners: () => dispatch(fetchAllPartnersAction()),
  updatePartner: (data) => dispatch(updatePartnerAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPartner);
