export const FETCH_ALL_VENUES = 'FETCH_ALL_VENUES';
export const FETCH_ALL_VENUES_SUCCESS = 'FETCH_ALL_VENUES_SUCCESS';
export const FETCH_ALL_VENUES_ERROR = 'FETCH_ALL_VENUES_ERROR';
export const SET_VENUES_FILTER_NAME = 'SET_VENUES_FILTER_NAME';
export const DELETE_VENUE = 'DELETE_VENUE';
export const DELETE_VENUE_SUCCESS = 'DELETE_VENUE_SUCCESS';
export const DELETE_VENUE_ERROR = 'DELETE_VENUE_ERROR';

export const fetchAllVenues = () => ({ type: FETCH_ALL_VENUES });
export const fetchAllVenuesSuccess = (data) => ({ type: FETCH_ALL_VENUES_SUCCESS, data });
export const fetchAllVenuesError = (error) => ({ type: FETCH_ALL_VENUES_ERROR, error });
export const setVenueFilterName = (data) => ({ type: SET_VENUES_FILTER_NAME, data });

export const deleteVenue = (data) => ({ type: DELETE_VENUE, data });
export const deleteVenueSuccess = () => ({ type: DELETE_VENUE_SUCCESS });
export const deleteVenueError = (error) => ({ type: DELETE_VENUE_ERROR, error });
