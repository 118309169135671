import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryOnePartner,
  MutateCreatePartner,
  MutateUpdatePartner,
} from '../../graphql/queries/partners';
import {
  FETCH_ONE_PARTNER,
  fetchOnePartnerSuccess,
  fetchOnePartnerError,
  UPDATE_PARTNER,
  updatePartnerSuccess,
  updatePartnerError,
} from './partnerActions';
import catchErrors from '../../graphql/sagas';

export function* getOnePartner(action) {
  if (action.data.id === 'new') {
    yield put(fetchOnePartnerSuccess({
      id: 'new',
      name: '',
      contact: '',
      country: '',
    }));
  } else {
    const response = yield call(axios.post, '/', { query: QueryOnePartner(action.data.id) });
    yield put(fetchOnePartnerSuccess(response.data.data.partner));
  }
}

export function* updatePartner(action) {
  let query;
  if (action.data.id === 'new') {
    query = MutateCreatePartner(action.data);
  } else {
    query = MutateUpdatePartner(action.data);
  }

  yield call(axios.post, '/', { query });
  yield put(updatePartnerSuccess());
  yield put(push('/admin/settings/partners'));

  store.addNotification({
    title: 'SUCCESS!',
    message: 'Update successfully saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_PARTNER,
    catchErrors({
      onErrorAction: fetchOnePartnerError,
      overrideErrorMessage: 'Unable to retrieve partner',
    })(getOnePartner),
  );

  yield takeLatest(
    UPDATE_PARTNER,
    catchErrors({
      onErrorAction: updatePartnerError,
      overrideErrorMessage: 'Unable to update partner',
    })(updatePartner),
  );
}
