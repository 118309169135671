import { createAction } from 'redux-actions';

export const CREATE_ABSTRACT_OF_TALK = 'CREATE_ABSTRACT_OF_TALK';
export const CREATE_ABSTRACT_OF_TALK_ERROR = 'CREATE_ABSTRACT_OF_TALK_ERROR';
export const CREATE_ABSTRACT_OF_TALK_SUCCESS = 'CREATE_ABSTRACT_OF_TALK_SUCCESS';
export const FETCH_ABSTRACT_OF_TALK = 'FETCH_ABSTRACT_OF_TALK';
export const FETCH_ABSTRACT_OF_TALK_ERROR = 'FETCH_ABSTRACT_OF_TALK_ERROR';
export const FETCH_ABSTRACT_OF_TALK_SUCCESS = 'FETCH_ABSTRACT_OF_TALK_SUCCESS';
export const LOAD_ABSTRACT_OF_TALK_REQUIREMENTS = 'LOAD_REQUIREMENTS_ABSTRACT_OF_TALK';
export const LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_ERROR = 'LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_ERROR';
export const LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_SUCCESS = 'LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_SUCCESS';
export const UPDATE_ABSTRACT_OF_TALK = 'UPDATE_ABSTRACT_OF_TALK';
export const UPDATE_ABSTRACT_OF_TALK_ERROR = 'UPDATE_ABSTRACT_OF_TALK_ERROR';
export const UPDATE_ABSTRACT_OF_TALK_SUCCESS = 'UPDATE_ABSTRACT_OF_TALK_SUCCESS';

export const createAbstractOfTalk = createAction(CREATE_ABSTRACT_OF_TALK);
export const createAbstractOfTalkError = createAction(CREATE_ABSTRACT_OF_TALK_ERROR);
export const createAbstractOfTalkSuccess = createAction(CREATE_ABSTRACT_OF_TALK_SUCCESS);

export const fetchAbstractOfTalk = createAction(FETCH_ABSTRACT_OF_TALK);
export const fetchAbstractOfTalkError = createAction(FETCH_ABSTRACT_OF_TALK_ERROR);
export const fetchAbstractOfTalkSuccess = createAction(FETCH_ABSTRACT_OF_TALK_SUCCESS);

export const loadAbstractOfTalkRequirements = createAction(LOAD_ABSTRACT_OF_TALK_REQUIREMENTS);
export const loadAbstractOfTalkRequirementsError = createAction(
  LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_ERROR,
);
export const loadAbstractOfTalkRequirementsSuccess = createAction(
  LOAD_ABSTRACT_OF_TALK_REQUIREMENTS_SUCCESS,
);

export const updateAbstractOfTalk = createAction(UPDATE_ABSTRACT_OF_TALK);
export const updateAbstractOfTalkError = createAction(UPDATE_ABSTRACT_OF_TALK_ERROR);
export const updateAbstractOfTalkSuccess = createAction(UPDATE_ABSTRACT_OF_TALK_SUCCESS);
