import React from 'react';
import { checkPubType } from './typeHelper';

const renderPublicationsByPerson = (publications, canEdit) => (
  <div>
    <table className="table table-striped">
      <tbody>
        {checkPubType(publications.publications, canEdit)}
      </tbody>
    </table>
  </div>
);

export default renderPublicationsByPerson;
