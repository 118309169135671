import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Loader from '../../component/loader/loader';
import {
  FETCH_ALL_PUBLICATIONS,
  SET_PUBLICATION_FILTER_AUTHORS,
  SET_PUBLICATION_FILTER_PUBLICATIONTYPES,
  SET_PUBLICATION_FILTER_YEARS,
  SET_PUBLICATION_FILTER_STATUS,
  SET_PUBLICATION_FILTER_INTERNATIONAL,
  SET_PUBLICATION_FILTER_REVIEWED,
  SET_PUBLICATION_FILTER_AUTHOR_LIMIT,
} from '../../state/publication/publicationActions';
import { generateAuthors, generateYears } from '../../utils';
import { PublicationsTabs } from '../../component/admin';
import { LOGOUT } from '../../state/app/appActions';
import { customFilterMatchAll } from '../../component/publicationFormatTypes/typeHelper';

/* un-comment props when necessary, then remove this message */
export const PublicSearchContainer = (props) => {
  const {
    authors,
    fetchAllPublications,
    years,
    filter,
    loading,
    publications,
    publicationTypes,
    setPublicationFilterAuthors,
    setPublicationFilterTypes,
    setPublicationFilterYears,
    setPublicationFilterStatus,
    setPublicationFilterInternational,
    setPublicationFilterAuthorLimit,
    setPublicationFilterReviewed,
    // user,
    // isAuthenticated,
    // logout,
  } = props;
  useEffect(() => {
    fetchAllPublications();
  }, [fetchAllPublications]);

  const yearsOptions = generateYears(years);
  const authorOptions = generateAuthors(authors);
  const statusOptions = [
    { value: 'published', label: 'Published' },
    { value: 'accepted', label: 'Accepted' },
    { value: 'submitted', label: 'Submitted' },
  ];

  const onChangeInternational = (event) => {
    setPublicationFilterInternational(event.target.checked);
  };

  const onChangeReviewed = (event) => {
    setPublicationFilterReviewed(event.target.checked);
  };

  const onChangeAuthor = (event) => {
    setPublicationFilterAuthorLimit(event.target.checked);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="publication-title text-center">
          <img src="/assets/img/logo.png" alt="COSIC" style={{ width: 150 }} />
          <h1>Publications</h1>
          <p>Search in our publications database</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Authors</Form.Label>
                      <Select
                        options={authorOptions}
                        value={filter.authors}
                        onChange={setPublicationFilterAuthors}
                        filterOption={customFilterMatchAll}
                        isMulti
                      />
                      <Form.Text className="text-muted">
                        Leave empty to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Type of Publication</Form.Label>
                      <Select
                        options={publicationTypes}
                        value={filter.publicationTypes}
                        onChange={setPublicationFilterTypes}
                        isMulti
                      />
                      <Form.Text className="text-muted">
                        Leave empty to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Years</Form.Label>
                      <Select
                        options={yearsOptions}
                        isMulti
                        value={filter.years}
                        onChange={setPublicationFilterYears}
                      />
                      <Form.Text className="text-muted">
                        Press x to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Select
                        options={statusOptions}
                        defaultValue={{ value: 'published', label: 'Published' }}
                        isClearable
                        onChange={setPublicationFilterStatus}
                      />
                      <Form.Text className="text-muted">
                        Press x to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="Limit to international"
                          onChange={onChangeInternational}
                        />
                        <Form.Check
                          inline
                          label="Limit to peer-reviewed"
                          onChange={onChangeReviewed}
                        />
                        <Form.Check
                          inline
                          label="Limit to author"
                          onChange={onChangeAuthor}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {loading
            ? <Loader />
            : (
              <PublicationsTabs
                years={filter.years}
                value={filter.status}
                publications={publications}
                canEdit={false}
              />
            )}
        </Col>
      </Row>
    </Container>
  );
};

PublicSearchContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  publications: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  publicationTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAllPublications: PropTypes.func.isRequired,
  setPublicationFilterAuthors: PropTypes.func.isRequired,
  setPublicationFilterYears: PropTypes.func.isRequired,
  setPublicationFilterTypes: PropTypes.func.isRequired,
  setPublicationFilterStatus: PropTypes.func.isRequired,
  setPublicationFilterInternational: PropTypes.func.isRequired,
  setPublicationFilterReviewed: PropTypes.func.isRequired,
  setPublicationFilterAuthorLimit: PropTypes.func.isRequired,
  // isAuthenticated: PropTypes.bool.isRequired,
  // user: PropTypes.objectOf(PropTypes.any).isRequired,
};

PublicSearchContainer.defaultProps = {
  publications: [],
};

const mapStateToProps = (state) => ({
  loading: state.publication.isLoading,
  authors: state.people.authors,
  years: state.app.years,
  publicationTypes: state.app.publicationTypes,
  publicationByYear: state.publication.publicationByYear,
  publicationByAuthor: state.publication.publicationByAuthor,
  publicationByCategory: state.publication.publicationByCategory,
  filter: state.publication.filter,
  user: state.user.user,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllPublications: () => dispatch(FETCH_ALL_PUBLICATIONS()),
  setPublicationFilterAuthors: (value) => dispatch(SET_PUBLICATION_FILTER_AUTHORS(value)),
  setPublicationFilterYears: (value) => dispatch(SET_PUBLICATION_FILTER_YEARS(value)),
  setPublicationFilterTypes: (value) => dispatch(SET_PUBLICATION_FILTER_PUBLICATIONTYPES(value)),
  setPublicationFilterStatus: (value) => dispatch(SET_PUBLICATION_FILTER_STATUS(value)),
  setPublicationFilterReviewed: (value) => dispatch(SET_PUBLICATION_FILTER_REVIEWED(value)),
  setPublicationFilterAuthorLimit: (value) => dispatch(SET_PUBLICATION_FILTER_AUTHOR_LIMIT(value)),
  setPublicationFilterInternational: (value) => (
    dispatch(SET_PUBLICATION_FILTER_INTERNATIONAL(value))
  ),
  logout: () => dispatch(LOGOUT()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicSearchContainer);
