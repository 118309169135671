import React, { useEffect, useState } from 'react';
import { merge } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import { patentValidationSchema as validationSchema } from '../ValidationSchema';
import { Loader } from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';
import {
  createPatent as createPatentAction,
  loadPatentRequirements as loadPatentRequirementsAction,
  updatePatent as updatePatentAction,
} from './actions';
import {
  selectIsLoading,
  selectProjects,
  selectPublicationPatentDetails,
} from './selectors';

const Patent = ({
  createPatent,
  isLoading,
  isResearcher,
  loadPatentRequirements,
  projects,
  patentInfo,
  updatePatent,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadPatentRequirements({ id, reset: true });
  }, [id, loadPatentRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadPatentRequirements({ reset: false });
    }
  }, [reloads, loadPatentRequirements]);

  const initialFormValues = merge({
    types: 'patent',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    number: '',
    applicant: '',
    description: '',
  }, patentInfo);

  const handlePatentSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      type: 'patent',
    };

    if (id) {
      updatePatent({ ...updatedValues, id });
    } else {
      createPatent(updatedValues);
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handlePatentSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="patent"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Patent specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="number"
                            onChange={handleChange}
                            value={values.number}
                            className={touched.number && errors.number ? 'error' : null}
                          />
                          <ErrorMessage name="number" />
                        </Col>
                        <Col>
                          <Form.Label>Applicant</Form.Label>
                          <Form.Text muted>
                            e.g. KU Leuven, Intel Corporation, etc
                          </Form.Text>
                          <Form.Control
                            name="applicant"
                            onChange={handleChange}
                            value={values.applicant}
                            className={touched.applicant && errors.applicant ? 'error' : null}
                          />
                          <ErrorMessage name="applicant" />
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            name="description"
                            as="textarea"
                            onChange={handleChange}
                            value={values.description}
                            className={touched.description && errors.description ? 'error' : null}
                          />
                          <ErrorMessage name="description" />
                        </Col>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="patent"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={patentInfo.visibility}
        />
      )}
    </Container>
  );
};

Patent.propTypes = {
  createPatent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadPatentRequirements: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patentInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  updatePatent: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoading,
  patentInfo: selectPublicationPatentDetails,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createPatent: createPatentAction,
  loadPatentRequirements: loadPatentRequirementsAction,
  updatePatent: updatePatentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Patent);
