export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const FETCH_ALL_COUNTRIES = 'FETCH_ALL_COUNTRIES';
export const FETCH_ALL_COUNTRIES_SUCCESS = 'FETCH_ALL_COUNTRIES_SUCCESS';
export const FETCH_ALL_COUNTRIES_ERROR = 'FETCH_ALL_COUNTRIES_ERROR';
export const SET_COUNTRY_FILTER_CODE = 'SET_COUNTRY_FILTER_CODE';
export const SET_COUNTRY_FILTER_NAME = 'SET_COUNTRY_FILTER_NAME';

export const deleteCountry = (countryId) => ({ type: DELETE_COUNTRY, countryId });
export const deleteCountrySuccess = () => ({ type: DELETE_COUNTRY_SUCCESS });
export const deleteCountryError = (error) => ({ type: DELETE_COUNTRY_ERROR, error });

export const fetchAllCountries = () => ({ type: FETCH_ALL_COUNTRIES });
export const fetchAllCountriesSuccess = (data) => ({ type: FETCH_ALL_COUNTRIES_SUCCESS, data });
export const fetchAllCountriesError = (error) => ({ type: FETCH_ALL_COUNTRIES_ERROR, error });
export const setCountryFilterCode = (data) => ({ type: SET_COUNTRY_FILTER_CODE, data });
export const setCountryFilterName = (data) => ({ type: SET_COUNTRY_FILTER_NAME, data });
