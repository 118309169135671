import axios from 'axios';
import config from '../config/config';

const axiosClient = axios.create({
  baseURL: config.GRAPHQL_URL,
  headers: {
    'x-api-key': 'YhQdyz0hIzKojjh2pd0GN9KDZ3DKl2CdZdpWNZz7wRQRO1liQpPhqjSYF1PbKckR',
  },
});

const setBearer = (cfg) => {
  const token = localStorage.getItem('token');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    cfg.headers.Authorization = `Bearer ${token}`;
  }
  return cfg;
};

const error = (e) => Promise.reject(e);

axiosClient.interceptors.request.use(
  setBearer,
  error,
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response.data.errors && response.data.errors.length > 0) {
      throw new Error(response.data.errors[0].message);
    }

    return response;
  },
  error,
);

export const axiosClientNoGraphl = axios.create({
  baseURL: config.API_URL,
  headers: {
    'x-api-key': 'YhQdyz0hIzKojjh2pd0GN9KDZ3DKl2CdZdpWNZz7wRQRO1liQpPhqjSYF1PbKckR',
  },
});

axiosClientNoGraphl.interceptors.request.use(
  setBearer,
  error,
);

export default axiosClient;
