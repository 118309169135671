import {
  FETCH_ALL_EVENTS,
  FETCH_ALL_EVENTS_ERROR,
  FETCH_ALL_EVENTS_SUCCESS,
  SEND_MAIL,
  SEND_MAIL_ERROR,
  SEND_MAIL_SUCCESS,
  SET_FILTERS,
} from './eventsActions';

const defaultState = {
  isLoading: false,
  data: [],
  error: '',
  emailAddresses: [],
  filters: {
    title: null,
    type: null,
    startDate: null,
    endDate: null,
  },
};

const events = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_EVENTS:
      return { ...state, isLoading: true };
    case FETCH_ALL_EVENTS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case FETCH_ALL_EVENTS_SUCCESS:
      return { ...state, data: action.data, isLoading: false };

    case SEND_MAIL:
      return { ...state, data: action.data, isLoading: true };
    case SEND_MAIL_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case SEND_MAIL_SUCCESS:
      return { ...state, isLoading: false };

    case SET_FILTERS:
      return { ...state, filters: action.filters, isLoading: false };

    default:
      return state;
  }
};

export default events;
