import { publicationTypes, publicationTypesAdmin } from '../../config/optionValues';

const defaultState = {
  isLoading: true,
  initialDataLoaded: false,
  years: [],
  publicationTypes,
  publicationTypesAdmin,
  error: '',
};

const app = (state = defaultState, action) => {
  switch (action.type) {
    case 'INIT_DATA_SUCCESS':
      return { ...state, years: action.data.allDates.reverse(), isLoading: false };
    case 'INIT_DATA_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_USER_ID_ERROR':
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
};

export default app;
