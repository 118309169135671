import {
  FETCH_ONE_TYPE,
  FETCH_ONE_TYPE_ERROR,
  FETCH_ONE_TYPE_SUCCESS,
  UPDATE_TYPE,
  UPDATE_TYPE_ERROR,
  UPDATE_TYPE_SUCCESS,
} from './typeActions';

const defaultState = {
  isLoading: false,
  data: {},
  error: '',
};

const type = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ONE_TYPE:
    case UPDATE_TYPE:
      return { ...state, isLoading: true };

    case FETCH_ONE_TYPE_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_TYPE_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case UPDATE_TYPE_SUCCESS:
      return { ...state, data: {}, isLoading: false };
    case UPDATE_TYPE_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default type;
