import {
  DELETE_FUNDER,
  DELETE_FUNDER_ERROR,
  DELETE_FUNDER_SUCCESS,
  FETCH_ALL_FUNDERS,
  FETCH_ALL_FUNDERS_ERROR,
  FETCH_ALL_FUNDERS_SUCCESS,
  SET_FUNDER_FILTER_NAME,
} from './fundersActions';

const defaultState = {
  isLoading: false,
  data: [],
  filter: '',
  error: '',
};

const funders = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_FUNDERS:
      return { ...state, isLoading: true };
    case FETCH_ALL_FUNDERS_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ALL_FUNDERS_ERROR:
      return { ...state, data: action.error, isLoading: false };
    case SET_FUNDER_FILTER_NAME:
      return { ...state, filter: action.data, isLoading: false };
    case DELETE_FUNDER:
      return { ...state, isLoading: true };
    case DELETE_FUNDER_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_FUNDER_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default funders;
