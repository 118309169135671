import React from 'react';
import PropTypes from 'prop-types';

const ClickText = ({
  onClick,
  text,
  ...props
}) => (
  <span
    role="button"
    className="text-isClickable"
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={0}
    {...props}
  >
    {text}
  </span>
);

ClickText.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ClickText;
