import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_THESE,
  createTheseError,
  createTheseSuccess,
  FETCH_THESE,
  fetchTheseError,
  fetchTheseSuccess,
  fetchThese as fetchTheseAction,
  LOAD_THESE_REQUIREMENTS,
  loadTheseRequirementsError,
  loadTheseRequirementsSuccess,
  UPDATE_THESE,
  updateTheseError,
  updateTheseSuccess,
} from './actions';
import {
  ensurePeople,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createThese as createTheseMutation,
  QuerytheseById as fetchTheseQuery,
  updateThese as updateTheseMutation,
} from '../../../../graphql/queries/publications';

export function* createThese({ payload }) {
  const response = yield call(axios.post, '/', { query: createTheseMutation(payload) });
  yield put(createTheseSuccess());

  const { id } = response.data.data.createThese;
  yield put(push(`/researcher/publications/updateThese/${id}`));
  showSuccessMessage('Thesis created successfully');
}

export function* fetchThese({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchTheseQuery(payload.id) });
  yield put(fetchTheseSuccess(response.data.data.theseById));
}

export function* updateThese({ payload }) {
  const response = yield call(axios.post, '/', { query: updateTheseMutation(payload) });
  yield put(updateTheseSuccess(response.data.data.updateThese));
  showSuccessMessage('Thesis updated successfully');
}

export function* loadThese({ payload }) {
  if (payload.id) {
    yield put(fetchTheseAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadTheseRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
  ]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_THESE,
    catchErrors({
      onErrorAction: createTheseError,
      overrideErrorMessage: 'Could not create publication of type these',
    })(createThese),
  );

  yield takeLatest(
    FETCH_THESE,
    catchErrors({
      onErrorAction: fetchTheseError,
      overrideErrorMessage: 'Could not fetch these',
    })(fetchThese),
  );

  yield takeLatest(
    LOAD_THESE_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadTheseRequirementsError,
    })(loadThese),
  );

  yield takeLatest(
    UPDATE_THESE,
    catchErrors({
      onErrorAction: updateTheseError,
      overrideErrorMessage: 'Could not update publication of type these',
    })(updateThese),
  );
}
