export const FETCH_ONE_PARTNER = 'FETCH_ONE_PARTNER';
export const FETCH_ONE_PARTNER_SUCCESS = 'FETCH_ONE_PARTNER_SUCCESS';
export const FETCH_ONE_PARTNER_ERROR = 'FETCH_ONE_PARTNER_ERROR';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_ERROR = 'UPDATE_PARTNER_ERROR';

export const fetchOnePartner = (data) => ({ type: FETCH_ONE_PARTNER, data });
export const fetchOnePartnerSuccess = (data) => ({ type: FETCH_ONE_PARTNER_SUCCESS, data });
export const fetchOnePartnerError = (error) => ({ type: FETCH_ONE_PARTNER_ERROR, error });

export const updatePartner = (data) => ({ type: UPDATE_PARTNER, data });
export const updatePartnerSuccess = (data) => ({ type: UPDATE_PARTNER_SUCCESS, data });
export const updatePartnerError = (error) => ({ type: UPDATE_PARTNER_ERROR, error });
