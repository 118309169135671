import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { Link, useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { Loader } from '../../../component/index';
import config from '../../../config/config';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

import {
  SET_PEOPLE_FILTER_ACTIVE,
  SET_PEOPLE_FILTER_STATUSTYPE,
  SET_PEOPLE_FILTER_TEAM,
  SET_PEOPLE_FILTER_STARTDATE,
  FETCH_ALL_FILTERED_PEOPLE,
  FETCH_ALL_PEOPLE_TYPES,
  FETCH_ALL_PEOPLE_TEAMS,
} from '../../../state/people/peopleActions';

import defaultImageJPG from '../../../assets/defaultProfileImage.jpg';

const statusFilterWeight = (status) => {
  switch (status) {
    case 'Faculty':
      return 1;
    case 'ResMan':
      return 2;
    case 'Postdoc':
      return 3;
    case 'Researcher':
      return 4;
    case 'Visitor':
      return 5;
    default:
      return 6;
  }
};

const roleFilterWeight = (status) => {
  switch (status) {
    case 'ADMIN':
      return 1;
    case 'RESEARCH_EXPERT':
      return 2;
    case 'RESEARCHER':
      return 3;
    case 'VISITOR':
      return 4;
    default:
      return 5;
  }
};

const columns = [
  {
    Header: 'Image',
    Cell: (row) => (
      // eslint-disable-next-line
      <Link to={`/admin/people/${row.original.id}`}>
        <Image
            // eslint-disable-next-line
          src={`${config.API_URL}/files/downloadProfilePicture/${row.original.id}`}
          onError={(e) => { e.target.onerror = null; e.target.src = defaultImageJPG; }}
          style={{
            display: 'block',
            width: 45,
            height: 45,
            margin: '0 auto',
          }}
          fluid
          roundedCircle
        />
      </Link>
    ),
  },
  {
    Header: 'Role',
    accessor: 'role',
    sortMethod: (a, b) => {
      const aWeight = roleFilterWeight(a);
      const bWeight = roleFilterWeight(b);
      if (aWeight === bWeight) {
        return 0;
      }

      return aWeight > bWeight ? -1 : +1;
    },
  },
  {
    Header: 'Status (Current)',
    accessor: 'currentStatus',
    sortMethod: (a, b, desc) => {
      if (desc) {
        const aWeight = statusFilterWeight(a);
        const bWeight = statusFilterWeight(b);
        if (aWeight === bWeight) {
          return 0;
        }
        return aWeight > bWeight ? -1 : +1;
      }
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
  },
  {
    Header: 'U-number',
    accessor: 'uNumber',
    sortType: 'basic',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
  },
  {
    Header: 'First name',
    accessor: 'firstname',
    sortType: 'basic',
  },
];

const People = (props) => {
  const {
    fetchAllFilteredPeople,
    people,
    loading,
    filter,
    setPeopleFilterActive,
    setPeopleFilterStatustype,
    setPeopleFilterTeam,
    setPeopleFilterStartdate,
    fetchTeams,
    fetchTypes,
    types,
    teams,
  } = props;
  const history = useHistory();
  const filterPeople = (e) => {
    fetchAllFilteredPeople();
    e.preventDefault();
  };
  const setStartdateFilter = (date) => {
    try {
      setPeopleFilterStartdate(date.format('YYYY-MM-DD'));
    } catch (e) {
      setPeopleFilterStartdate('');
    }
    fetchAllFilteredPeople(filter);
  };
  useEffect(() => {
    fetchAllFilteredPeople(filter);
    fetchTeams();
    fetchTypes();
  }, [fetchAllFilteredPeople, fetchTeams, fetchTypes, filter]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form onSubmit={filterPeople}>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Active/Inactive</Form.Label>
                      <Form.Control
                        as="select"
                        value={filter.active}
                        onChange={(val) => {
                          setPeopleFilterActive(val.target.value); fetchAllFilteredPeople();
                        }}
                      >
                        <option>Active</option>
                        <option>Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Status Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={filter.statusType}
                        onChange={(val) => {
                          setPeopleFilterStatustype(val.target.value); fetchAllFilteredPeople();
                        }}
                      >
                        <option label="Any" value={null}>{null}</option>
                        {types.map(
                          (el) => <option value={el.id} key={el.id}>{el.abbrv}</option>,
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Team</Form.Label>
                      <Form.Control as="select" value={filter.team} onChange={(val) => { setPeopleFilterTeam(val.target.value); fetchAllFilteredPeople(); }}>
                        <option label="Any" value={null}>{null}</option>
                        {teams.map(
                          (el) => <option value={el.id} key={el.id}>{el.name}</option>,
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Started After</Form.Label>
                      <Datetime dateFormat="YYYY-MM-DD" timeFormat={false} value={filter.startDate} onChange={(val) => setStartdateFilter(val)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              People
              <Button
                onClick={() => { history.push('/admin/people/new'); }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem',
                }}
              >
                New...
              </Button>
            </Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <ReactTable
                    columns={columns}
                    data={people}
                    defaultSorted={[
                      {
                        id: 'currentStatus',
                        desc: true,
                      },
                      {
                        id: 'lastname',
                        desc: true,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTdProps={(state, rowInfo, column) => ({
                      onClick: (e, handleOriginal) => {
                        if (column.Header !== 'Image') {
                          history.push(`/admin/people/${rowInfo.original.id}`);
                          if (handleOriginal) {
                            handleOriginal();
                          }
                        }
                      },
                    })}
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

People.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  teams: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAllFilteredPeople: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  setPeopleFilterActive: PropTypes.func.isRequired,
  setPeopleFilterStatustype: PropTypes.func.isRequired,
  setPeopleFilterTeam: PropTypes.func.isRequired,
  setPeopleFilterStartdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.people.isLoading,
  people: state.people.data,
  filter: state.people.filter,
  teams: state.people.teams,
  types: state.people.types,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllFilteredPeople: () => dispatch(FETCH_ALL_FILTERED_PEOPLE()),
  fetchTypes: () => dispatch(FETCH_ALL_PEOPLE_TYPES()),
  fetchTeams: () => dispatch(FETCH_ALL_PEOPLE_TEAMS()),
  setPeopleFilterActive: (value) => dispatch(SET_PEOPLE_FILTER_ACTIVE(value)),
  setPeopleFilterStatustype: (value) => dispatch(SET_PEOPLE_FILTER_STATUSTYPE(value)),
  setPeopleFilterTeam: (value) => dispatch(SET_PEOPLE_FILTER_TEAM(value)),
  setPeopleFilterStartdate: (value) => dispatch(SET_PEOPLE_FILTER_STARTDATE(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(People);
