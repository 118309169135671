export const DELETE_TYPE = 'DELETE_TYPE';
export const DELETE_TYPE_ERROR = 'DELETE_TYPE_ERROR';
export const DELETE_TYPE_SUCCESS = 'DELETE_TYPE_SUCCESS';
export const FETCH_ALL_TYPES = 'FETCH_ALL_TYPES';
export const FETCH_ALL_TYPES_SUCCESS = 'FETCH_ALL_TYPES_SUCCESS';
export const FETCH_ALL_TYPES_ERROR = 'FETCH_ALL_TYPES_ERROR';
export const SET_TYPE_FILTER_NAME = 'SET_TYPE_FILTER_NAME';

export const deleteType = (typeId) => ({ type: DELETE_TYPE, typeId });
export const deleteTypeSuccess = () => ({ type: DELETE_TYPE_SUCCESS });
export const deleteTypeError = (error) => ({ type: DELETE_TYPE_ERROR, error });

export const fetchAllTypes = () => ({ type: FETCH_ALL_TYPES });
export const fetchAllTypesSuccess = (data) => ({ type: FETCH_ALL_TYPES_SUCCESS, data });
export const fetchAllTypesError = (error) => ({ type: FETCH_ALL_TYPES_ERROR, error });

export const setTypeFilterName = (data) => ({ type: SET_TYPE_FILTER_NAME, data });
