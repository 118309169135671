import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import Datetime from 'react-datetime';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Formik } from 'formik';
import { Loader } from '../../../component/index';
import {
  updateEvent as updateEventAction,
  fetchOneEvent as fetchOneEventAction,
} from '../../../state/event/eventActions';
import { FETCH_ALL_PEOPLE } from '../../../state/people/peopleActions';
import { fetchAllCountries } from '../../../state/countries/countriesActions';
import validationSchema from './validationSchema';
import { eventTypes } from '../../../config/optionValues';
import { customFilterMatchAll } from '../../../component/publicationFormatTypes/typeHelper';

const EditEvent = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const {
    loading,
    event,
    fetchCountries,
    countriesOptions,
    people,
    fetchEvent,
    fetchAllPeople,
    updateEvent,
  } = props;
  useEffect(() => {
    fetchCountries();
    fetchEvent({ id });
    fetchAllPeople();
  }, [
    fetchCountries,
    fetchEvent,
    fetchAllPeople,
    id,
  ]);

  const handleDatetimeChange = (data, fieldName, format, setFieldValue) => {
    /*
      Work-around for `react-datetime`

      Change Formik value using `setFieldValue`.
      See:
      https://stackoverflow.com/questions/54039555/problem-with-saving-data-with-react-datetime-inside-formik-component
    */
    if (typeof (data) === 'string') {
      setFieldValue(fieldName, data);
    } else {
      setFieldValue(fieldName, data.format(format));
    }
  };

  const handleOrganizerChange = (val, setFieldValue) => {
    setFieldValue('organizers', val);
  };

  const displayForm = () => {
    const { state } = location;
    let ev = {};
    if (event) {
      if (Object.keys(event).length > 0) {
        ev = event;
      } else {
        ev = state ? (
          state.events.find((item) => item.id === parseInt(id, 10))
        ) : {};
      }
    } else {
      fetchEvent({ id });
    }
    const organizers = ev.people ? ev.people.map((p) => ({
      label: `${p.gn}, ${p.sn}`,
      value: p.id,
    })) : [];

    const initialValues = {
      type: ev.type,
      title: ev.title,
      description: ev.description,
      location: ev.location,
      city: ev.city,
      country: ev.country,
      participant: ev.participant,
      organizers,
      date: ev.date,
      endDate: ev.endDate,
      url: ev.url ? ev.url : '',
      start: ev.start ? event.start : '',
      end: ev.end ? event.end : '',
    };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const mutatedData = {
            ...values,
            organizers: values.organizers.map((data) => data.value),
            id,
          };
          updateEvent(mutatedData);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.title && errors.title ? 'error' : null}
                  />
                  {touched.title && errors.title ? (<div className="error-message">{errors.title}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <CreatableSelect
                    name="type"
                    onChange={(e) => setFieldValue('type', e.value)}
                    options={eventTypes}
                    value={eventTypes.find((t) => t.value === values.type)}
                  />
                  {touched.type && errors.type ? (<div className="error-message">{errors.type}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    defaultValue={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.description && errors.description ? 'error' : null}
                  />
                  {touched.description && errors.description ? (<div className="error-message">{errors.description}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Start date</Form.Label>
                  <Datetime
                    name="date"
                    value={values.date}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    onChange={(data) => handleDatetimeChange(data, 'date', 'YYYY-MM-DD', setFieldValue)}
                    className={touched.date && errors.date ? 'error' : null}
                  />
                  {touched.date && errors.date ? (<div className="error-message">{errors.date}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>End date</Form.Label>
                  <Datetime
                    name="endDate"
                    value={values.endDate}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    onChange={(data) => handleDatetimeChange(data, 'endDate', 'YYYY-MM-DD', setFieldValue)}
                    className={touched.endDate && errors.endDate ? 'error' : null}
                  />
                  {touched.endDate && errors.endDate ? (<div className="error-message">{errors.endDate}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Start Time</Form.Label>
                  <Datetime
                    name="start"
                    value={values.start}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    onChange={(data) => handleDatetimeChange(data, 'start', 'HH:mm', setFieldValue)}
                  />
                  {touched.start && errors.start ? (<div className="error-message">{errors.start}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>End time</Form.Label>
                  <Datetime
                    name="end"
                    value={values.end}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    onChange={(data) => handleDatetimeChange(data, 'end', 'HH:mm', setFieldValue)}
                  />
                  {touched.end && errors.end ? (<div className="error-message">{errors.end}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Participants</Form.Label>
                  <Form.Text muted>
                    An estimate of the number of participants is required for EU project reporting
                  </Form.Text>
                  <Form.Control
                    name="participant"
                    value={values.participant}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.participant && errors.participant ? 'error' : null}
                  />
                  {touched.participant && errors.participant ? (<div className="error-message">{errors.participant}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group>
                  <Form.Label>COSIC organizers</Form.Label>
                  <Select
                    name="organizers"
                    onChange={(val) => handleOrganizerChange(val, setFieldValue)}
                    options={people ? (
                      people.map((p) => ({
                        label: `${p.last}, ${p.first}`,
                        value: p.id,
                      }))
                    ) : []}
                    filterOption={customFilterMatchAll}
                    defaultValue={values.organizers}
                    isMulti
                  />
                  {touched.organizers && errors.organizers ? (<div className="error-message">{errors.organizers}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Link to event</Form.Label>
                  <Form.Control
                    name="url"
                    type="url"
                    defaultValue={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.location && errors.location ? 'error' : null}
                  />
                  {touched.location && errors.location ? (<div className="error-message">{errors.location}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.city && errors.city ? 'error' : null}
                  />
                  {touched.city && errors.city ? (<div className="error-message">{errors.city}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Select
                    name="country"
                    onChange={(s) => setFieldValue('country', s.value)}
                    options={countriesOptions}
                    value={countriesOptions.find((country) => country.value === values.country)}
                    defaultValue={countriesOptions.find((country) => (
                      country.value === values.country
                    ))}
                  />
                  {touched.country && errors.country ? (<div className="error-message">{errors.country}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    block
                  >
                    Save
                  </Button>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>Edit Event</Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <div>
                    {displayForm()}
                  </div>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

EditEvent.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchEvent: PropTypes.func.isRequired,
  fetchAllPeople: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countriesOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.event.isLoading,
  event: state.event.data,
  events: state.events.data,
  people: state.people.data,
  countriesOptions: state.publication.countriesOptions,
});

const mapDispatchToProps = (dispatch) => ({
  updateEvent: (data) => dispatch(updateEventAction(data)),
  fetchEvent: (data) => dispatch(fetchOneEventAction(data)),
  fetchAllPeople: () => dispatch(FETCH_ALL_PEOPLE()),
  fetchCountries: () => dispatch(fetchAllCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
