import {
  UPDATE_COUNTRY,
  FETCH_ONE_COUNTRY,
  FETCH_ONE_COUNTRY_SUCCESS,
  FETCH_ONE_COUNTRY_ERROR,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_ERROR,
} from './countryActions';

const defaultState = {
  isLoading: false,
  data: {},
  error: '',
};

const country = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ONE_COUNTRY:
    case UPDATE_COUNTRY:
      return { ...state, isLoading: true };

    case FETCH_ONE_COUNTRY_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_COUNTRY_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case UPDATE_COUNTRY_SUCCESS:
      return { ...state, data: {}, isLoading: false };
    case UPDATE_COUNTRY_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default country;
