import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Container,
  Button,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { find } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  linkProjectWithPublication as linkProjectWithPublicationAction,
  unlinkProjectFromPublication as unlinkProjectFromPublicationAction,
} from '../../../state/publication/publicationActions';
import { FETCH_ALL_PROJECTS } from '../../../state/project/projectActions';
import { selectAllProjectsAsOptions } from '../../../state/project/selectors';

const PublicationProjects = ({
  publicationId,
  publicationType,
  allProjects,
  linkedProjects,
  linkProjectWithPublication,
  unlinkProjectFromPublication,
  loadAllProjects,
}) => {
  useEffect(() => {
    loadAllProjects();
  }, [loadAllProjects]);

  const [projectId, setProjectId] = useState('');

  const handleLink = () => {
    linkProjectWithPublication({
      publicationId,
      projectId: projectId.value,
      publicationType,
    });
  };

  const handleUnlink = (id) => {
    unlinkProjectFromPublication({
      publicationId,
      projectId: id,
      publicationType,
    });
  };

  const filteredProjects = allProjects.filter(
    (p) => find({ id: p.value }, linkedProjects) === undefined,
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Select
            name="project"
            options={filteredProjects}
            value={projectId}
            onChange={(value) => setProjectId(value)}
          />
        </Col>
        <Col>
          <Button disabled={projectId === ''} variant="primary" onClick={() => handleLink()}>Link publication to project(s)</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card className="filter-card">
            <table className="table table-card table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col">Acronym</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  linkedProjects.map((project) => (
                    <tr key={project.id}>
                      <td>{project.name}</td>
                      <td>{project.acronym}</td>
                      <td>
                        <Button variant="danger" onClick={() => handleUnlink(project.id)}>Delete</Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

PublicationProjects.propTypes = {
  publicationId: PropTypes.string.isRequired,
  publicationType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  linkedProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  linkProjectWithPublication: PropTypes.func.isRequired,
  unlinkProjectFromPublication: PropTypes.func.isRequired,
  loadAllProjects: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  allProjects: selectAllProjectsAsOptions,
});

const mapDispatchToProps = (dispatch) => ({
  linkProjectWithPublication: (data) => dispatch(linkProjectWithPublicationAction(data)),
  unlinkProjectFromPublication: (data) => dispatch(unlinkProjectFromPublicationAction(data)),
  loadAllProjects: (data) => dispatch(FETCH_ALL_PROJECTS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicationProjects);
