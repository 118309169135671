import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  // eslint-disable-next-line max-len
  editorNameFormatter, editorNameFormatterText, getYearFromDate, idFormatter, replaceEdition, statusFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function Proceeding(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);

  const {
    id,
    volume,
    appeared,
    publisher,
    editors,
    title,
    status,
    index,
    edition,
    city,
    country,
    seriesName,
    webVersion,
    webVersionRecording,
    visibility,
    files,
    canEdit,
    isAuthenticated,
  } = props;
  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };
  const bibTex = (`
    @PROCEEDINGS{proceedings${id},
      booktitle = {${title}},
      editor =    {${editors ? editors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      volume =    {${volume || ''}},
      number =    {${edition || ''}},
      series =    {${seriesName || ''}},
      address =   {${city || ''}, ${country || ''}},
      publisher = {${publisher || ''}},
      year =      {${getYearFromDate(appeared)}}
    }
  `);
  const laTex = (`
    \\item { m ${title} ${seriesName || ''}, ${volume || ''}}, ${editorNameFormatterText(editors)}, ${publisher || ''}, ${getYearFromDate(appeared)}
  `);

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                &quot;
                {titleView(id, replaceEdition(edition, title, appeared), visibility, pdfFile, showPublication, 'proceeding', isAdmin)}
                &quot;
                {seriesName ? `, ${seriesName}` : ''}
                {volume ? ` ${volume}` : ''}
                {editors?.length > 0 ? ', ' : ''}
                {editorNameFormatter(editors, true)}
                {publisher ? `, ${publisher}` : ''}
                ,
                {' '}
                {getYearFromDate(appeared)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'proceeding')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateProceeding/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

Proceeding.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  city: PropTypes.string,
  country: PropTypes.string,
  seriesName: PropTypes.string,
  volume: PropTypes.string,
  appeared: PropTypes.string.isRequired,
  publisher: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  editors: PropTypes.arrayOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  edition: PropTypes.number,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

Proceeding.defaultProps = {
  city: '',
  country: '',
  edition: '',
  publisher: '',
  seriesName: '',
  volume: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(Proceeding);
