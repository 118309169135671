import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Table, Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../index';
import {
  mutatePersonAddAward,
  mutatePersonUpdateAward,
  mutatePersonRemoveAward,
} from '../../../state/person/personActions';

const EditPeopleAwardsForm = (props) => {
  const {
    loading,
    personid,
    awards,
    addAward,
    removeAward,
    updateAward,
    //    publications,
  } = props;
  const [inputVals, setInputVals] = useState({
    personid,
    id: -1,
    name: '',
    venue: '',
    title: '',
    result: '',
    date: '',
    bestPaper: false,
  });
  const [show, setShow] = useState(false);
  const [awardIdToDelete, setAwardIdToDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const selectARow = (idx) => {
    if (idx === -1) {
      setInputVals({
        personid,
        id: -1,
        name: '',
        venue: '',
        title: '',
        result: '',
        date: '',
        bestPaper: false,
      });
    } else {
      setInputVals({
        personid,
        id: awards[idx].id,
        name: awards[idx].name,
        venue: awards[idx].venue,
        title: awards[idx].title,
        result: awards[idx].result,
        date: (awards[idx].date) ? moment(awards[idx].date).format('YYYY-MM-DD') : '',
        bestPaper: awards[idx].bestPaper,
      });
    }
    setShow(true);
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required('^ mandatory field'),
    venue: Yup.string()
      .required('^ mandatory field'),
    title: Yup.string()
      .required('^ mandatory field'),
    result: Yup.string().nullable(),
    bestPaper: Yup.bool(),
    date: Yup.date()
      .required('^ mandatory field'),
  });
  const handleCloseEdit = () => {
    setShow(false);
  };

  const handleShowDelete = (idx) => {
    setAwardIdToDelete(idx);
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    setAwardIdToDelete(null);
  };
  const handleDelete = () => {
    removeAward({ awardid: awards[awardIdToDelete].id, personid });
    handleCloseDelete();
  };

  // const listOfPubTitles = () => {
  //   const pubs = [];
  //   if (publications.patents) {
  //     publications.patents.map(
  //       (patent) => pubs.push({ label: patent.title, value: patent.title }),
  //     );
  //   }
  //   if (publications.abstractOfTalks) {
  //     publications.abstractOfTalks.map(
  //       (aot) => pubs.push({ label: aot.title, value: aot.title }),
  //     );
  //   }
  //   if (publications.talks) {
  //     publications.talks.map(
  //       (talk) => pubs.push({ label: talk.title, value: talk.title }),
  //     );
  //   }
  //   if (publications.theses) {
  //     publications.theses.map(
  //       (these) => pubs.push({ label: these.title, value: these.title }),
  //     );
  //   }
  //   if (publications.proceedings) {
  //     publications.proceedings.map(
  //       (proc) => pubs.push({ label: proc.proceedings, value: proc.proceedings }),
  //     );
  //   }
  //   if (publications.books) {
  //     publications.books.map(
  //       (book) => pubs.push({ label: book.title, value: book.title }),
  //     );
  //   }
  //   if (publications.bookArticles) {
  //     publications.bookArticles.map(
  //       (bArticle) => pubs.push({ label: bArticle.title, value: bArticle.title }),
  //     );
  //   }
  //   if (publications.journalArticles) {
  //     publications.journalArticles.map(
  //       (jArticle) => pubs.push({ label: jArticle.title, value: jArticle.title }),
  //     );
  //   }
  //   if (publications.conferenceArticles) {
  //     publications.conferenceArticles.map(
  //       (cArticle) => pubs.push({ label: cArticle.title, value: cArticle.title }),
  //     );
  //   }
  //   if (publications.rumps) {
  //     publications.rumps.map(
  //       (rump) => pubs.push({ label: rump.title, value: rump.title }),
  //     );
  //   }
  //   if (publications.reports) {
  //     publications.reports.map(
  //       (report) => pubs.push({ label: report.title, value: report.title }),
  //     );
  //   }
  //   return pubs;
  // };

  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  const displayForm = () => (
    <Formik
      initialValues={inputVals}
      enableReinitialize
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.id === -1) {
          addAward(values);
        } else {
          updateAward(values);
        }
        setSubmitting(false);
        handleCloseEdit();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Full award name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="form-control"
              />
              {displayError(errors.name, touched.name)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Venue (without year, e.g. CHES)</Form.Label>
              <Form.Control
                type="text"
                name="venue"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.venue}
                className="form-control"
              />
              {displayError(errors.venue, touched.venue)}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Best paper award (winner)</Form.Label>
              <Form.Check
                name="bestPaper"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.bestPaper}
                value={values.bestPaper}
                className={touched.bestPaper && errors.bestPaper ? 'error' : null}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Title of related publication/work</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className="form-control"
              />
              {displayError(errors.title, touched.title)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Result/ranking (use plain number: 1,2,3 etc)</Form.Label>
              <Form.Control
                type="text"
                name="result"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.result}
                className="form-control"
              />
              {displayError(errors.result, touched.result)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Date</Form.Label>
              <Datetime
                name="date"
                value={values.date}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(val) => setFieldValue('date', moment(val).format('YYYY-MM-DD'))}
                onClose={(val) => setFieldValue('date', moment(val).format('YYYY-MM-DD'))}
              />
              {displayError(errors.date, touched.date)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%' }}>
                Save
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
  const wpModalFormDelete = () => (
    <Modal
      show={showDelete}
      onHide={handleCloseDelete}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Delete award (
          {(awardIdToDelete !== null) ? awards[awardIdToDelete].name : ''}
          )?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>This action is permanent.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDelete}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const wpModalFormEdit = () => (
    <Modal
      show={show}
      onHide={handleCloseEdit}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>{displayForm()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <div>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Full award title</th>
                  <th>Venue</th>
                  <th>Title of related publication/work</th>
                  <th>Result/ranking</th>
                  <th>Date</th>
                  <th>Best paper award</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {awards.sort((a, b) => moment(b.date) - moment(a.date)).map((el, idx) => (
                  <tr key={`awards_${el.id}`}>
                    <td>{el.id}</td>
                    <td>{el.name}</td>
                    <td>{el.venue}</td>
                    <td>{el.title}</td>
                    <td>{el.result}</td>
                    <td>{moment(el.date).format('YYYY-MM-DD')}</td>
                    <td>{(el.bestPaper) ? 'Yes' : 'No'}</td>
                    <td>
                      <Button className="btn btn-primary fixed-size-button" onClick={() => selectARow(idx)}>
                        Edit
                      </Button>
                      <Button className="btn btn-danger fixed-size-button" onClick={() => handleShowDelete(idx)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <Button className="btn btn-primary fixed-size-button" onClick={() => selectARow(-1)}>
                      New
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {wpModalFormDelete()}
            {wpModalFormEdit()}
          </div>
        )}
    </div>
  );
};

EditPeopleAwardsForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  awards: PropTypes.arrayOf(PropTypes.any),
  personid: PropTypes.string,
  addAward: PropTypes.func.isRequired,
  updateAward: PropTypes.func.isRequired,
  removeAward: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  publications: PropTypes.arrayOf(PropTypes.any).isRequired,
};

EditPeopleAwardsForm.defaultProps = {
  personid: '-1',
  awards: [],
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  personid: state.person.data.id,
  awards: state.person.data.awards,
});

const mapDispatchToProps = (dispatch) => ({
  addAward: (data) => dispatch(mutatePersonAddAward(data)),
  updateAward: (data) => dispatch(mutatePersonUpdateAward(data)),
  removeAward: (data) => dispatch(mutatePersonRemoveAward(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleAwardsForm);
