import React from 'react';
import { Container } from 'react-bootstrap';
import JournalArticle from './JournalArticle';
import ConferenceArticle from './ConferenceArticle';
import BookChapter from './BookChapter';
import Report from './Report';
import Book from './Book';
import Thesis from './Thesis';
import Proceeding from './Proceeding';
import Patent from './Patent';
import AbstractOfTalk from './AbstractOfTalk';
import Rump from './Rump';
import Talk from './Talk';

const categoryStyle = {
  backgroundColor: 'black',
  color: 'white',
};

const renderTable = (Comp, identifier) => (
  <table key={identifier} className="table table-striped">
    <tbody>
      {Comp}
    </tbody>
  </table>
);

function createConferenceArticle(count, ca, canEdit) {
  return (
    <ConferenceArticle
      index={count}
      key={ca.id}
      id={ca.id}
      title={ca.title}
      status={ca.status}
      pages={ca.pages}
      from={ca.from}
      proceedingsFrom={ca.proceedingsFrom}
      conference={ca.conference}
      authors={ca.authors}
      editors={ca.editors}
      proceedingTitle={ca.proceedingTitle}
      edition={ca.edition}
      files={ca.files}
      webVersion={ca.webVersion}
      webVersionRecording={ca.webVersionRecording}
      visibility={ca.visibility}
      canEdit={canEdit}
      series={ca.series}
      volume={ca.volume}
      publisher={ca.publisher}
    />
  );
}

function createJournalArticle(count, ja, canEdit) {
  return (
    <JournalArticle
      index={count}
      key={ja.id}
      id={ja.id}
      authors={ja.authors}
      title={ja.title}
      status={ja.status}
      journalTitle={ja.journalTitle}
      number={ja.number}
      volume={ja.volume}
      pages={ja.pages}
      from={ja.from}
      date={ja.date}
      files={ja.files}
      webVersion={ja.webVersion}
      webVersionRecording={ja.webVersionRecording}
      visibility={ja.visibility}
      canEdit={canEdit}
    />
  );
}

function createBookChapter(count, ba, canEdit) {
  return (
    <BookChapter
      index={count}
      key={ba.id}
      id={ba.id}
      title={ba.title}
      status={ba.status}
      publisher={ba.publisher}
      date={ba.date}
      pages={ba.pages}
      authors={ba.authors}
      editors={ba.editors}
      bookTitle={ba.bookTitle}
      files={ba.files}
      visibility={ba.visibility}
      webVersionRecording={ba.webVersionRecording}
      canEdit={canEdit}
    />
  );
}

function createThesis(count, th, canEdit) {
  return (
    <Thesis
      index={count}
      id={th.id}
      title={th.title}
      status={th.status}
      pages={th.pages}
      promotors={th.promotors}
      authors={th.authors}
      institution={th.institution}
      type={th.type}
      date={th.date}
      files={th.files}
      webVersion={th.webVersion}
      webVersionRecording={th.webVersionRecording}
      visibility={th.visibility}
      canEdit={canEdit}
    />
  );
}

function createProceeding(count, pr, canEdit) {
  return (
    <Proceeding
      index={count}
      id={pr.id}
      volume={pr.volume}
      appeared={pr.appeared}
      publisher={pr.publisher}
      editors={pr.editors}
      title={pr.proceedings}
      status={pr.status}
      edition={pr.edition}
      city={pr.city}
      country={pr.country}
      seriesName={pr.seriesName}
      files={pr.files}
      webVersion={pr.webVersion}
      webVersionRecording={pr.webVersionRecording}
      visibility={pr.visibility}
      canEdit={canEdit}
    />
  );
}

function createBook(count, b, canEdit) {
  return (
    <Book
      index={count}
      id={b.id}
      title={b.title}
      status={b.status}
      publisher={b.publisher}
      date={b.date}
      pages={b.pages}
      authors={b.authors}
      editors={b.editors}
      seriesName={b.seriesName}
      volume={b.volume}
      files={b.files}
      webVersion={b.webVersion}
      webVersionRecording={b.webVersionRecording}
      visibility={b.visibility}
      canEdit={canEdit}
    />
  );
}

function createReport(count, rp, canEdit) {
  return (
    <Report
      index={count}
      id={rp.id}
      title={rp.title}
      status={rp.status}
      pages={rp.pages}
      date={rp.date}
      authors={rp.authors}
      note={rp.note}
      files={rp.files}
      webVersion={rp.webVersion}
      webVersionRecording={rp.webVersionRecording}
      visibility={rp.visibility}
      canEdit={canEdit}
    />
  );
}

function createAbstractOfTalk(count, abstractOfTalk, canEdit) {
  return (
    <AbstractOfTalk
      index={count}
      id={abstractOfTalk.id}
      title={abstractOfTalk.title}
      status={abstractOfTalk.status}
      event={abstractOfTalk.event}
      city={abstractOfTalk.city}
      state={abstractOfTalk.state}
      country={abstractOfTalk.country}
      from={abstractOfTalk.from}
      proceedingsFrom={abstractOfTalk.proceedingsFrom}
      edition={abstractOfTalk.edition}
      pages={abstractOfTalk.pages}
      authors={abstractOfTalk.authors}
      proceedings={abstractOfTalk.proceedings}
      publisher={abstractOfTalk.publisher}
      volume={abstractOfTalk.volume}
      files={abstractOfTalk.files}
      webVersion={abstractOfTalk.webVersion}
      webVersionRecording={abstractOfTalk.webVersionRecording}
      visibility={abstractOfTalk.visibility}
      canEdit={canEdit}
    />
  );
}

function createTalk(count, t, canEdit) {
  return (
    <Talk
      index={count}
      id={t.id}
      title={t.title}
      status={t.status}
      event={t.event}
      city={t.city}
      country={t.country}
      from={t.from}
      authors={t.authors}
      files={t.files}
      webVersion={t.webVersion}
      webVersionRecording={t.webVersionRecording}
      visibility={t.visibility}
      canEdit={canEdit}
    />
  );
}

function createRump(count, r, canEdit) {
  return (
    <Rump
      index={count}
      id={r.id}
      title={r.title}
      status={r.status}
      pages={r.pages}
      from={r.from}
      edition={r.edition}
      authors={r.authors}
      proceedings={r.proceedings}
      files={r.files}
      webVersion={r.webVersion}
      webVersionRecording={r.webVersionRecording}
      visibility={r.visibility}
      canEdit={canEdit}
    />
  );
}

function createPatent(count, patent, canEdit) {
  return (
    <Patent
      index={count}
      id={patent.id}
      title={patent.title}
      status={patent.status}
      description={patent.description}
      date={patent.date}
      authors={patent.authors}
      number={patent.number}
      applicant={patent.applicant}
      files={patent.files}
      webVersion={patent.webVersion}
      webVersionRecording={patent.webVersionRecording}
      visibility={patent.visibility}
      canEdit={canEdit}
    />
  );
}

const checkPubType = (publications, canEdit) => {
  const pubs = [];
  let articles = false;

  // Articles category
  if (publications.articlesCategory) {
    const journalArticles = publications.articlesCategory.journalCategory;
    const conferenceArticles = publications.articlesCategory.conferenceCategory;
    const bookArticles = publications.articlesCategory.bookArticlesCategory;

    if (
      conferenceArticles.internationalReviewed.length > 0
      || conferenceArticles.internationalNotReviewed.length > 0
      || conferenceArticles.localReviewed.length > 0
      || conferenceArticles.localNotReviewed.length > 0
      || journalArticles.internationalReviewed.length > 0
      || journalArticles.internationalNotReviewed.length > 0
      || journalArticles.localReviewed.length > 0
      || journalArticles.localNotReviewed.length > 0
      || bookArticles.internationalReviewed.length > 0
      || bookArticles.internationalNotReviewed.length > 0
      || bookArticles.localReviewed.length > 0
      || bookArticles.localNotReviewed.length > 0
    ) {
      pubs.push(<h2 key="article" style={categoryStyle}>Articles</h2>);
      articles = true;
      // Conference articles
      if (conferenceArticles.internationalReviewed.length > 0) {
        pubs.push(<h3 key="proceedings-reviewed">In Proceedings international reviewed</h3>);
        pubs.push(
          conferenceArticles.internationalReviewed.map((ir) => {
            let count = 0;
            return (
              <div key={ir.date}>
                <h3>{ir.date}</h3>
                {ir.conferenceArticles.map((ca) => {
                  count += 1;
                  return renderTable(
                    createConferenceArticle(count, ca, canEdit),
                    ca.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (conferenceArticles.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="proceedings-not-reviewed">In Proceedings international not reviewed</h3>);
        pubs.push(
          conferenceArticles.internationalNotReviewed.map((ir) => {
            let count = 0;
            return (
              <div key={ir.date}>
                <h3>{ir.date}</h3>
                {ir.conferenceArticles.map((ca) => {
                  count += 1;
                  return renderTable(
                    createConferenceArticle(count, ca, canEdit),
                    ca.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (conferenceArticles.localReviewed.length > 0) {
        pubs.push(<h3 key="proceedings-local-reviewed">In Proceedings local reviewed</h3>);
        pubs.push(
          conferenceArticles.localReviewed.map((ir) => {
            let count = 0;
            return (
              <div key={ir.date}>
                <h3>{ir.date}</h3>
                {ir.conferenceArticles.map((ca) => {
                  count += 1;
                  return renderTable(
                    createConferenceArticle(count, ca, canEdit),
                    ca.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (conferenceArticles.localNotReviewed.length > 0) {
        pubs.push(<h3 key="proceedings-local-not-reviewed">In Proceedings local not reviewed</h3>);
        pubs.push(
          conferenceArticles.localNotReviewed.map((ir) => {
            let count = 0;
            return (
              <div key={ir.date}>
                <h3>{ir.date}</h3>
                {ir.conferenceArticles.map((ca) => {
                  count += 1;
                  return renderTable(
                    createConferenceArticle(count, ca, canEdit),
                    ca.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      // Journal articles
      if (journalArticles.internationalReviewed.length > 0) {
        pubs.push(<h3 key="journalArticle">In journal international reviewed</h3>);
        pubs.push(
          journalArticles.internationalReviewed.map((jr) => {
            let count = 0;
            return (
              <div key={jr.date}>
                <h3>{jr.date}</h3>
                {jr.journals.map((ja) => {
                  count += 1;
                  return renderTable(
                    createJournalArticle(count, ja, canEdit),
                    ja.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (journalArticles.internationalNotReviewed.length > 0) {
        pubs.push(
          <h3 key="journal-international-not-reviewed">
            In journal international not reviewed
          </h3>,
        );
        pubs.push(
          journalArticles.internationalNotReviewed.map((jr) => {
            let count = 0;
            return (
              <div key={jr.date}>
                <h3>{jr.date}</h3>
                {jr.journals.map((ja) => {
                  count += 1;
                  return renderTable(
                    createJournalArticle(count, ja, canEdit),
                    ja.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (journalArticles.localReviewed.length > 0) {
        pubs.push(<h3 key="journal-local-reviewed">In journal local reviewed</h3>);
        pubs.push(
          journalArticles.localReviewed.map((jr) => {
            let count = 0;
            return (
              <div key={jr.date}>
                <h3>{jr.date}</h3>
                {jr.journals.map((ja) => {
                  count += 1;
                  return renderTable(
                    createJournalArticle(count, ja, canEdit),
                    ja.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (journalArticles.localNotReviewed.length > 0) {
        pubs.push(<h3 key="journal-local-not-reviewed">In journal local not reviewed</h3>);
        pubs.push(
          journalArticles.localNotReviewed.map((jr) => {
            let count = 0;
            return (
              <div key={jr.date}>
                <h3>{jr.date}</h3>
                {jr.journals.map((ja) => {
                  count += 1;
                  return renderTable(
                    createJournalArticle(count, ja, canEdit),
                    ja.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      // Book articles
      if (bookArticles.internationalReviewed.length > 0) {
        pubs.push(<h3 key="book-reviewed">In a book international reviewed</h3>);
        pubs.push(
          bookArticles.internationalReviewed.map((ir) => {
            let count = 0;
            return (
              <div key={ir.date}>
                <h3>{ir.date}</h3>
                {ir.bookArticleViews.map((ba) => {
                  count += 1;
                  return renderTable(
                    createBookChapter(count, ba, canEdit),
                    ba.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (bookArticles.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="book-not-reviewed">In a book international not reviewed</h3>);
        pubs.push(
          bookArticles.internationalNotReviewed.map((ir) => {
            let count = 0;
            return (
              <div>
                <h3>{ir.date}</h3>
                {ir.bookArticleViews.map((ba) => {
                  count += 1;
                  return renderTable(
                    createBookChapter(count, ba, canEdit),
                    ba.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (bookArticles.localReviewed.length > 0) {
        pubs.push(<h3 key="book-local-reviewed">In a book local reviewed</h3>);
        pubs.push(
          bookArticles.localReviewed.map((ir) => {
            let count = 0;
            return (
              <div>
                <h3>{ir.date}</h3>
                {ir.bookArticleViews.map((ba) => {
                  count += 1;
                  return renderTable(
                    createBookChapter(count, ba, canEdit),
                    ba.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (bookArticles.localNotReviewed.length > 0) {
        pubs.push(<h3 key="book-local-not-reviewed">In a book local not reviewed</h3>);
        pubs.push(
          bookArticles.localNotReviewed.map((ir) => {
            let count = 0;
            return (
              <div>
                <h3>{ir.date}</h3>
                {ir.bookArticleViews.map((ba) => {
                  count += 1;
                  return renderTable(
                    createBookChapter(count, ba, canEdit),
                    ba.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Theses
  if (publications.thesesCategory) {
    if (publications.thesesCategory.phdTheses.length > 0
            || publications.thesesCategory.masterTheses.length > 0
            || publications.thesesCategory.bachelorTheses.length > 0) {
      pubs.push(<h2 key="theses" style={categoryStyle}>Theses</h2>);
      if (publications.thesesCategory.phdTheses.length > 0) {
        pubs.push(<h3 key="phd-thesis">PhD thesis</h3>);
        pubs.push(publications.thesesCategory.phdTheses.map((these) => {
          let count = 0;
          return (
            <div>
              <h3>{these.date}</h3>
              {
                                these.theses.map((th) => {
                                  count += 1;
                                  return renderTable(
                                    createThesis(count, th, canEdit),
                                    th.id,
                                  );
                                })
                            }
            </div>
          );
        }));
      }
      if (publications.thesesCategory.masterTheses.length > 0) {
        pubs.push(<h3 key="master-thesis">Master thesis</h3>);
        pubs.push(publications.thesesCategory.masterTheses.map((these) => {
          let count = 0;
          return (
            <div>
              <h3>{these.date}</h3>
              {
                                these.theses.map((th) => {
                                  count += 1;
                                  return renderTable(
                                    createThesis(count, th, canEdit),
                                    th.id,
                                  );
                                })
                            }
            </div>
          );
        }));
      }
      if (publications.thesesCategory.bachelorTheses.length > 0) {
        pubs.push(<h3 key="bachelor-thesis">Bachelor thesis</h3>);
        pubs.push(publications.thesesCategory.bachelorTheses.map((these) => {
          let count = 0;
          return (
            <div>
              <h3>{these.date}</h3>
              {
                                these.theses.map((th) => {
                                  count += 1;
                                  return renderTable(
                                    createThesis(count, th, canEdit),
                                    th.id,
                                  );
                                })
                            }
            </div>
          );
        }));
      }
    }
  }
  // Proceedings
  if (publications.proceedingCategory) {
    if (publications.proceedingCategory.internationalReviewed.length > 0
            || publications.proceedingCategory.internationalNotReviewed.length > 0
            || publications.proceedingCategory.localReviewed.length > 0
            || publications.proceedingCategory.localNotReviewed.length > 0) {
      pubs.push(<h2 key="proceeding-editor" style={categoryStyle}>Editor of following Proceedings</h2>);
      if (publications.proceedingCategory.internationalReviewed.length > 0) {
        pubs.push(<h3 key="proceeding-international-reviewed">International reviewed</h3>);
        pubs.push(
          publications.proceedingCategory.internationalReviewed.map((proceeding) => {
            let count = 0;
            return (
              <div>
                <h3>{proceeding.date}</h3>
                {proceeding.proceedings.map((pr) => {
                  count += 1;
                  return renderTable(
                    createProceeding(count, pr, canEdit),
                    pr.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.proceedingCategory.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="proceeding-international-not-reviewed">International not reviewed</h3>);
        pubs.push(
          publications.proceedingCategory.internationalNotReviewed.map((proceeding) => {
            let count = 0;
            return (
              <div>
                <h3>{proceeding.date}</h3>
                {proceeding.proceedings.map((pr) => {
                  count += 1;
                  return renderTable(
                    createProceeding(count, pr, canEdit),
                    pr.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.proceedingCategory.localReviewed.length > 0) {
        pubs.push(<h3 key="proceeding-local-reviewed">Local reviewed</h3>);
        pubs.push(
          publications.proceedingCategory.internationalReviewed.map((proceeding) => {
            let count = 0;
            return (
              <div>
                <h3>{proceeding.date}</h3>
                {proceeding.proceedings.map((pr) => {
                  count += 1;
                  return renderTable(
                    createProceeding(count, pr, canEdit),
                    pr.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.proceedingCategory.localNotReviewed.length > 0) {
        pubs.push(<h3 key="proceeding-local-not-reviewed">Local not reviewed</h3>);
        pubs.push(
          publications.proceedingCategory.internationalReviewed.map((proceeding) => {
            let count = 0;
            return (
              <div>
                <h3>{proceeding.date}</h3>
                {proceeding.proceedings.map((pr) => {
                  count += 1;
                  return renderTable(
                    createProceeding(count, pr, canEdit),
                    pr.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Books
  if (publications.books) {
    if (publications.books.internationalReviewed.length > 0
            || publications.books.internationalNotReviewed.length > 0
            || publications.books.localReviewed.length > 0
            || publications.books.localNotReviewed.length > 0) {
      pubs.push(<h2 key="books" style={categoryStyle}>Books</h2>);

      if (publications.books.internationalReviewed.length > 0) {
        pubs.push(<h3 key="international-reviewed">International reviewed</h3>);
        pubs.push(
          publications.books.internationalReviewed.map((book) => {
            let count = 0;
            return (
              <div>
                <h3>{book.date}</h3>
                {book.books.map((b) => {
                  count += 1;
                  return renderTable(
                    createBook(count, b, canEdit),
                    b.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.books.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="international-not-reviewed">International not reviewed</h3>);
        pubs.push(
          publications.books.internationalNotReviewed.map((book) => {
            let count = 0;
            return (
              <div>
                <h3>{book.date}</h3>
                {book.books.map((b) => {
                  count += 1;
                  return renderTable(
                    createBook(count, b, canEdit),
                    b.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.books.localReviewed.length > 0) {
        pubs.push(<h3 key="local-reviewed">Local reviewed</h3>);
        pubs.push(
          publications.books.localReviewed.map((book) => {
            let count = 0;
            return (
              <div>
                <h3>{book.date}</h3>
                {book.books.map((b) => {
                  count += 1;
                  return renderTable(
                    createBook(count, b, canEdit),
                    b.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.books.localNotReviewed.length > 0) {
        pubs.push(<h3 key="local-not-reviewed">Local not reviewed</h3>);
        pubs.push(
          publications.books.localNotReviewed.map((book) => {
            let count = 0;
            return (
              <div>
                <h3>{book.date}</h3>
                {book.books.map((b) => {
                  count += 1;
                  return renderTable(
                    createBook(count, b, canEdit),
                    b.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Internal reports
  if (publications.internalReport && publications.internalReport.length > 0) {
    if (!articles) {
      pubs.push(<h2 style={categoryStyle}>Articles</h2>);
    }
    pubs.push(<h3 key="internal-report">Internal reports</h3>);
    pubs.push(publications.internalReport.map((report) => {
      let count = 0;
      return (
        <div>
          <h3>{report.date}</h3>
          {
            report.reports.map((rp) => {
              count += 1;
              return renderTable(
                createReport(count, rp, canEdit),
                rp.id,
              );
            })
          }
        </div>
      );
    }));
  }
  // Abstract of Talk
  if (publications.abstractOfTalkCategory) {
    if (publications.abstractOfTalkCategory.internationalReviewed.length > 0
            || publications.abstractOfTalkCategory.internationalNotReviewed.length > 0
            || publications.abstractOfTalkCategory.localReviewed.length > 0
            || publications.abstractOfTalkCategory.localNotReviewed.length > 0) {
      pubs.push(<h2 key="abstractoftalk" style={categoryStyle}>Abstracts of Talks</h2>);
      if (publications.abstractOfTalkCategory.internationalReviewed.length > 0) {
        pubs.push(<h3 key="abstractoftalk-international-reviewed">International reviewed</h3>);
        pubs.push(
          publications.abstractOfTalkCategory.internationalReviewed.map((aot) => {
            let count = 0;
            return (
              <div>
                <h3>{aot.date}</h3>
                {aot.abstractOfTalks.map((abstractOfTalk) => {
                  count += 1;
                  return renderTable(
                    createAbstractOfTalk(count, abstractOfTalk, canEdit),
                    abstractOfTalk.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.abstractOfTalkCategory.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="abstractoftalk-international-not-reviewed">International not reviewed</h3>);
        pubs.push(
          publications.abstractOfTalkCategory.internationalNotReviewed.map((aot) => {
            let count = 0;
            return (
              <div>
                <h3>{aot.date}</h3>
                {aot.abstractOfTalks.map((abstractOfTalk) => {
                  count += 1;
                  return renderTable(
                    createAbstractOfTalk(count, abstractOfTalk, canEdit),
                    abstractOfTalk.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.abstractOfTalkCategory.localReviewed.length > 0) {
        pubs.push(<h3 key="abstractoftalk-local-reviewed">Local reviewed</h3>);
        pubs.push(
          publications.abstractOfTalkCategory.internationalReviewed.map((aot) => {
            let count = 0;
            return (
              <div>
                <h3>{aot.date}</h3>
                {aot.abstractOfTalks.map((abstractOfTalk) => {
                  count += 1;
                  return renderTable(
                    createAbstractOfTalk(count, abstractOfTalk, canEdit),
                    abstractOfTalk.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.abstractOfTalkCategory.localNotReviewed.length > 0) {
        pubs.push(<h3 key="abstractoftalk-local-not-reviewed">Local not reviewed</h3>);
        pubs.push(
          publications.abstractOfTalkCategory.internationalReviewed.map((aot) => {
            let count = 0;
            return (
              <div>
                <h3>{aot.date}</h3>
                {aot.abstractOfTalks.map((abstractOfTalk) => {
                  count += 1;
                  return renderTable(
                    createAbstractOfTalk(count, abstractOfTalk, canEdit),
                    abstractOfTalk.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Talk
  if (publications.talkCategory) {
    if (publications.talkCategory.internationalNotReviewed.length > 0
            || publications.talkCategory.localNotReviewed.length > 0) {
      pubs.push(<h2 key="talks" style={categoryStyle}>Talks</h2>);
      if (publications.talkCategory.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="talk-international-not-reviewed">International not reviewed</h3>);
        pubs.push(
          publications.talkCategory.internationalNotReviewed.map((talk) => {
            let count = 0;
            return (
              <div>
                <h3>{talk.date}</h3>
                {talk.talks.map((t) => {
                  count += 1;
                  return renderTable(
                    createTalk(count, t, canEdit),
                    t.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.talkCategory.localNotReviewed.length > 0) {
        pubs.push(<h3 key="talk-local-not-reviewed">Local not reviewed</h3>);
        pubs.push(
          publications.talkCategory.localNotReviewed.map((talk) => {
            let count = 0;
            return (
              <div>
                <h3>{talk.date}</h3>
                {talk.talks.map((t) => {
                  count += 1;
                  return renderTable(
                    createTalk(count, t, canEdit),
                    t.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Rump
  if (publications.rumpCategory) {
    if (publications.rumpCategory.internationalReviewed.length > 0
            || publications.rumpCategory.internationalNotReviewed.length > 0
            || publications.rumpCategory.localReviewed.length > 0
            || publications.rumpCategory.localNotReviewed.length > 0) {
      pubs.push(<h2 key="rump" style={categoryStyle}>Rump Sessions</h2>);
      if (publications.rumpCategory.internationalReviewed.length > 0) {
        pubs.push(<h3 key="rump-international-reviewed">International reviewed</h3>);
        pubs.push(
          publications.rumpCategory.internationalReviewed.map((rump) => {
            let count = 0;
            return (
              <div>
                <h3>{rump.date}</h3>
                {rump.rumps.map((r) => {
                  count += 1;
                  return renderTable(
                    createRump(count, r, canEdit),
                    r.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.rumpCategory.internationalNotReviewed.length > 0) {
        pubs.push(<h3 key="rump-international-not-reviewed">International not reviewed</h3>);
        pubs.push(
          publications.rumpCategory.internationalNotReviewed.map((rump) => {
            let count = 0;
            return (
              <div>
                <h3>{rump.date}</h3>
                {rump.rumps.map((r) => {
                  count += 1;
                  return renderTable(
                    createRump(count, r, canEdit),
                    r.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.rumpCategory.localReviewed.length > 0) {
        pubs.push(<h3 key="rump-local-reviewed">Local reviewed</h3>);
        pubs.push(
          publications.rumpCategory.internationalReviewed.map((rump) => {
            let count = 0;
            return (
              <div>
                <h3>{rump.date}</h3>
                {rump.rumps.map((r) => {
                  count += 1;
                  return renderTable(
                    createRump(count, r, canEdit),
                    r.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
      if (publications.rumpCategory.localNotReviewed.length > 0) {
        pubs.push(<h3 key="rump-local-not-reviewed">Local not reviewed</h3>);
        pubs.push(
          publications.rumpCategory.internationalReviewed.map((rump) => {
            let count = 0;
            return (
              <div>
                <h3>{rump.date}</h3>
                {rump.rumps.map((r) => {
                  count += 1;
                  return renderTable(
                    createRump(count, r, canEdit),
                    r.id,
                  );
                })}
              </div>
            );
          }),
        );
      }
    }
  }
  // Patent
  if (publications.patents && publications.patents.length > 0) {
    pubs.push(<h2 key="patents" style={categoryStyle}>Patents</h2>);
    pubs.push(publications.patents.map((pat) => {
      let count = 0;
      return (
        <div>
          <h3>{pat.date}</h3>
          {
            pat.patents.map((patent) => {
              count += 1;
              return renderTable(
                createPatent(count, patent, canEdit),
                patent.id,
              );
            })
          }
        </div>
      );
    }));
  }

  return pubs;
};

const renderPublicationByCategory = (pbc, canEdit) => (
  <Container fluid>
    {checkPubType(pbc, canEdit)}
  </Container>
);

function ByCategoryWrapper(props) {
  const { pbc, canEdit } = props;
  return renderPublicationByCategory(pbc, canEdit);
}

export default ByCategoryWrapper;
