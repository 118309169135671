import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectProject = get('project');
export const selectIsLoading = createSelector(selectProject, (project) => project.isLoading);
export const selectProjectDetail = createSelector(selectProject, (project) => project.detail.data);
export const selectProjectDetailId = createSelector(
  selectProject,
  (project) => project.detail.selectedId,
);

export const selectAllProjects = createSelector(
  selectProject,
  (project) => (project.data ? project.data : []),
);
export const selectAllProjectsAsOptions = createSelector(
  selectAllProjects,
  (projects) => projects.map((project) => ({ value: project.id, label: `${project.name} (${project.acronym})` })),
);

export const selectAllProjectTypes = createSelector(
  selectProject,
  (data) => (data.types ? data.types : []),
);

export const selectAllProjectTypesAsOptions = createSelector(
  selectAllProjectTypes,
  (types) => types.map((type) => ({ value: type.name, label: type.name })),
);

export const selectProjectFilters = createSelector(selectProject, get('filters'));
