export const FETCH_JOURNAL_BY_YEAR = (data) => ({ type: 'FETCH_JOURNAL_BY_YEAR', data });
export const FETCH_CONFERENCE_BY_YEAR = (data) => ({ type: 'FETCH_CONFERENCE_BY_YEAR', data });
export const FETCH_STATUS_HISTORY_COUNT_BY_YEAR = (data) => ({ type: 'FETCH_STATUS_HISTORY_COUNT_BY_YEAR', data });
export const FETCH_VISITORS_BETWEEN_BY_YEAR = (data) => ({ type: 'FETCH_VISITORS_BETWEEN_BY_YEAR', data });
export const FETCH_BEST_PAPER_BY_YEAR = (data) => ({ type: 'FETCH_BEST_PAPER_BY_YEAR', data });
export const FETCH_AWARDS_BY_YEAR = (data) => ({ type: 'FETCH_AWARDS_BY_YEAR', data });
export const FETCH_PROJECTS_RATE = () => ({ type: 'FETCH_PROJECTS_RATE' });
export const FETCH_GRANT_RATE = () => ({ type: 'FETCH_GRANT_RATE' });
export const FETCH_BILATERAL_RATE = () => ({ type: 'FETCH_BILATERAL_RATE' });
export const FETCH_TOTAL_RATE = () => ({ type: 'FETCH_TOTAL_RATE' });
export const FETCH_LOCATION_RATE = () => ({ type: 'FETCH_LOCATION_RATE' });
export const FETCH_FUNDER_RATE = () => ({ type: 'FETCH_FUNDER_RATE' });

export const FETCH_JOURNAL_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_JOURNAL_BY_YEAR_SUCCESS', data });
export const FETCH_CONFERENCE_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_CONFERENCE_BY_YEAR_SUCCESS', data });
export const FETCH_STATUS_HISTORY_COUNT_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_STATUS_HISTORY_COUNT_BY_YEAR_SUCCESS', data });
export const FETCH_VISITORS_BETWEEN_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_VISITORS_BETWEEN_BY_YEAR_SUCCESS', data });
export const FETCH_BEST_PAPER_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_BEST_PAPER_BY_YEAR_SUCCESS', data });
export const FETCH_AWARDS_BY_YEAR_SUCCESS = (data) => ({ type: 'FETCH_AWARDS_BY_YEAR_SUCCESS', data });
export const FETCH_PROJECTS_RATE_SUCCESS = (data) => ({ type: 'FETCH_PROJECTS_RATE_SUCCESS', data });
export const FETCH_GRANT_RATE_SUCCESS = (data) => ({ type: 'FETCH_GRANT_RATE_SUCCESS', data });
export const FETCH_BILATERAL_RATE_SUCCESS = (data) => ({ type: 'FETCH_BILATERAL_RATE_SUCCESS', data });
export const FETCH_TOTAL_RATE_SUCCESS = (data) => ({ type: 'FETCH_TOTAL_RATE_SUCCESS', data });
export const FETCH_LOCATION_RATE_SUCCESS = (data) => ({ type: 'FETCH_LOCATION_RATE_SUCCESS', data });
export const FETCH_FUNDER_RATE_SUCCESS = (data) => ({ type: 'FETCH_FUNDER_RATE_SUCCESS', data });

export const FETCH_JOURNAL_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_JOURNAL_BY_YEAR_ERROR', error });
export const FETCH_CONFERENCE_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_CONFERENCE_BY_YEAR_ERROR', error });
export const FETCH_STATUS_HISTORY_COUNT_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_STATUS_HISTORY_COUNT_BY_YEAR_ERROR', error });
export const FETCH_VISITORS_BETWEEN_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_VISITORS_BETWEEN_BY_YEAR_ERROR', error });
export const FETCH_BEST_PAPER_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_BEST_PAPER_BY_YEAR_ERROR', error });
export const FETCH_AWARDS_BY_YEAR_ERROR = (error) => ({ type: 'FETCH_AWARDS_BY_YEAR_ERROR', error });
export const FETCH_PROJECTS_RATE_ERROR = (error) => ({ type: 'FETCH_PROJECTS_RATE_ERROR', error });
export const FETCH_GRANT_RATE_ERROR = (error) => ({ type: 'FETCH_GRANT_RATE_ERROR', error });
export const FETCH_BILATERAL_RATE_ERROR = (error) => ({ type: 'FETCH_BILATERAL_RATE_ERROR', error });
export const FETCH_TOTAL_RATE_ERROR = (error) => ({ type: 'FETCH_TOTAL_RATE_ERROR', error });
export const FETCH_LOCATION_RATE_ERROR = (error) => ({ type: 'FETCH_LOCATION_RATE_ERROR', error });
export const FETCH_FUNDER_RATE_ERROR = (data) => ({ type: 'FETCH_FUNDER_RATE_ERROR', data });
