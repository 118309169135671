export const DELETE_FUNDER = 'DELETE_FUNDER';
export const DELETE_FUNDER_SUCCESS = 'DELETE_FUNDER_SUCCESS';
export const DELETE_FUNDER_ERROR = 'DELETE_FUNDER_ERROR';
export const FETCH_ALL_FUNDERS = 'FETCH_ALL_FUNDERS';
export const FETCH_ALL_FUNDERS_SUCCESS = 'FETCH_ALL_FUNDERS_SUCCESS';
export const FETCH_ALL_FUNDERS_ERROR = 'FETCH_ALL_FUNDERS_ERROR';
export const SET_FUNDER_FILTER_NAME = 'SET_FUNDER_FILTER_NAME';

export const deleteFunder = (funderId) => ({ type: DELETE_FUNDER, funderId });
export const deleteFunderSuccess = () => ({ type: DELETE_FUNDER_SUCCESS });
export const deleteFunderError = (error) => ({ type: DELETE_FUNDER_ERROR, error });

export const fetchAllFunders = () => ({ type: FETCH_ALL_FUNDERS });
export const fetchAllFundersSuccess = (data) => ({ type: FETCH_ALL_FUNDERS_SUCCESS, data });
export const fetchAllFundersError = (error) => ({ type: FETCH_ALL_FUNDERS_ERROR, error });
export const setFunderFilterName = (data) => ({ type: SET_FUNDER_FILTER_NAME, data });
