import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { filterEvents } from '../../utils';

export const selectIsLoading = get('events.isLoading');
export const selectFilters = get('events.filters');
export const selectAllEvents = get('events.data');
export const selectFilteredEvents = createSelector(
  selectAllEvents,
  selectFilters,
  (events, filters) => filterEvents(events, filters),
);
