import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row, Col, Container, Card, Form, Button, ButtonToolbar,
} from 'react-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Loader } from '../../../component/index';
import {
  deletePartner as deletePartnerAction,
  fetchAllPartners as fetchAllPartnersAction,
  setPartnerFilterName as setPartnerFilterNameAction,
} from '../../../state/partners/partnersActions';
import { filteredPartners } from '../../../utils';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  selectFilter,
  selectAllPartners,
  selectIsLoading,
} from '../../../state/partners/selectors';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';

export const Partners = (props) => {
  const {
    loading,
    partners,
    filter,
    fetchAllPartners,
    setPartnerFilterName,
    deletePartner,
  } = props;
  const history = useHistory();

  useEffect(() => { fetchAllPartners(); }, [fetchAllPartners]);
  const data = filteredPartners(partners, filter);

  const [deletePartnerInfo, setDeletePartnerInfo] = useState(null);
  const onConfirmDelete = () => {
    deletePartner(deletePartnerInfo.id);
    setDeletePartnerInfo(null);
  };

  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Country', accessor: 'country' },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (cellInfo) => (cellInfo.original.type ? cellInfo.original.type : ' - '),
    },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
              // eslint-disable-next-line
            onClick={() => history.push(`/admin/settings/partners/${row.original.id}`)}
          >
            Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
              // eslint-disable-next-line
            onClick={() => setDeletePartnerInfo(row.original)}
          >
            Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Partner name</Form.Label>
                      <Form.Control placeholder="Partner name" value={filter} onChange={(val) => setPartnerFilterName(val.target.value)} />
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Partners
              <Button
                onClick={() => {
                  history.push('/admin/settings/partners/new');
                }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem .4rem',
                  float: 'right',
                }}
              >
                New..
              </Button>
            </Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <ReactTable
                    columns={columns}
                    data={data}
                    defaultSorted={[
                      {
                        id: 'name',
                        desc: false,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                    getTrProps={() => ({
                      style: {
                        cursor: 'pointer',
                      },
                    })}
                  />
                )}
              <DeleteConfirmation
                text={deletePartnerInfo !== null ? `Delete partner ${deletePartnerInfo.name}` : ''}
                show={deletePartnerInfo !== null}
                onCancel={() => setDeletePartnerInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Partners.propTypes = {
  loading: PropTypes.bool.isRequired,
  filter: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchAllPartners: PropTypes.func.isRequired,
  setPartnerFilterName: PropTypes.func.isRequired,
  deletePartner: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading,
  partners: selectAllPartners,
  filter: selectFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllPartners: () => dispatch(fetchAllPartnersAction()),
  setPartnerFilterName: (value) => dispatch(setPartnerFilterNameAction(value)),
  deletePartner: (partnerId) => dispatch(deletePartnerAction(partnerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
