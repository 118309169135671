export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_ERROR = 'FETCH_ALL_EVENTS_ERROR';
export const REQUEST_EMAIL_SUBMIT = 'REQUEST_EMAIL_SUBMIT';
export const SEND_MAIL = 'SEND_MAIL';
export const SEND_MAIL_ERROR = 'SEND_MAIL_ERROR';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SET_FILTERS = 'SET_FILTERS';

export const fetchAllEvents = () => ({ type: FETCH_ALL_EVENTS });
export const fetchAllEventsError = (error) => ({ type: FETCH_ALL_EVENTS_ERROR, error });
export const fetchAllEventsSuccess = (data) => ({ type: FETCH_ALL_EVENTS_SUCCESS, data });

export const sendMail = (data) => ({ type: SEND_MAIL, data });
export const sendMailSuccess = () => ({ type: SEND_MAIL_SUCCESS });
export const sendMailError = (error) => ({ type: SEND_MAIL_ERROR, error });

export const setFilters = (filters) => ({ type: SET_FILTERS, filters });
