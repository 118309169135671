import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_CONFERENCE_PAPER,
  createConferencePaperError,
  createConferencePaperSuccess,
  FETCH_CONFERENCE_PAPER,
  fetchConferencePaperError,
  fetchConferencePaperSuccess,
  fetchConferencePaper as fetchConferencePaperAction,
  LOAD_CONFERENCE_PAPER_REQUIREMENTS,
  loadConferencePaperRequirementsError,
  loadConferencePaperRequirementsSuccess,
  UPDATE_CONFERENCE_PAPER,
  updateConferencePaperError,
  updateConferencePaperSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createConferenceArticle as createConferencePaperMutation,
  QueryconferenceArticleById as fetchConferencePaperQuery,
  updateConferenceArticle as updateConferencePaperMutation,
} from '../../../../graphql/queries/publications';

export function* createConferencePaper({ payload }) {
  const response = yield call(axios.post, '/', { query: createConferencePaperMutation(payload) });
  yield put(createConferencePaperSuccess());

  const { id } = response.data.data.createConferenceArticle;
  yield put(push(`/researcher/publications/updateConferencePaper/${id}`));
  showSuccessMessage('Conference paper created successfully');
}

export function* fetchConferencePaper({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchConferencePaperQuery(payload.id) });
  yield put(fetchConferencePaperSuccess(response.data.data.conferenceArticleById));
}

export function* updateConferencePaper({ payload }) {
  const response = yield call(axios.post, '/', { query: updateConferencePaperMutation(payload) });
  yield put(updateConferencePaperSuccess(response.data.data.updateConferenceArticle));
  showSuccessMessage('Conference paper updated successfully');
}

export function* loadConferencePaper({ payload }) {
  if (payload.id) {
    yield put(fetchConferencePaperAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadConferencePaperRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_CONFERENCE_PAPER,
    catchErrors({
      onErrorAction: createConferencePaperError,
      overrideErrorMessage: 'Could not create publication of type conferencePaper',
    })(createConferencePaper),
  );

  yield takeLatest(
    FETCH_CONFERENCE_PAPER,
    catchErrors({
      onErrorAction: fetchConferencePaperError,
      overrideErrorMessage: 'Could not fetch conferencePaper',
    })(fetchConferencePaper),
  );

  yield takeLatest(
    LOAD_CONFERENCE_PAPER_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadConferencePaperRequirementsError,
    })(loadConferencePaper),
  );

  yield takeLatest(
    UPDATE_CONFERENCE_PAPER,
    catchErrors({
      onErrorAction: updateConferencePaperError,
      overrideErrorMessage: 'Could not update publication of type conferencePaper',
    })(updateConferencePaper),
  );
}
