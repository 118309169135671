import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationPatent = get('publicationPatent');
export const selectPublicationPatentDetails = createSelector(
  selectPublicationPatent,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationPatent, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationPatentDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
