import {
  CREATE_PROCEEDING,
  CREATE_PROCEEDING_ERROR,
  CREATE_PROCEEDING_SUCCESS,
  FETCH_PROCEEDING,
  FETCH_PROCEEDING_ERROR,
  FETCH_PROCEEDING_SUCCESS,
  LOAD_PROCEEDING_REQUIREMENTS_SUCCESS,
  UPDATE_PROCEEDING,
  UPDATE_PROCEEDING_ERROR,
  UPDATE_PROCEEDING_SUCCESS,
} from './actions';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateProceedingRespones = (response) => ({
  types: 'proceeding',
  title: response.conference.proceedings,
  publisher: response.publisher,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: '',
  authors: [],
  date: response.appeared,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.url,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  country: response.country,
  city: response.city,
  state: response.state,
  volume: response.volume,
  edition: response.edition,
  from: response.from,
  to: response.to,
  conference: response.conference.id,
  series: response.conference.series ? response.conference.series.id : null,
  editors: response.editors.map((editor) => ({
    value: editor.id, label: `${editor.last}, ${editor.first}`,
  })),
  projects: response.projects,
});

const publicationProceeding = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PROCEEDING_SUCCESS:
    case FETCH_PROCEEDING_SUCCESS:
      return { ...state, details: dehydrateProceedingRespones(action.payload), isLoading: false };

    case CREATE_PROCEEDING_ERROR:
    case UPDATE_PROCEEDING_ERROR:
    case FETCH_PROCEEDING_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_PROCEEDING_SUCCESS:
    case LOAD_PROCEEDING_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_PROCEEDING:
    case FETCH_PROCEEDING:
    case UPDATE_PROCEEDING:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationProceeding;
