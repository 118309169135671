import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import config from '../../config/config';
import Loader from '../../component/loader/loader';
import { QueryAllPublications } from '../../graphql/queries/publications';
import { QueryProjectPublications } from '../../graphql/queries/project';
import ByYearWrapper from '../../component/publicationFormatTypes/ByYearWrapper';
import RenderPublicationsFlat from '../../component/publicationFormatTypes/renderPublicationsFlat';

const IFrameSearchContainer = (props) => {
  const {
    location,
  } = props;
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get('projectId');
  const authors = [searchParams.get('author')];
  const types = searchParams.get('type') ? searchParams.get('type').split(',') : [];
  const ids = searchParams.get('id') ? searchParams.get('id').split(',') : [];
  const years = searchParams.get('year') ? searchParams.get('year').split(',') : [];
  // const limit = search_params.get('limit');
  const statuses = ['published', 'accepted', 'submitted'];
  const limitInternational = false;
  const limitReviewed = false;
  const limitAuthor = false;
  const teamId = null;
  const publicationDateFrom = null;
  const publicationDateTo = null;
  const conferenceDateFrom = null;
  const conferenceDateTo = null;

  const [loading, setLoading] = useState(true);
  const [publications, setPublications] = useState([]);

  function invokeQuery(query, handleResponse) {
    axios.create({ // No bearer token
      baseURL: config.GRAPHQL_URL,
    }).post('/', {
      query,
    }).then((response) => {
      handleResponse(response);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    if (projectId != null) {
      invokeQuery(
        QueryProjectPublications(projectId),
        (response) => setPublications(response.data.data.publicationsByProject),
      );
    } else {
      invokeQuery(
        QueryAllPublications(
          ids,
          years,
          publicationDateFrom,
          publicationDateTo,
          conferenceDateFrom,
          conferenceDateTo,
          authors,
          types ? [...types.map((type) => `"${type}"`)] : [],
          limitInternational,
          limitReviewed,
          limitAuthor,
          statuses ? [...statuses.map((status) => `"${status}"`)] : [],
          teamId,
        ),
        (response) => setPublications(response.data.data.publicationsFilterAdmin.publicationByYear),
      );
    }
    // eslint-disable-next-line
  }, [props.match.params]);

  return (
    <Container fluid>
      <Row>
        <Col>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading
            ? <Loader />
            : ids.length > 0
              ? (<RenderPublicationsFlat pby={publications} canEdit={false} />)
              : (<ByYearWrapper pby={publications} canEdit={false} />)}
        </Col>
      </Row>
    </Container>
  );
};

IFrameSearchContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

IFrameSearchContainer.defaultProps = {};

export default IFrameSearchContainer;
