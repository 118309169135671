import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../index';
import { MUTATE_PERSON_ALUMNUS_DATA } from '../../../state/person/personActions';

const EditPeopleAlumnusForm = (props) => {
  const {
    loading,
    id,
    currentSituation,
    currentUrl,
    careerAfter,
    alumnus,
    formerPostdoc,
    formerColleague,
    hiddenWebsite,
    // eslint-disable-next-line react/prop-types
    mutatePersonAlumnusData,
    canEdit,
  } = props;

  const validationSchema = Yup.object().shape({
    currentUrl: Yup.string().url('Must be a valid URL').nullable(),
  });
  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <Formik
            enableReinitialize
            initialValues={{
              id,
              currentSituation: currentSituation || '',
              currentUrl: currentUrl || '',
              careerAfter: careerAfter || '',
              alumnus: alumnus || false,
              formerPostdoc: formerPostdoc || false,
              formerColleague: formerColleague || false,
              hiddenWebsite: hiddenWebsite || false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              mutatePersonAlumnusData({
                ...values,
              });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Current affiliation</Form.Label>
                    <Form.Control
                      type="text"
                      name="currentSituation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentSituation}
                      className="form-control"
                      disabled={!canEdit}
                    />
                    {displayError(errors.currentSituation, touched.currentSituation)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Link to personal page</Form.Label>
                    <Form.Control
                      type="text"
                      name="currentUrl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentUrl}
                      className="form-control"
                      disabled={!canEdit}
                    />
                    {displayError(errors.currentUrl, touched.currentUrl)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} sm={3}>
                    <Form.Label>Career after Cosic</Form.Label>
                    <Form.Control
                      as="select"
                      name="careerAfter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.careerAfter}
                      className="form-control"
                      disabled={!canEdit}
                    >
                      <option />
                      <option>Industry</option>
                      <option>Academia</option>
                      <option>Government</option>
                      <option>Other</option>
                    </Form.Control>
                    {displayError(errors.careerAfter, touched.careerAfter)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      checked={values.alumnus}
                      type="checkbox"
                      label="Alumnus"
                      name="alumnus"
                      value={values.alumnus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canEdit}
                    />
                    <Form.Text muted>
                      This person successfully defended a PhD thesis in COSIC
                    </Form.Text>
                    {displayError(errors.alumnus, touched.alumnus)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      checked={values.formerPostdoc}
                      type="checkbox"
                      label="Former postdoc"
                      name="formerPostdoc"
                      value={values.formerPostdoc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canEdit}
                    />
                    <Form.Text muted>
                      This person worked as a postdoctoral researcher in COSIC
                    </Form.Text>
                    {displayError(errors.formerPostdoc, touched.formerPostdoc)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      checked={values.formerColleague}
                      type="checkbox"
                      label="Former colleague"
                      name="formerColleague"
                      value={values.formerColleague}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canEdit}
                    />
                    <Form.Text muted>
                      This person was temporarily at COSIC
                    </Form.Text>
                    {displayError(errors.formerColleague, touched.formerColleague)}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Check
                      checked={values.hiddenWebsite}
                      type="checkbox"
                      label="Hide on website"
                      name="hiddenWebsite"
                      value={values.hiddenWebsite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!canEdit}
                    />
                    <Form.Text muted>
                      This person will be hidden on website alumni section
                    </Form.Text>
                    {displayError(errors.hiddenWebsite, touched.hiddenWebsite)}
                  </Form.Group>
                </Row>
                {canEdit
                && (
                <Row>
                  <Form.Group as={Col}>
                    <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%' }}>
                      Save
                    </Button>
                  </Form.Group>
                </Row>
                )}
              </Form>
            )}
          </Formik>
        )}
    </div>
  );
};

EditPeopleAlumnusForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  id: PropTypes.string,
  currentSituation: PropTypes.string,
  currentUrl: PropTypes.string,
  careerAfter: PropTypes.string,
  alumnus: PropTypes.bool,
  formerPostdoc: PropTypes.bool,
  formerColleague: PropTypes.bool,
  hiddenWebsite: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
};

EditPeopleAlumnusForm.defaultProps = {
  id: '',
  currentSituation: null,
  currentUrl: null,
  careerAfter: null,
  alumnus: null,
  formerPostdoc: null,
  formerColleague: null,
  hiddenWebsite: null,
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  id: state.person.data.id,
  mutatePersonAlumnusData: PropTypes.func.isRequired,
  currentSituation: state.person.data.alumnusData?.currentSituation,
  currentUrl: state.person.data.alumnusData?.currentUrl,
  careerAfter: state.person.data.alumnusData?.careerAfter,
  alumnus: state.person.data.alumnusData?.alumnus,
  formerPostdoc: state.person.data.alumnusData?.formerPostdoc,
  formerColleague: state.person.data.alumnusData?.formerColleague,
  hiddenWebsite: state.person.data.alumnusData?.hiddenWebsite,
});

const mapDispatchToProps = (dispatch) => ({
  mutatePersonAlumnusData: (data) => dispatch(MUTATE_PERSON_ALUMNUS_DATA(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleAlumnusForm);
