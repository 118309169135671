import React from 'react';
import {
  Button, Modal,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteConfirmation = ({
  show,
  text,
  onCancel,
  onConfirm,
}) => (
  <Modal
    show={show}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={onCancel}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{text}</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm}>Delete</Button>
    </Modal.Footer>
  </Modal>
);

DeleteConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteConfirmation;
