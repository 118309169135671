import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ButtonGroup, Card, Col, Container, Dropdown, DropdownButton, Form, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import Datetime from 'react-datetime';
import Loader from '../../../component/loader/loader';
import {
  FETCH_ALL_PUBLICATIONS,
  SET_PUBLICATION_FILTER_AUTHOR_LIMIT,
  SET_PUBLICATION_FILTER_AUTHORS,
  SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM,
  SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO,
  SET_PUBLICATION_FILTER_INTERNATIONAL,
  SET_PUBLICATION_FILTER_PUBLICATIONTYPES,
  SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM,
  SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO,
  SET_PUBLICATION_FILTER_REVIEWED,
  SET_PUBLICATION_FILTER_STATUS,
  SET_PUBLICATION_FILTER_TEAM,
  SET_PUBLICATION_FILTER_YEARS,
} from '../../../state/publication/publicationActions';
import { generateAuthors, generateYears } from '../../../utils';
import { PublicationsTabs } from '../../../component/admin';
import { selectFilter } from '../../../state/publication/selectors';
import { publicationStatusOptions, teams } from '../../../config/optionValues';
import { customFilterMatchAll } from '../../../component/publicationFormatTypes/typeHelper';

export const Publications = (props) => {
  const {
    authors,
    fetchAllPublications,
    years,
    filter,
    loading,
    publications,
    publicationTypesAdmin,
    setPublicationFilterAuthors,
    setPublicationFilterTypes,
    setPublicationFilterYears,
    setPublicationFilterPublishingDateFrom,
    setPublicationFilterPublishingDateTo,
    setPublicationFilterConferenceDateFrom,
    setPublicationFilterConferenceDateTo,
    setPublicationFilterStatus,
    setPublicationFilterTeam,
    setPublicationFilterInternational,
    setPublicationFilterAuthorLimit,
    setPublicationFilterReviewed,
  } = props;
  useEffect(() => {
    fetchAllPublications();
  }, [fetchAllPublications]);

  const history = useHistory();
  const yearsOptions = generateYears(years);
  const authorOptions = generateAuthors(authors);
  const statusOptions = [
    ...publicationStatusOptions,
  ];

  const teamOptions = [
    { value: '', label: 'None' },
    ...teams,
  ];

  const onChangeInternational = (event) => {
    setPublicationFilterInternational(event.target.checked);
  };

  const onChangeReviewed = (event) => {
    setPublicationFilterReviewed(event.target.checked);
  };

  const onChangeAuthor = (event) => {
    setPublicationFilterAuthorLimit(event.target.checked);
  };

  const handleAddPublication = (eventKey) => {
    switch (eventKey) {
      case 'abstractOfTalk':
        history.push('/researcher/publications/addAbstractOfTalk');
        break;
      case 'book':
        history.push('/researcher/publications/addBook');
        break;
      case 'bookchapter':
        history.push('/researcher/publications/addBookchapter');
        break;
      case 'conferencePaper':
        history.push('/researcher/publications/addConferencePaper');
        break;
      case 'journal':
        history.push('/researcher/publications/addJournal');
        break;
      case 'patent':
        history.push('/researcher/publications/addPatent');
        break;
      case 'proceeding':
        history.push('/researcher/publications/addProceeding');
        break;
      case 'report':
        history.push('/researcher/publications/addReport');
        break;
      case 'talk':
        history.push('/researcher/publications/addTalk');
        break;
      case 'thesis':
        history.push('/researcher/publications/addThese');
        break;

      default:
        history.push('/admin/publications');
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>
              Filters
              <DropdownButton
                onSelect={handleAddPublication}
                size="sm"
                as={ButtonGroup}
                title="Add new publication ..."
                id="bg-nested-dropdown"
                style={{
                  fontSize: '.8em',
                  padding: '.0rem .4rem',
                  float: 'right',
                }}
              >
                <Dropdown.Item eventKey="abstractOfTalk">Add Abstract of Talk</Dropdown.Item>
                <Dropdown.Item eventKey="book">Add Book</Dropdown.Item>
                <Dropdown.Item eventKey="bookchapter">Add Book Chapter</Dropdown.Item>
                <Dropdown.Item eventKey="conferencePaper">Add Conference Paper</Dropdown.Item>
                <Dropdown.Item eventKey="journal">Add Journal Article</Dropdown.Item>
                <Dropdown.Item eventKey="patent">Add Patent</Dropdown.Item>
                <Dropdown.Item eventKey="proceeding">Add Proceeding</Dropdown.Item>
                <Dropdown.Item eventKey="report">Add Report</Dropdown.Item>
                <Dropdown.Item eventKey="talk">Add Talk</Dropdown.Item>
                <Dropdown.Item eventKey="thesis">Add Thesis</Dropdown.Item>
              </DropdownButton>
            </Card.Header>

            <Card.Body>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Authors</Form.Label>
                      <Select
                        options={authorOptions}
                        value={filter.authors}
                        onChange={setPublicationFilterAuthors}
                        filterOption={customFilterMatchAll}
                        isMulti
                      />
                      <Form.Text className="text-muted">
                        Leave empty to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Type of Publication</Form.Label>
                      <Select
                        options={publicationTypesAdmin}
                        value={filter.publicationTypes}
                        onChange={setPublicationFilterTypes}
                        isMulti
                      />
                      <Form.Text className="text-muted">
                        Leave empty to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Select
                        options={statusOptions}
                        defaultValue={{ value: 'published', label: 'Published' }}
                        isClearable
                        onChange={(status) => setPublicationFilterStatus(status)}
                      />
                      <Form.Text className="text-muted">
                        Press x to select all
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Team</Form.Label>
                      <Select
                        options={teamOptions}
                        defaultValue={{ value: '', label: 'None' }}
                        onChange={(option) => setPublicationFilterTeam(option.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Row>
                      <Col>
                        <Form.Label>Publishing date</Form.Label>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>From</Form.Label>
                          <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            value={filter.publishingDateFrom}
                            onChange={setPublicationFilterPublishingDateFrom}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>To</Form.Label>
                          <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            value={filter.publishingDateTo}
                            onChange={setPublicationFilterPublishingDateTo}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>Years</Form.Label>
                          <Select
                            options={yearsOptions}
                            isMulti
                            value={filter.years}
                            onChange={setPublicationFilterYears}
                          />
                          <Form.Text className="text-muted">
                            Press x to select all
                          </Form.Text>
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Col>
                  <Col>
                    <Form.Row>
                      <Col>
                        <Form.Label>Conference start date</Form.Label>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>From</Form.Label>
                          <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            value={filter.conferenceDateFrom}
                            onChange={setPublicationFilterConferenceDateFrom}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={4}>
                        <Form.Group>
                          <Form.Label>To</Form.Label>
                          <Datetime
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            value={filter.conferenceDateTo}
                            onChange={setPublicationFilterConferenceDateTo}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Group>
                        <Form.Check
                          inline
                          label="Limit to international"
                          onChange={onChangeInternational}
                        />
                        <Form.Check
                          inline
                          label="Limit to peer-reviewed"
                          onChange={onChangeReviewed}
                        />
                        <Form.Check
                          inline
                          label="Limit to author"
                          onChange={onChangeAuthor}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {loading
            ? <Loader />
            : (
              <PublicationsTabs
                years={filter.years}
                value={filter.status}
                publications={publications}
                canEdit
              />
            )}
        </Col>
      </Row>
    </Container>
  );
};

Publications.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  publications: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  publicationTypesAdmin: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchAllPublications: PropTypes.func.isRequired,
  setPublicationFilterAuthors: PropTypes.func.isRequired,
  setPublicationFilterYears: PropTypes.func.isRequired,
  setPublicationFilterPublishingDateFrom: PropTypes.func.isRequired,
  setPublicationFilterPublishingDateTo: PropTypes.func.isRequired,
  setPublicationFilterConferenceDateFrom: PropTypes.func.isRequired,
  setPublicationFilterConferenceDateTo: PropTypes.func.isRequired,
  setPublicationFilterTypes: PropTypes.func.isRequired,
  setPublicationFilterStatus: PropTypes.func.isRequired,
  setPublicationFilterTeam: PropTypes.func.isRequired,
  setPublicationFilterInternational: PropTypes.func.isRequired,
  setPublicationFilterReviewed: PropTypes.func.isRequired,
  setPublicationFilterAuthorLimit: PropTypes.func.isRequired,
};

Publications.defaultProps = {
  publications: [],
};

const mapStateToProps = createStructuredSelector({
  loading: (state) => state.publication.isLoading,
  authors: (state) => state.people.authors,
  years: (state) => state.app.years,
  publicationTypesAdmin: (state) => state.app.publicationTypesAdmin,
  publicationByYear: (state) => state.publication.publicationByYear,
  publicationByAuthor: (state) => state.publication.publicationByAuthor,
  publicationByCategory: (state) => state.publication.publicationByCategory,
  filter: selectFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllPublications: () => dispatch(FETCH_ALL_PUBLICATIONS()),
  setPublicationFilterAuthors: (value) => dispatch(SET_PUBLICATION_FILTER_AUTHORS(value)),
  setPublicationFilterYears: (value) => dispatch(SET_PUBLICATION_FILTER_YEARS(value)),
  // eslint-disable-next-line max-len
  setPublicationFilterPublishingDateFrom: (value) => dispatch(SET_PUBLICATION_FILTER_PUBLISHING_DATE_FROM(value)),
  // eslint-disable-next-line max-len
  setPublicationFilterPublishingDateTo: (value) => dispatch(SET_PUBLICATION_FILTER_PUBLISHING_DATE_TO(value)),
  // eslint-disable-next-line max-len
  setPublicationFilterConferenceDateFrom: (value) => dispatch(SET_PUBLICATION_FILTER_CONFERENCE_DATE_FROM(value)),
  // eslint-disable-next-line max-len
  setPublicationFilterConferenceDateTo: (value) => dispatch(SET_PUBLICATION_FILTER_CONFERENCE_DATE_TO(value)),
  setPublicationFilterTypes: (value) => dispatch(SET_PUBLICATION_FILTER_PUBLICATIONTYPES(value)),
  setPublicationFilterStatus: (value) => dispatch(SET_PUBLICATION_FILTER_STATUS(value)),
  setPublicationFilterTeam: (value) => dispatch(SET_PUBLICATION_FILTER_TEAM(value)),
  setPublicationFilterReviewed: (value) => dispatch(SET_PUBLICATION_FILTER_REVIEWED(value)),
  setPublicationFilterAuthorLimit: (value) => dispatch(SET_PUBLICATION_FILTER_AUTHOR_LIMIT(value)),
  setPublicationFilterInternational: (value) => dispatch(
    SET_PUBLICATION_FILTER_INTERNATIONAL(value),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Publications);
