import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
];

const store = createStore(
  rootReducer(history),
  composeWithDevTools(
    applyMiddleware(...middleware),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
