export const FETCH_ONE_FUNDER = 'FETCH_ONE_FUNDER';
export const FETCH_ONE_FUNDER_SUCCESS = 'FETCH_ONE_FUNDER_SUCCESS';
export const FETCH_ONE_FUNDER_ERROR = 'FETCH_ONE_FUNDER_ERROR';
export const UPDATE_FUNDER = 'UPDATE_FUNDER';
export const UPDATE_FUNDER_SUCCESS = 'UPDATE_FUNDER_SUCCESS';
export const UPDATE_FUNDER_ERROR = 'UPDATE_FUNDER_ERROR';

export const fetchOneFunder = (data) => ({ type: FETCH_ONE_FUNDER, data });
export const fetchOneFunderSuccess = (data) => ({ type: FETCH_ONE_FUNDER_SUCCESS, data });
export const fetchOneFunderError = (error) => ({ type: FETCH_ONE_FUNDER_ERROR, error });

export const updateFunder = (data) => ({ type: UPDATE_FUNDER, data });
export const updateFunderSuccess = (data) => ({ type: UPDATE_FUNDER_SUCCESS, data });
export const updateFunderError = (error) => ({ type: UPDATE_FUNDER_ERROR, error });
