export const FETCH_ALL_COMMITTEE_MEMBERS = 'FETCH_ALL_COMMITTEE_MEMBERS';
export const FETCH_ALL_COMMITTEE_MEMBERS_SUCCESS = 'FETCH_ALL_COMMITTEE_MEMBERS_SUCCESS';
export const FETCH_ALL_COMMITTEE_MEMBERS_ERROR = 'FETCH_ALL_COMMITTEE_MEMBERS_ERROR';

export const fetchAllCommitteeMembers = () => ({ type: FETCH_ALL_COMMITTEE_MEMBERS });
export const fetchAllCommitteeMembersError = (error) => ({
  type: FETCH_ALL_COMMITTEE_MEMBERS_ERROR,
  error,
});
export const fetchAllCommitteeMembersSuccess = (data) => ({
  type: FETCH_ALL_COMMITTEE_MEMBERS_SUCCESS,
  data,
});
