import { call, put, takeLatest } from 'redux-saga/effects';
import catchErrors from '../../graphql/sagas';
import axios from '../../axios';
import QueryAllCommitteeMembers from '../../graphql/queries/committeeMembers';
import {
  FETCH_ALL_COMMITTEE_MEMBERS,
  fetchAllCommitteeMembersError,
  fetchAllCommitteeMembersSuccess,
} from './actions';

function* getAllCommitteeMembers() {
  const response = yield call(axios.post, '/', { query: QueryAllCommitteeMembers });
  yield put(fetchAllCommitteeMembersSuccess(response.data.data.allPhdCommitee));
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ALL_COMMITTEE_MEMBERS,
    catchErrors({
      onErrorAction: fetchAllCommitteeMembersError,
      overrideErrorMessage: 'Unable to retrieve all committee members',
    })(getAllCommitteeMembers),
  );
}
