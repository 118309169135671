import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationThese = get('publicationThese');
export const selectPublicationTheseDetails = createSelector(
  selectPublicationThese,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationThese, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationTheseDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
