import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardColumns,
  Container,
} from 'react-bootstrap';

const PublicationSelector = () => {
  const history = useHistory();

  const goTo = (location) => history.push(`/researcher/publications/${location}`);

  return (
    <Container fluid>
      <CardColumns>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addJournal'); }}>
            <Card.Title>New Journal Article</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addConferencePaper'); }}>
            <Card.Title>New Conference Paper</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addAbstractOfTalk'); }}>
            <Card.Title>New Abstract of Talk</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addTalk'); }}>
            <Card.Title>New Talk</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addProceeding'); }}>
            <Card.Title>New Proceeding</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addThese'); }}>
            <Card.Title>New Thesis</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addBook'); }}>
            <Card.Title>New Book</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addBookchapter'); }}>
            <Card.Title>New Book Chapter</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addPatent'); }}>
            <Card.Title>New Patent</Card.Title>
          </Card.Body>
        </Card>
        <Card className="publication-select">
          <Card.Body onClick={() => { goTo('addReport'); }}>
            <Card.Title>New Report</Card.Title>
          </Card.Body>
        </Card>
      </CardColumns>
    </Container>
  );
};

export default PublicationSelector;
