// @flow

import * as React from 'react';
import { Container, Spinner } from 'react-bootstrap';

type Props = {
  centered: boolean,
}

function Loader({ centered = false }: Props) {
  const spin = (
    <Spinner
      animation="border"
      role="status"
      variant="primary"
      data-testid="loader"
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
  return (centered ? (
    <Container data-testid="loader-isCentered" className="loader">
      {spin}
    </Container>
  ) : spin
  );
}

export default Loader;
