// @flow

import type { ProfileState, ProfileAction } from './types';

const defaultState: ProfileState = {
  isImageLoading: false,
  error: '',
  key: 1,
};

const profile = (state: ProfileState = defaultState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case 'UPLOAD_PROFILE_IMAGE':
      return { ...state, isImageLoading: true };
    case 'UPLOAD_PROFILE_IMAGE_SUCCESS':
      return { ...state, key: state.key + 1, isImageLoading: false };
    case 'UPLOAD_PROFILE_IMAGE_ERROR':
      return { ...state, error: action.error, isImageLoading: false };
    default:
      return state;
  }
};

export default profile;
