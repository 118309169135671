import { createAction } from 'redux-actions';

export const CREATE_TALK = 'CREATE_TALK';
export const CREATE_TALK_ERROR = 'CREATE_TALK_ERROR';
export const CREATE_TALK_SUCCESS = 'CREATE_TALK_SUCCESS';
export const FETCH_TALK = 'FETCH_TALK';
export const FETCH_TALK_ERROR = 'FETCH_TALK_ERROR';
export const FETCH_TALK_SUCCESS = 'FETCH_TALK_SUCCESS';
export const LOAD_TALK_REQUIREMENTS = 'LOAD_REQUIREMENTS_TALK';
export const LOAD_TALK_REQUIREMENTS_ERROR = 'LOAD_TALK_REQUIREMENTS_ERROR';
export const LOAD_TALK_REQUIREMENTS_SUCCESS = 'LOAD_TALK_REQUIREMENTS_SUCCESS';
export const UPDATE_TALK = 'UPDATE_TALK';
export const UPDATE_TALK_ERROR = 'UPDATE_TALK_ERROR';
export const UPDATE_TALK_SUCCESS = 'UPDATE_TALK_SUCCESS';

export const createTalk = createAction(CREATE_TALK);
export const createTalkError = createAction(CREATE_TALK_ERROR);
export const createTalkSuccess = createAction(CREATE_TALK_SUCCESS);

export const fetchTalk = createAction(FETCH_TALK);
export const fetchTalkError = createAction(FETCH_TALK_ERROR);
export const fetchTalkSuccess = createAction(FETCH_TALK_SUCCESS);

export const loadTalkRequirements = createAction(LOAD_TALK_REQUIREMENTS);
export const loadTalkRequirementsError = createAction(LOAD_TALK_REQUIREMENTS_ERROR);
export const loadTalkRequirementsSuccess = createAction(LOAD_TALK_REQUIREMENTS_SUCCESS);

export const updateTalk = createAction(UPDATE_TALK);
export const updateTalkError = createAction(UPDATE_TALK_ERROR);
export const updateTalkSuccess = createAction(UPDATE_TALK_SUCCESS);
