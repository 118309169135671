import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationAbstractOfTalk = get('publicationAbstractOfTalk');
export const selectPublicationAbstractOfTalkDetails = createSelector(
  selectPublicationAbstractOfTalk,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationAbstractOfTalk, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationAbstractOfTalkDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
