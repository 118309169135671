import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  ButtonToolbar,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import Select from 'react-select';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { createStructuredSelector } from 'reselect';
import { Loader } from '../../../component';
import DeleteConfirmation from '../../../component/admin/settings/deleteConfirmation';
import {
  CLEAR_PROJECT_FILTERS,
  FETCH_ALL_PROJECTS,
  FETCH_PROJECT_TYPES,
  SET_PROJECT_FILTERS,
  removeProject as removeProjectAction,
} from '../../../state/project/projectActions';
import {
  selectAllProjectTypesAsOptions,
  selectAllProjects,
  selectIsLoading,
  selectProjectFilters,
} from '../../../state/project/selectors';
import { projectDateStatuses, projectStatuses, teams } from '../../../config/optionValues';
import {
  defaultTablePageSize,
  defaultTablePageSizeOptions,
} from '../../../config/constants';
import { datetimeWorkaround } from '../publications/GeneralDataForm';

export const Projects = (props) => {
  const {
    fetchAllProjects,
    projects,
    loading,
    filters,
    fetchTypes,
    clearFilters,
    setFilters,
    types,
    removeProject,
  } = props;

  const history = useHistory();
  const [statusState, setStatus] = useState(null);
  const [beginDateState, setBeginDate] = useState(null);
  const [endDateState, setEndDate] = useState(null);
  const [nameOrAcronymState, setNameOrAcronym] = useState(null);
  const [dateStatusState, setDateStatus] = useState(null);
  const [typeState, setType] = useState(null);
  const [teamState, setTeam] = useState(null);
  const [deleteProjectInfo, setDeleteProjectInfo] = useState(null);

  const onConfirmDelete = () => {
    removeProject(deleteProjectInfo.id);
    setDeleteProjectInfo(null);
  };

  const columns = [
    {
      Header: 'Project',
      columns: [
        {
          Header: 'Status',
          accessor: 'status',
          sortType: 'basic',
          Cell: (cellInfo) => `${cellInfo.original.status ? (
            projectStatuses.find((s) => (s.value === cellInfo.original.status)).label
          ) : ''}`,
        },
        {
          Header: 'Acronym',
          accessor: 'acronym',
          sortType: 'basic',
        },
        {
          Header: 'Name',
          accessor: 'name',
          sortType: 'basic',
        },
        {
          Header: 'Funder',
          accessor: 'funder.name',
          sortType: 'basic',
        },
      ],
    },
    {
      Header: 'Date',
      columns: [
        {
          Header: 'Start Date',
          accessor: 'beginDate',
          Cell: (cellInfo) => `${cellInfo.original.beginDate ? (
            new Date(Date.parse(cellInfo.original.beginDate)).toDateString()
          ) : ''}`,
        },
        {
          Header: 'End Date',
          accessor: 'endDate',
          Cell: (cellInfo) => `${cellInfo.original.endDate ? (
            new Date(Date.parse(cellInfo.original.endDate)).toDateString()
          ) : ''}`,
        },
        {
          Header: 'Status',
          sortType: 'basic',
          accessor: 'dateStatus',
          Cell: (cellInfo) => `${cellInfo.original.dateStatus ? (
            projectDateStatuses.find((s) => (s.value === cellInfo.original.dateStatus)).label
          ) : ''}`,
        },
      ],
    },
    {
      id: 'button',
      Cell: (row) => (
        <ButtonToolbar className="justify-content-center">
          <Button
            className="mr-3 px-5"
            variant="primary"
            size="sm"
              // eslint-disable-next-line
            onClick={() => history.push(`/admin/projects/${row.original.id}`)}
          >
            Edit
          </Button>
          <Button
            className="px-5"
            variant="danger"
            size="sm"
              // eslint-disable-next-line
            onClick={() => setDeleteProjectInfo(row.original)}
          >
            Delete
          </Button>
        </ButtonToolbar>
      ),
    },
  ];

  useEffect(() => {
    fetchTypes();
    fetchAllProjects();
  }, [fetchAllProjects, fetchTypes]);

  const typesOptions = [{ label: 'All', value: null }, ...types];
  const teamOptions = [{ label: 'All', value: null }, ...teams];
  const projectStatusOptions = [{ label: 'All', value: null }, ...projectStatuses];
  const projectDateStatusOptions = [{ label: 'All', value: null }, ...projectDateStatuses];

  const handleNameOrAcronymChange = (nameOrAcronym) => {
    const newFilters = { ...filters, nameOrAcronym };
    setNameOrAcronym(nameOrAcronym);
    setFilters(newFilters);
  };

  const handleStatusChange = (status) => {
    const newFilters = { ...filters, status: status.value };
    const newStatus = projectStatusOptions.find((stat) => (
      stat.value === status.value
    ));
    setStatus(newStatus);
    setFilters(newFilters);
  };
  const handleDateStatusChange = (dateStatus) => {
    const newFilters = { ...filters, dateStatus: dateStatus.value };
    const newDateStatus = projectDateStatusOptions.find((stat) => (
      stat.value === dateStatus.value
    ));
    setDateStatus(newDateStatus);
    setFilters(newFilters);
  };

  const handleTypeChange = (type) => {
    const newFilters = { ...filters, type: type.value };
    const newType = typesOptions.find((t) => (
      t.value === type.value
    ));
    setType(newType);
    setFilters(newFilters);
  };

  const handleTeamChange = (team) => {
    const newFilters = { ...filters, team: team.value };
    const newTeam = teamOptions.find((t) => (
      t.value === team.value
    ));
    setTeam(newTeam);
    setFilters(newFilters);
  };

  const handlebeginDateChange = (moment) => {
    let beginDate = '';
    if (typeof (moment) !== 'string') {
      beginDate = moment.format('YYYY-MM-DD');
    } else {
      beginDate = moment;
    }
    const newFilters = { ...filters, beginDate };
    setBeginDate(beginDate);
    setFilters(newFilters);
  };

  const handleEndDateChange = (moment) => {
    let endDate = '';
    if (typeof (moment) !== 'string') {
      endDate = moment.format('YYYY-MM-DD');
    } else {
      endDate = moment;
    }
    const newFilters = { ...filters, endDate };
    setEndDate(endDate);
    setFilters(newFilters);
  };

  const handleClear = () => {
    setNameOrAcronym('');
    setBeginDate('');
    setEndDate('');
    setStatus(null);
    setType(null);
    setTeam(null);
    setDateStatus(null);
    clearFilters();
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>Filters</Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Col sm={2}>
                    <Form.Group>
                      <Form.Label>Project name or acronym</Form.Label>
                      <Form.Control
                        onChange={(val) => handleNameOrAcronymChange(val.target.value)}
                        value={nameOrAcronymState}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <Select
                        options={projectStatusOptions}
                        onChange={(val) => handleStatusChange(val)}
                        value={statusState}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <Select
                        options={typesOptions}
                        onChange={(val) => handleTypeChange(val)}
                        value={typeState}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Team</Form.Label>
                      <Select
                        options={teamOptions}
                        onChange={(val) => handleTeamChange(val)}
                        value={teamState}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date (Not Before)</Form.Label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={beginDateState}
                        onChange={handlebeginDateChange}
                        inputProps={{
                          ...datetimeWorkaround(beginDateState),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End Date (Not After)</Form.Label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={endDateState}
                        onChange={handleEndDateChange}
                        inputProps={{
                          ...datetimeWorkaround(endDateState),
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Date status</Form.Label>
                      <Select
                        options={projectDateStatusOptions}
                        onChange={(val) => handleDateStatusChange(val)}
                        value={dateStatusState}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => handleClear()}
                        style={{ marginTop: '32px' }}
                      >
                        Clear
                      </Button>
                    </Form.Group>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Projects (warning: do not reuse/copy content from other people's proposals)
              <Button
                onClick={() => { history.push('/admin/projects/new'); }}
                style={{
                  fontSize: '.8rem',
                  marginLeft: '1rem',
                  padding: '.2rem',
                }}
              >
                New...
              </Button>
            </Card.Header>
            <Card.Body>
              {!loading && projects
                ? (
                  <ReactTable
                    columns={columns}
                    data={projects}
                    defaultSorted={[
                      {
                        id: 'status',
                        desc: true,
                      },
                      {
                        id: 'acronym',
                        desc: true,
                      },
                    ]}
                    pageSizeOptions={defaultTablePageSizeOptions}
                    defaultPageSize={defaultTablePageSize}
                    minRows={0}
                    className="-striped -highlight"
                  />
                )
                : (
                  <Loader />
                )}
              <DeleteConfirmation
                text={deleteProjectInfo !== null ? `Delete project ${deleteProjectInfo.name}` : ''}
                show={deleteProjectInfo !== null}
                onCancel={() => setDeleteProjectInfo(null)}
                onConfirm={() => onConfirmDelete()}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Projects.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.arrayOf(PropTypes.any),
  fetchAllProjects: PropTypes.func.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  removeProject: PropTypes.func.isRequired,
};

Projects.defaultProps = {
  projects: [],
  types: [],
};

const mapStateToProps = createStructuredSelector({
  loading: selectIsLoading,
  projects: selectAllProjects,
  filters: selectProjectFilters,
  types: selectAllProjectTypesAsOptions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllProjects: () => dispatch(FETCH_ALL_PROJECTS()),
  fetchTypes: (data) => dispatch(FETCH_PROJECT_TYPES(data)),
  clearFilters: (data) => dispatch(CLEAR_PROJECT_FILTERS(data)),
  setFilters: (data) => dispatch(SET_PROJECT_FILTERS(data)),
  removeProject: (projectId) => dispatch(removeProjectAction(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
