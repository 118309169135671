import { createAction } from 'redux-actions';

export const CREATE_CONFERENCE_PAPER = 'CREATE_CONFERENCE_PAPER';
export const CREATE_CONFERENCE_PAPER_ERROR = 'CREATE_CONFERENCE_PAPER_ERROR';
export const CREATE_CONFERENCE_PAPER_SUCCESS = 'CREATE_CONFERENCE_PAPER_SUCCESS';
export const FETCH_CONFERENCE_PAPER = 'FETCH_CONFERENCE_PAPER';
export const FETCH_CONFERENCE_PAPER_ERROR = 'FETCH_CONFERENCE_PAPER_ERROR';
export const FETCH_CONFERENCE_PAPER_SUCCESS = 'FETCH_CONFERENCE_PAPER_SUCCESS';
export const LOAD_CONFERENCE_PAPER_REQUIREMENTS = 'LOAD_REQUIREMENTS_CONFERENCE_PAPER';
export const LOAD_CONFERENCE_PAPER_REQUIREMENTS_ERROR = 'LOAD_CONFERENCE_PAPER_REQUIREMENTS_ERROR';
export const LOAD_CONFERENCE_PAPER_REQUIREMENTS_SUCCESS = 'LOAD_CONFERENCE_PAPER_REQUIREMENTS_SUCCESS';
export const UPDATE_CONFERENCE_PAPER = 'UPDATE_CONFERENCE_PAPER';
export const UPDATE_CONFERENCE_PAPER_ERROR = 'UPDATE_CONFERENCE_PAPER_ERROR';
export const UPDATE_CONFERENCE_PAPER_SUCCESS = 'UPDATE_CONFERENCE_PAPER_SUCCESS';

export const createConferencePaper = createAction(CREATE_CONFERENCE_PAPER);
export const createConferencePaperError = createAction(CREATE_CONFERENCE_PAPER_ERROR);
export const createConferencePaperSuccess = createAction(CREATE_CONFERENCE_PAPER_SUCCESS);

export const fetchConferencePaper = createAction(FETCH_CONFERENCE_PAPER);
export const fetchConferencePaperError = createAction(FETCH_CONFERENCE_PAPER_ERROR);
export const fetchConferencePaperSuccess = createAction(FETCH_CONFERENCE_PAPER_SUCCESS);

export const loadConferencePaperRequirements = createAction(LOAD_CONFERENCE_PAPER_REQUIREMENTS);
export const loadConferencePaperRequirementsError = createAction(
  LOAD_CONFERENCE_PAPER_REQUIREMENTS_ERROR,
);
export const loadConferencePaperRequirementsSuccess = createAction(
  LOAD_CONFERENCE_PAPER_REQUIREMENTS_SUCCESS,
);

export const updateConferencePaper = createAction(UPDATE_CONFERENCE_PAPER);
export const updateConferencePaperError = createAction(UPDATE_CONFERENCE_PAPER_ERROR);
export const updateConferencePaperSuccess = createAction(UPDATE_CONFERENCE_PAPER_SUCCESS);
