import { all } from 'redux-saga/effects';

import peopleWatcher, {
  fetchPeoplesSagaWatcher,
  fetchFilteredPeoplesSagaWatcher,
  fetchTeamsSagaWatcher,
  fetchAllPeopleTypesSagaWatcher,
  fetchAllPromotersSagaWatcher,
} from './people/peopleSaga';
import personWatcher, {
  mutateCreateExternalPeopleSagaWatcher,
  fetchPersonSagaWatcher,
  fetchPersonProjectsSagaWatcher,
  setPersonRoleSagaWatcher,
  createPersonSagaWatcher,
  mutatePersonSagaWatcher,
  mutatePersonPhdSagaWatcher,
  mutatePersonAlumnusSagaWatcher,
  fetchPersonInternshipSagaWatcher,
  mutatePersonAddInternshipSagaWatcher,
  mutatePersonUpdateInternshipSagaWatcher,
  mutatePersonRemoveInternshipSagaWatcher,
  fetchTeamListSagaWatcher,
  fetchZAPListSagaWatcher,
  mutatePersonUpdateResearchdataSagaWatcher,
  mutatePersonProfileSagaWatcher,
  mutatePersonAddPgmCommSagaWatcher,
  mutatePersonRemovePgmCommSagaWatcher,
  mutatePersonUpdatePgmCommSagaWatcher,
  fetchProgramVenuesSagaWatcher,
  uploadResearchImageSagaWatcher,
} from './person/personSaga';

import projectWatcher, {
  clearProjectFiltersSagaWatcher,
  fetchProjectsSagaWatcher,
  fetchProjectSagaWatcher,
  fetchAllProjectsWithFilterSagaWatcher,
  fetchProjectTypesSagaWatcher,
  fetchProjectCallsSagaWatcher,
  fetchFilesSagaWatcher,
  updateProjectSagaWatcher,
  addWorkPacketToProjectSagaWatcher,
  deleteWorkPacketToProjectSagaWatcher,
  fetchProjectWorkPacketsSagaWatcher,
  updateWorkPacketProjectSagaWatcher,
  createProjectSagaWatcher,
  addFileSagaWatcher,
  deleteFileSagaWatcher,
  downloadFileSagaWatcher,
  addProjectPartnerSagaWatcher,
  deleteProjectPartnerSagaWatcher,
  addProjectMemberSagaWatcher,
  deleteProjectMemberSagaWatcher,
} from './project/projectSaga';
import fetchPeopleTypesSagaWatcher from './peopleType/peopleTypeSaga';
import eventsWatcher from './events/eventsSaga';
import eventWatcher from './event/eventSaga';
import countriesSagaWatcher from './countries/countriesSaga';
import countryWatcher from './country/countrySaga';
import partnersWatcher from './partners/partnersSaga';
import partnerWatcher from './partner/partnerSaga';
import fundersWatcher from './funders/fundersSaga';
import funderWatcher from './funder/funderSaga';
import typesWatcher from './types/typesSaga';
import typeWatcher from './type/typeSaga';
import projectCallsWatcher from './projectCalls/projectCallsSaga';
import projectCallWatcher from './projectCall/projectCallSaga';
import venuesWatcher from './venues/venuesSaga';
import committeeMembersWatcher from './committeeMembers/sagas';
import venueWatcher from './venue/venueSaga';
import publicationWatcher, {
  fetchAllPublicationsWithFilterSagaWatcher,
  fetchPublicationPeoplesSagaWatcher,
  fetchPublicationVolumesSagaWatcher,
  fetchPublicationTeamsSagaWatcher,
  createPublicationSagaWatcher,
  updatePublicationSagaWatcher,
  fetchOnePublicationSagaWatcher,
  fetchPublicationProceedingsSagaWatcher,
  fetchPublicationFilesSagaWatcher,
  uploadPublicationFilesSagaWatcher,
  deletePublicationFilesSagaWatcher,
  downloadPublicationFilesSagaWatcher,
  deletePublicationSagaWatcher,
} from './publication/publicationSaga';
import {
  fetchDatesSagaWatcher,
  fetchUserIdAndTokenSagaWatcher,
} from './app/appSaga';
import {
  fetchConferenceSagaWatcher,
  fetchJournalsSagaWatcher,
  fetchStatusHistoryCountByYearSagaWatcher,
  fetchBestPaperByYearSagaWatcher,
  fetchAwardsByYearSagaWatcher,
  fetchProjectSuccessRateSagaWatcher,
  fetchGrantSuccessRateSagaWatcher,
  fetchBilateralSuccessRateSagaWatcher,
  fetchTotalSuccessRateSagaWatcher,
  fetchProjectLocationRateSagaWatcher,
  fetchProjectFunderRateSagaWatcher,
} from './dashboard/dashboardSaga';

import {
  uploadProfileImageSagaWatcher,
} from './profile/profileSaga';

import abstractOfTalkSagas from '../container/admin/publications/AbstractOfTalk/sagas';
import bookSagas from '../container/admin/publications/Book/sagas';
import bookchapterSagas from '../container/admin/publications/Bookchapter/sagas';
import conferencePaperSagas from '../container/admin/publications/ConferencePaper/sagas';
import journalSagas from '../container/admin/publications/Journal/sagas';
import patentSagas from '../container/admin/publications/Patent/sagas';
import proceedingSagas from '../container/admin/publications/Proceeding/sagas';
import reportSagas from '../container/admin/publications/Report/sagas';
import talkSagas from '../container/admin/publications/Talk/sagas';
import theseSagas from '../container/admin/publications/These/sagas';

export default function* rootSaga() {
  yield all([
    abstractOfTalkSagas(),
    bookSagas(),
    bookchapterSagas(),
    conferencePaperSagas(),
    journalSagas(),
    patentSagas(),
    proceedingSagas(),
    reportSagas(),
    talkSagas(),
    theseSagas(),

    createPublicationSagaWatcher(),
    updatePublicationSagaWatcher(),
    fetchPeoplesSagaWatcher(),
    fetchOnePublicationSagaWatcher(),
    fetchPublicationPeoplesSagaWatcher(),
    fetchPublicationVolumesSagaWatcher(),
    fetchPublicationTeamsSagaWatcher(),
    fetchPublicationProceedingsSagaWatcher(),
    fetchUserIdAndTokenSagaWatcher(),
    fetchPeoplesSagaWatcher(),
    setPersonRoleSagaWatcher(),
    createPersonSagaWatcher(),
    mutatePersonSagaWatcher(),
    mutatePersonPhdSagaWatcher(),
    mutatePersonAlumnusSagaWatcher(),
    fetchFilteredPeoplesSagaWatcher(),
    fetchPersonSagaWatcher(),

    mutateCreateExternalPeopleSagaWatcher(),
    fetchPersonInternshipSagaWatcher(),
    mutatePersonAddInternshipSagaWatcher(),
    mutatePersonUpdateInternshipSagaWatcher(),
    mutatePersonRemoveInternshipSagaWatcher(),
    fetchTeamListSagaWatcher(),
    fetchZAPListSagaWatcher(),
    mutatePersonUpdateResearchdataSagaWatcher(),

    eventWatcher(),
    eventsWatcher(),
    fetchPersonProjectsSagaWatcher(),
    fetchProjectsSagaWatcher(),
    fetchProjectSagaWatcher(),
    countriesSagaWatcher(),
    countryWatcher(),
    partnersWatcher(),
    partnerWatcher(),
    fundersWatcher(),
    funderWatcher(),
    projectCallsWatcher(),
    projectCallWatcher(),
    typesWatcher(),
    typeWatcher(),
    venuesWatcher(),
    venueWatcher(),
    personWatcher(),
    peopleWatcher(),
    projectWatcher(),
    committeeMembersWatcher(),
    publicationWatcher(),
    fetchDatesSagaWatcher(),
    fetchAllPublicationsWithFilterSagaWatcher(),
    fetchConferenceSagaWatcher(),
    fetchJournalsSagaWatcher(),
    fetchAllProjectsWithFilterSagaWatcher(),
    fetchProjectTypesSagaWatcher(),
    clearProjectFiltersSagaWatcher(),
    fetchProjectCallsSagaWatcher(),
    updateProjectSagaWatcher(),
    addWorkPacketToProjectSagaWatcher(),
    deleteWorkPacketToProjectSagaWatcher(),
    fetchProjectWorkPacketsSagaWatcher(),
    updateWorkPacketProjectSagaWatcher(),
    createProjectSagaWatcher(),
    fetchPeopleTypesSagaWatcher(),
    fetchStatusHistoryCountByYearSagaWatcher(),
    fetchBestPaperByYearSagaWatcher(),
    fetchAwardsByYearSagaWatcher(),
    fetchProjectSuccessRateSagaWatcher(),
    fetchGrantSuccessRateSagaWatcher(),
    fetchBilateralSuccessRateSagaWatcher(),
    fetchFilesSagaWatcher(),
    addFileSagaWatcher(),
    deleteFileSagaWatcher(),
    downloadFileSagaWatcher(),
    addProjectPartnerSagaWatcher(),
    deleteProjectPartnerSagaWatcher(),
    addProjectMemberSagaWatcher(),
    deleteProjectMemberSagaWatcher(),
    fetchAllPeopleTypesSagaWatcher(),
    fetchAllPromotersSagaWatcher(),
    fetchTeamsSagaWatcher(),
    fetchTotalSuccessRateSagaWatcher(),
    fetchProjectLocationRateSagaWatcher(),
    fetchProjectFunderRateSagaWatcher(),
    uploadResearchImageSagaWatcher(),
    uploadProfileImageSagaWatcher(),
    mutatePersonProfileSagaWatcher(),
    mutatePersonAddPgmCommSagaWatcher(),
    mutatePersonRemovePgmCommSagaWatcher(),
    mutatePersonUpdatePgmCommSagaWatcher(),
    fetchProgramVenuesSagaWatcher(),
    fetchPublicationFilesSagaWatcher(),
    uploadPublicationFilesSagaWatcher(),
    deletePublicationFilesSagaWatcher(),
    downloadPublicationFilesSagaWatcher(),
    deletePublicationSagaWatcher(),
  ]);
}
