const defaultState = {
  userId: '',
  user: {},
  permissions: [],
  token: '',
  isAuthenticated: false,
  isLoggingIn: false,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_USER_ID_SUCCESS':
      return {
        ...state,
        userId: action.data,
        isAuthenticated: false,
      };
    case 'SET_USER_DETAILS':
      return {
        ...state,
        user: action.data,
        isAuthenticated: true,
        isLoggingIn: false,
      };
    case 'SET_USER_TOKEN':
      return { ...state, token: action.data, isAuthenticated: false };
    case 'USER_NOT_AUTHENTICATED':
      return { ...state, token: action.data, isAuthenticated: false };
    case 'FETCH_USER_ID':
      return { ...state, isLoggingIn: true };
    case 'LOGOUT':
      localStorage.clear();
      return { ...state, token: action.data, isAuthenticated: false };
    default:
      return state;
  }
};

export default user;
