import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationProceeding = get('publicationProceeding');
export const selectPublicationProceedingDetails = createSelector(
  selectPublicationProceeding,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationProceeding, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationProceedingDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
