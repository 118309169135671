import * as yup from 'yup';

function getDateValidation() {
  return yup.string()
    .matches(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}/g,
      {
        message: 'Invalid date must be YYYY-MM-DD',
        excludeEmptyString: true,
      },
    );
}

export const mainValidationSchema = {
  title: yup.string()
    // . notOneOf(
    // props[`${submissionType}Titles`] || [],
    // 'Title already exists',
    // ) // eslint-disable-line react/destructuring-assignment
    .required('A submission title is required.'),
  status: yup.string().required('A  Status must be picked.').nullable(),
  teams: yup.string().required('A  Team must be picked.').nullable(),
  primaryAuthor: yup.string().required('A primary author is required.').nullable(),
  authors: yup.array(),
  types: yup.string().required('The type is mandatory').nullable(),
  date: getDateValidation().required('A date is required.'),
  doi: yup.string().nullable(),
  webVersion: yup.string().url('Must be a valid URL').nullable(),
  webVersionSourceCode: yup.string().url('Must be a valid URL').nullable(),
  webVersionRecording: yup.string().url('Must be a valid URL').nullable(),
  visibility: yup.boolean(),
  dataset: yup.boolean().required('A value is required.').nullable(),
  datasetRDRStored: yup.boolean().required('A value is required.').nullable(),
};

function getPagesValidation() {
  return yup
    .string()
    .matches(
      /(^[0-9]+-[0-9]+$|^[0-9]+$)/,
      {
        message: 'Value must start with numbers delimited with hyphens (format: N or N-N)',
        excludeEmptyString: true,
      },
    )
    .required('Pages is required');
}

export const pagesValidation = {
  pages: getPagesValidation(),
  pageCount: yup.number().nullable(),
};

export const talkValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  presenters: yup.array().required('Pick a presenter'),
  city: yup.string().required('Please fill in a city'),
  country: yup.string().required('Please, pick a country').nullable(),
  typeEvent: yup.string().required('Pick type of event'),
});

export const bookValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  publisher: yup.string().required('Publisher is required').nullable(),
  volume: yup.number().positive(),
  isbn: yup.string().nullable(),
  editors: yup.array(),
  country: yup.string().required('Country is required').nullable(),
});

export const bookchapterValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  book: yup.string().required('Pick a book').nullable(),
});

export const patentValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  applicant: yup.string(),
  number: yup.string(),
  description: yup.string().required('Description is required'),
});

export const reportValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  note: yup.string().required('Note is required'),
});

export const theseValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  country: yup.string().required('Country is required').nullable(),
  institution: yup.string().required('Institution is required'),
  thesisType: yup.string().required('These type is required').nullable(),
  promotors: yup.string().required('Pick at least 1 promotor'),
});

export const proceedingValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  title: yup.string(),
  primaryAuthor: yup.string().nullable(),
  country: yup.string().required('Country is required').nullable(),
  edition: yup.number().positive().nullable(),
  volume: yup.string().nullable(),
  city: yup.string(),
  state: yup.string().nullable(),
  conference: yup.string().required('Conference is required').nullable(),
  from: yup.date().required('A from date is required'),
  to: yup.date().required('A to date is required').nullable().min(yup.ref('from'), "to date can't be before from date"),
});

export const abstractOfTalkValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  proceedingsId: yup.number().required('Proceedings is required').nullable(),
});

export const journalValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  journal: yup.string().required('Journal is required').nullable(),
  reviewed: yup.boolean().required('Value is required').nullable(),
  date: yup
    .string()
    .when('earlyAccess', {
      is: true,
      then: getDateValidation().notRequired().nullable(),
      otherwise: getDateValidation().required('A date is required.').nullable(),
    }),
  pages: yup
    .string()
    .when('earlyAccess', {
      is: true,
      then: getPagesValidation().notRequired().nullable(),
      otherwise: getPagesValidation(),
    }),
});

export const conferencePaperValidationSchema = yup.object().shape({
  ...mainValidationSchema,
  ...pagesValidation,
  reviewed: yup.boolean().required('Value is required').nullable(),
  proceedingsId: yup.number().required('Proceedings is required').nullable(),
  date: yup
    .string()
    .when('earlyAccess', {
      is: true,
      then: getDateValidation().notRequired().nullable(),
      otherwise: getDateValidation().required('A date is required.').nullable(),
    }),
  pages: yup
    .string()
    .when('earlyAccess', {
      is: true,
      then: getPagesValidation().notRequired().nullable(),
      otherwise: getPagesValidation(),
    }),
});
