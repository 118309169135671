import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectFilter = get('countries.filter');
export const selectAllCountries = get('countries.data');
export const selectAllCountriesAsOptions = createSelector(
  selectAllCountries,
  (countries) => countries.map((country) => ({ label: country.name, value: country.id })),
);
export const selectIsLoading = get('countries.isLoading');
