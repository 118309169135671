import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Spinner, Row,
} from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import { useParams, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Profile from '../assets/profile.png';
import { FETCH_USER_ID } from '../state/app/appActions';
import { userWithPermission } from '../utils';

export const onLogin = (token, login) => {
  localStorage.setItem('token', token);
  login();
};

function Token(props) {
  const { token } = useParams();

  const { login, isLoggingIn } = props;
  onLogin(token, login);

  const history = useHistory();

  const { permissions } = jwtDecode(token);
  if (userWithPermission(permissions, 'PUBLICATIONS_DATA_READ')) {
    history.push('/admin/publications');
  } else {
    history.push('/');
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="login-form">
            <div className="form">
              <div className="avatar"><img alt="profile" src={Profile} /></div>
              {isLoggingIn ? (
                <Spinner animation="border" variant="light" />
              ) : 'Logged in'}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Token.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoggingIn: state.user.isLoggingIn,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch((FETCH_USER_ID())),
});

export default connect(mapStateToProps, mapDispatchToProps)(Token);
