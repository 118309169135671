import React, { useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faDownload,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { fileUrlMakerBackend, fileUrlMakerFrontend } from '../../../component/publicationFormatTypes/typeHelper';
import AuthenticatedLink from '../../../component/AuthenticatedLink';
import { Loader } from '../../../component';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '150px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const activeStyle = {
  borderColor: '#2196f3',
};
const acceptStyle = {
  borderColor: '#00e676',
};
const rejectStyle = {
  borderColor: '#ff1744',
};
function RepositoryContainerPublication({
  files,
  numberOfFilesUploading,
  addFile,
  deleteFile,
  downloadFile,
  id,
  publicationType,
  category,
}) {
  const onDrop = (acceptedFiles, attribute) => {
    acceptedFiles.forEach((acceptedFile) => {
      const data = new FormData();
      data.append('file', acceptedFile);
      data.append('id', id);
      data.append('attribute', attribute);
      data.append('type', publicationType);
      data.append('category', category);
      addFile(data, 'source', attribute);
    });
  };

  const deleteFileFunc = (fileName, attribute) => {
    const data = {
      fileName, attribute, id, publicationType, category,
    };
    deleteFile(data);
  };

  const downloadFileFunc = (fileName, attribute) => {
    const data = {
      fileName, attribute, id, publicationType, category,
    };
    downloadFile(data);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*' });
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragAccept,
    isDragActive,
    isDragReject,
  ]);
  return (
    <div className="text-center mt-5">
      <Row>
        <Col>
          <Card>
            <Card.Title>Public files</Card.Title>
            <Card.Body>
              {
                files.length === 0 || files.filter((fileObj) => fileObj.visibility === 'public').length === 0
                  ? <p>Add (public) files related to this paper</p>
                  : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Download</th>
                          <th>URL</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        files.filter((fileObj) => fileObj.visibility === 'public').map((file) => (
                          <tr key={file.fileName + file.visibility}>
                            <td>
                              {/* eslint-disable-next-line max-len */}
                              <a href={fileUrlMakerFrontend(id, publicationType, category, file.visibility, file.fileName)}>{file.fileName}</a>
                            </td>
                            <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => downloadFileFunc(file.fileName, file.visibility)} icon={faDownload} color="#357341" /></td>
                            <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(fileUrlMakerFrontend(id, publicationType, category, file.visibility, file.fileName)); }} icon={faCopy} color="#4682B4" /></td>
                            <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => deleteFileFunc(file.fileName, file.visibility)} icon={faTrashAlt} color="red" /></td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </Table>
                  )
              }
              <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'public')}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag and drop some files here, or click to select files</p>
                    }
                    {
                      numberOfFilesUploading.public.source > 0 && (
                        <>
                          <p>
                            Uploading
                            {' '}
                            {numberOfFilesUploading.public.source}
                            {' '}
                            file(s)...
                          </p>
                          <Loader centered={false} />
                        </>
                      )
                    }
                  </div>
                )}
              </Dropzone>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Title>Private files</Card.Title>
            <Card.Body>
              {
                files.length === 0 || files.filter((fileObj) => fileObj.visibility === 'private').length === 0
                  ? <p>Add (private) files related to this paper (e.g. source code)</p>
                  : (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Download</th>
                          <th>URL</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        files.filter((fileObj) => fileObj.visibility === 'private').map((file) => {
                          // eslint-disable-next-line max-len
                          const url = fileUrlMakerBackend(id, publicationType, category, file.visibility, file.fileName);
                          return (
                            <tr key={file.fileName + file.visibility}>
                              <td>
                                {/* eslint-disable-next-line max-len */}
                                <a href={fileUrlMakerFrontend(id, publicationType, category, file.visibility, file.fileName)}>{file.fileName}</a>
                              </td>
                              <td><AuthenticatedLink filename={file.fileName} url={url}><FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faDownload} color="#357341" /></AuthenticatedLink></td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(fileUrlMakerFrontend(id, publicationType, category, file.visibility, file.fileName)); }} icon={faCopy} color="#4682B4" /></td>
                              <td><FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => deleteFileFunc(file.fileName, file.visibility)} icon={faTrashAlt} color="red" /></td>
                            </tr>
                          );
                        })
                      }
                      </tbody>
                    </Table>
                  )
              }
              <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'private')}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag and drop some files here, or click to select files</p>
                    }
                    {
                      numberOfFilesUploading.private.source > 0 && (
                        <>
                          <p>
                            Uploading
                            {' '}
                            {numberOfFilesUploading.private.source}
                            {' '}
                            file(s)...
                          </p>
                          <Loader centered={false} />
                        </>
                      )
                    }

                  </div>
                )}
              </Dropzone>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

RepositoryContainerPublication.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  numberOfFilesUploading: PropTypes.objectOf(PropTypes.any).isRequired,
  addFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  publicationType: PropTypes.string,
  category: PropTypes.string.isRequired,
};
RepositoryContainerPublication.defaultProps = {
  publicationType: '',
};
export default RepositoryContainerPublication;
