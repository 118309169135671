import { createAction } from 'redux-actions';

export const CREATE_BOOKCHAPTER = 'CREATE_BOOKCHAPTER';
export const CREATE_BOOKCHAPTER_ERROR = 'CREATE_BOOKCHAPTER_ERROR';
export const CREATE_BOOKCHAPTER_SUCCESS = 'CREATE_BOOKCHAPTER_SUCCESS';
export const FETCH_BOOKCHAPTER = 'FETCH_BOOKCHAPTER';
export const FETCH_BOOKCHAPTER_ERROR = 'FETCH_BOOKCHAPTER_ERROR';
export const FETCH_BOOKCHAPTER_SUCCESS = 'FETCH_BOOKCHAPTER_SUCCESS';
export const LOAD_BOOKCHAPTER_REQUIREMENTS = 'LOAD_REQUIREMENTS_BOOKCHAPTER';
export const LOAD_BOOKCHAPTER_REQUIREMENTS_ERROR = 'LOAD_BOOKCHAPTER_REQUIREMENTS_ERROR';
export const LOAD_BOOKCHAPTER_REQUIREMENTS_SUCCESS = 'LOAD_BOOKCHAPTER_REQUIREMENTS_SUCCESS';
export const UPDATE_BOOKCHAPTER = 'UPDATE_BOOKCHAPTER';
export const UPDATE_BOOKCHAPTER_ERROR = 'UPDATE_BOOKCHAPTER_ERROR';
export const UPDATE_BOOKCHAPTER_SUCCESS = 'UPDATE_BOOKCHAPTER_SUCCESS';

export const createBookchapter = createAction(CREATE_BOOKCHAPTER);
export const createBookchapterError = createAction(CREATE_BOOKCHAPTER_ERROR);
export const createBookchapterSuccess = createAction(CREATE_BOOKCHAPTER_SUCCESS);

export const fetchBookchapter = createAction(FETCH_BOOKCHAPTER);
export const fetchBookchapterError = createAction(FETCH_BOOKCHAPTER_ERROR);
export const fetchBookchapterSuccess = createAction(FETCH_BOOKCHAPTER_SUCCESS);

export const loadBookchapterRequirements = createAction(LOAD_BOOKCHAPTER_REQUIREMENTS);
export const loadBookchapterRequirementsError = createAction(LOAD_BOOKCHAPTER_REQUIREMENTS_ERROR);
export const loadBookchapterRequirementsSuccess = createAction(
  LOAD_BOOKCHAPTER_REQUIREMENTS_SUCCESS,
);

export const updateBookchapter = createAction(UPDATE_BOOKCHAPTER);
export const updateBookchapterError = createAction(UPDATE_BOOKCHAPTER_ERROR);
export const updateBookchapterSuccess = createAction(UPDATE_BOOKCHAPTER_SUCCESS);
