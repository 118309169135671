export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const SET_ONE_EVENT = 'SET_ONE_EVENT';
export const FETCH_ONE_EVENT = 'FETCH_ONE_EVENT';
export const FETCH_ONE_EVENT_SUCCESS = 'FETCH_ONE_EVENT_SUCCESS';
export const FETCH_ONE_EVENT_ERROR = 'FETCH_ONE_EVENT_ERROR';

export const createEvent = (data) => ({ type: CREATE_EVENT, data });
export const createEventError = (error) => ({ type: CREATE_EVENT_ERROR, error });
export const createEventSuccess = (data) => ({ type: CREATE_EVENT_SUCCESS, data });

export const deleteEvent = (eventId) => ({ type: DELETE_EVENT, eventId });
export const deleteEventError = (error) => ({ type: DELETE_EVENT_ERROR, error });
export const deleteEventSuccess = () => ({ type: DELETE_EVENT_SUCCESS });

export const updateEvent = (data) => ({ type: UPDATE_EVENT, data });
export const updateEventError = (error) => ({ type: UPDATE_EVENT_ERROR, error });
export const updateEventSuccess = (data) => ({ type: UPDATE_EVENT_SUCCESS, data });

export const fetchOneEvent = (data) => ({ type: FETCH_ONE_EVENT, data });
export const fetchOneEventError = (error) => ({ type: FETCH_ONE_EVENT_ERROR, error });
export const fetchOneEventSuccess = (data) => ({ type: FETCH_ONE_EVENT_SUCCESS, data });
