import {
  CREATE_PATENT,
  CREATE_PATENT_ERROR,
  CREATE_PATENT_SUCCESS,
  FETCH_PATENT,
  FETCH_PATENT_ERROR,
  FETCH_PATENT_SUCCESS,
  LOAD_PATENT_REQUIREMENTS_SUCCESS,
  UPDATE_PATENT,
  UPDATE_PATENT_ERROR,
  UPDATE_PATENT_SUCCESS,
} from './actions';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydratePatentRespones = (response) => ({
  types: 'patent',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  number: response.number,
  applicant: response.applicant,
  description: response.description,
  projects: response.projects,
});

const publicationPatent = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PATENT_SUCCESS:
    case FETCH_PATENT_SUCCESS:
      return { ...state, details: dehydratePatentRespones(action.payload), isLoading: false };

    case CREATE_PATENT_ERROR:
    case UPDATE_PATENT_ERROR:
    case FETCH_PATENT_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_PATENT_SUCCESS:
    case LOAD_PATENT_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_PATENT:
    case FETCH_PATENT:
    case UPDATE_PATENT:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationPatent;
