export const FETCH_ONE_TYPE = 'FETCH_ONE_TYPE';
export const FETCH_ONE_TYPE_SUCCESS = 'FETCH_ONE_TYPE_SUCCESS';
export const FETCH_ONE_TYPE_ERROR = 'FETCH_ONE_TYPE_ERROR';
export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_TYPE_SUCCESS = 'UPDATE_TYPE_SUCCESS';
export const UPDATE_TYPE_ERROR = 'UPDATE_TYPE_ERROR';

export const fetchOneType = (data) => ({ type: FETCH_ONE_TYPE, data });
export const fetchOneTypeError = (error) => ({ type: FETCH_ONE_TYPE_ERROR, error });
export const fetchOneTypeSuccess = (data) => ({ type: FETCH_ONE_TYPE_SUCCESS, data });

export const updateType = (data) => ({ type: UPDATE_TYPE, data });
export const updateTypeError = (error) => ({ type: UPDATE_TYPE_ERROR, error });
export const updateTypeSuccess = (data) => ({ type: UPDATE_TYPE_SUCCESS, data });
