import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authorNameFormatter,
  authorNameFormatterText,
  editorNameFormatter,
  editorNameFormatterText,
  getYearFromDate,
  idFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pagesView,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function BookChapter(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);
  const {
    id,
    title,
    publisher,
    date,
    pages,
    authors,
    editors,
    index,
    bookTitle,
    webVersion,
    webVersionRecording,
    visibility,
    files,
    canEdit,
    isAuthenticated,
  } = props;
  const handleBTClick = () => {
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showLaTex(!isLaTexShown);
  };
  const bibTex = (`
    @INPROCEEDINGS{article${id},
      author =    {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      editor =    {${editors ? editors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      title =     {${title}},
      booktitle = {${bookTitle}}, 
      publisher = {${publisher || ''}}
      year =      {${getYearFromDate(date)}},
      pages =     {${pages}},          
    }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title},'' ${editorNameFormatterText(editors) || ''}, ${publisher || ''}, pp.~${pages || ''}, ${getYearFromDate(date)}.
  `);

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;
                {titleView(id, title, visibility, pdfFile, showPublication, 'bookchapter', isAdmin)}
                &quot;
                {' In '}
                {bookTitle}
                {editors?.length > 0 ? ', ' : ''}
                {editorNameFormatter(editors, true)}
                {publisher ? `, ${publisher}` : ''}
                {pagesView(pages)}
                ,
                {' '}
                {getYearFromDate(date)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'bookchapter')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateBookchapter/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

BookChapter.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bookTitle: PropTypes.string.isRequired,
  publisher: PropTypes.string,
  pages: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editors: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

BookChapter.defaultProps = {
  editors: [],
  publisher: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(BookChapter);
