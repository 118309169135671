import { createAction } from 'redux-actions';

export const CREATE_THESE = 'CREATE_THESE';
export const CREATE_THESE_ERROR = 'CREATE_THESE_ERROR';
export const CREATE_THESE_SUCCESS = 'CREATE_THESE_SUCCESS';
export const FETCH_THESE = 'FETCH_THESE';
export const FETCH_THESE_ERROR = 'FETCH_THESE_ERROR';
export const FETCH_THESE_SUCCESS = 'FETCH_THESE_SUCCESS';
export const LOAD_THESE_REQUIREMENTS = 'LOAD_REQUIREMENTS_THESE';
export const LOAD_THESE_REQUIREMENTS_ERROR = 'LOAD_THESE_REQUIREMENTS_ERROR';
export const LOAD_THESE_REQUIREMENTS_SUCCESS = 'LOAD_THESE_REQUIREMENTS_SUCCESS';
export const UPDATE_THESE = 'UPDATE_THESE';
export const UPDATE_THESE_ERROR = 'UPDATE_THESE_ERROR';
export const UPDATE_THESE_SUCCESS = 'UPDATE_THESE_SUCCESS';

export const createThese = createAction(CREATE_THESE);
export const createTheseError = createAction(CREATE_THESE_ERROR);
export const createTheseSuccess = createAction(CREATE_THESE_SUCCESS);

export const fetchThese = createAction(FETCH_THESE);
export const fetchTheseError = createAction(FETCH_THESE_ERROR);
export const fetchTheseSuccess = createAction(FETCH_THESE_SUCCESS);

export const loadTheseRequirements = createAction(LOAD_THESE_REQUIREMENTS);
export const loadTheseRequirementsError = createAction(LOAD_THESE_REQUIREMENTS_ERROR);
export const loadTheseRequirementsSuccess = createAction(LOAD_THESE_REQUIREMENTS_SUCCESS);

export const updateThese = createAction(UPDATE_THESE);
export const updateTheseError = createAction(UPDATE_THESE_ERROR);
export const updateTheseSuccess = createAction(UPDATE_THESE_SUCCESS);
