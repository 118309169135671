import {
  CREATE_REPORT,
  CREATE_REPORT_ERROR,
  CREATE_REPORT_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORT_ERROR,
  FETCH_REPORT_SUCCESS,
  LOAD_REPORT_REQUIREMENTS_SUCCESS,
  UPDATE_REPORT,
  UPDATE_REPORT_ERROR,
  UPDATE_REPORT_SUCCESS,
} from './actions';
import { calculatePageCount } from '../../../../utils';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateReportRespones = (response) => ({
  types: 'report',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
  note: response.note,
  projects: response.projects,
});

const publicationReport = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_REPORT_SUCCESS:
    case FETCH_REPORT_SUCCESS:
      return { ...state, details: dehydrateReportRespones(action.payload), isLoading: false };

    case CREATE_REPORT_ERROR:
    case UPDATE_REPORT_ERROR:
    case FETCH_REPORT_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_REPORT_SUCCESS:
    case LOAD_REPORT_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_REPORT:
    case FETCH_REPORT:
    case UPDATE_REPORT:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationReport;
