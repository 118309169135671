import React from 'react';
import { checkPubType } from './typeHelper';

const renderPublicationByYear = (pby, canEdit) => {
  const pubsRender = pby.map((pub) => (
    <div key={pub.date}>
      <h2>{pub.date}</h2>
      <table className="table table-striped">
        <tbody>
          {checkPubType(pub.publications, canEdit)}
        </tbody>
      </table>
    </div>
  ));
  return (
    <div>
      {pubsRender}
    </div>

  );
};

function ByYearWrapper(props) {
  const { pby, canEdit } = props;
  return (
    renderPublicationByYear(pby, canEdit)
  );
}

export default ByYearWrapper;
