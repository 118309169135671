import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authorNameFormatter,
  authorNameFormatterText,
  editorNameFormatterText,
  getYearFromDate,
  idFormatter,
  replaceEdition,
  statusFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pagesView,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function AbstractOfTalk(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);

  const {
    id,
    volume,
    title,
    status,
    from,
    proceedingsFrom,
    authors,
    editors,
    index,
    proceedings,
    publisher,
    edition,
    pages,
    city,
    country,
    webVersion,
    webVersionRecording,
    visibility,
    files,
    canEdit,
    isAuthenticated,
  } = props;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };
  const bibTex = (`
    @INPROCEEDINGS{article${id},
      author =    {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      title =     {${title}},
      year =      {${getYearFromDate(from)}},
      pages =     {${pages}},
      booktitle = {${proceedings}},
      editor =    {${editors ? editors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      volume =    {${volume || ''}},
      number =    {},
      series =    {},
      address =   {${city},${country}},
      publisher = {${publisher || ''}}
    }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title},'' { ${proceedings || ''}}, ${editorNameFormatterText(editors) || ''}, pp.~${pages || ''}, ${getYearFromDate(from)}.
  `);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;

                {titleView(id, title, visibility, pdfFile, showPublication, 'abstractoftalk', isAdmin)}
                &quot;,
                {' '}
                In
                {' '}
                {replaceEdition(edition, proceedings, proceedingsFrom)}
                {pagesView(pages)}
                ,
                {' '}
                {getYearFromDate(from)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'abstractoftalk')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateAbstractOfTalk/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

AbstractOfTalk.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  proceedingsFrom: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editors: PropTypes.arrayOf(PropTypes.any),
  index: PropTypes.number.isRequired,
  volume: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  proceedings: PropTypes.string.isRequired,
  publisher: PropTypes.string,
  edition: PropTypes.number,
  pages: PropTypes.string.isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

AbstractOfTalk.defaultProps = {
  edition: null,
  editors: [],
  publisher: '',
  volume: '',
  webVersion: '',
  webVersionRecording: '',
  visibility: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(AbstractOfTalk);
