export const FETCH_ONE_VENUE = 'FETCH_ONE_VENUE';
export const FETCH_ONE_VENUE_SUCCESS = 'FETCH_ONE_VENUE_SUCCESS';
export const FETCH_ONE_VENUE_ERROR = 'FETCH_ONE_VENUE_ERROR';
export const UPDATE_VENUE = 'UPDATE_VENUE';
export const UPDATE_VENUE_SUCCESS = 'UPDATE_VENUE_SUCCESS';
export const UPDATE_VENUE_ERROR = 'UPDATE_VENUE_ERROR';

export const fetchOneVenue = (data) => ({ type: FETCH_ONE_VENUE, data });
export const fetchOneVenueSuccess = (data) => ({ type: FETCH_ONE_VENUE_SUCCESS, data });
export const fetchOneVenueError = (error) => ({ type: FETCH_ONE_VENUE_ERROR, error });

export const updateVenue = (data) => ({ type: UPDATE_VENUE, data });
export const updateVenueSuccess = (data) => ({ type: UPDATE_VENUE_SUCCESS, data });
export const updateVenueError = (error) => ({ type: UPDATE_VENUE_ERROR, error });
