import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreateExternalPeople from './createExternalPeople';
import { ClickText } from '../../index';

export default function CreateExternalPeopleModal() {
  const [isExternalAuthorPopupShown, setExternalAuthorPopupShown] = useState(false);
  const handleClose = () => setExternalAuthorPopupShown(false);
  return (
    <>
      <ClickText text="Add External People" onClick={() => setExternalAuthorPopupShown(true)} />
      <Modal
        show={isExternalAuthorPopupShown}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create External</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateExternalPeople onSubmitted={handleClose} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
