import {
  CREATE_TALK,
  CREATE_TALK_ERROR,
  CREATE_TALK_SUCCESS,
  FETCH_TALK,
  FETCH_TALK_ERROR,
  FETCH_TALK_SUCCESS,
  LOAD_TALK_REQUIREMENTS_SUCCESS,
  UPDATE_TALK,
  UPDATE_TALK_ERROR,
  UPDATE_TALK_SUCCESS,
} from './actions';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateTalkRespones = (response) => ({
  types: 'talk',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.from,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  presenters: response.presenters.map((presenter) => ({
    value: presenter.id, label: `${presenter.last}, ${presenter.first}`, order: presenter.order,
  })),
  country: response.country,
  city: response.city,
  typeEvent: response.typeEvent,
  event: response.event,
  eventSelectInput: (response.typeEvent === 'Invited talk at a conference/workshop'
    ? response.event
    : null),
  eventFreeInput: (response.typeEvent !== 'Invited talk at a conference/workshop'
    ? response.event
    : ''),
  projects: response.projects,
});

const publicationTalk = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_TALK_SUCCESS:
    case FETCH_TALK_SUCCESS:
      return { ...state, details: dehydrateTalkRespones(action.payload), isLoading: false };

    case CREATE_TALK_ERROR:
    case UPDATE_TALK_ERROR:
    case FETCH_TALK_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_TALK_SUCCESS:
    case LOAD_TALK_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_TALK:
    case FETCH_TALK:
    case UPDATE_TALK:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationTalk;
