import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_BOOKCHAPTER,
  createBookchapterError,
  createBookchapterSuccess,
  FETCH_BOOKCHAPTER,
  fetchBookchapterError,
  fetchBookchapterSuccess,
  fetchBookchapter as fetchBookchapterAction,
  LOAD_BOOKCHAPTER_REQUIREMENTS,
  loadBookchapterRequirementsError,
  loadBookchapterRequirementsSuccess,
  UPDATE_BOOKCHAPTER,
  updateBookchapterError,
  updateBookchapterSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createBookArticle as createBookchapterMutation,
  QuerybookArticleById as fetchBookchapterQuery,
  updateBookArticle as updateBookchapterMutation,
} from '../../../../graphql/queries/publications';

export function* createBookchapter({ payload }) {
  const response = yield call(axios.post, '/', { query: createBookchapterMutation(payload) });
  yield put(createBookchapterSuccess());

  const { id } = response.data.data.createBookArticle;
  yield put(push(`/researcher/publications/updateBookchapter/${id}`));
  showSuccessMessage('Book article created successfully');
}

export function* fetchBookchapter({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchBookchapterQuery(payload.id) });
  yield put(fetchBookchapterSuccess(response.data.data.bookArticleById));
}

export function* updateBookchapter({ payload }) {
  const response = yield call(axios.post, '/', { query: updateBookchapterMutation(payload) });
  yield put(updateBookchapterSuccess(response.data.data.updateBookArticle));
  showSuccessMessage('Book article updated successfully');
}

export function* loadBookchapter({ payload }) {
  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);

  if (payload.id) {
    yield put(fetchBookchapterAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadBookchapterRequirementsSuccess());
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_BOOKCHAPTER,
    catchErrors({
      onErrorAction: createBookchapterError,
      overrideErrorMessage: 'Could not create publication of type bookchapter',
    })(createBookchapter),
  );

  yield takeLatest(
    FETCH_BOOKCHAPTER,
    catchErrors({
      onErrorAction: fetchBookchapterError,
      overrideErrorMessage: 'Could not fetch bookchapter',
    })(fetchBookchapter),
  );

  yield takeLatest(
    LOAD_BOOKCHAPTER_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadBookchapterRequirementsError,
    })(loadBookchapter),
  );

  yield takeLatest(
    UPDATE_BOOKCHAPTER,
    catchErrors({
      onErrorAction: updateBookchapterError,
      overrideErrorMessage: 'Could not update publication of type bookchapter',
    })(updateBookchapter),
  );
}
