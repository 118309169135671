import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { CREATE_EXTERNAL_PEOPLE } from '../../../state/person/personActions';

const validationSchema = Yup.object()
  .shape({
    lastname: Yup.string().required('Lastname is mandatory'),
    firstname: Yup.string().required('Firstname is mandatory'),
  });

const CreateExternalPeople = (props) => (
  <Card className="filter-card">
    <Card.Body>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // eslint-disable-next-line react/destructuring-assignment
          props.createExternalPeople(values);
          // eslint-disable-next-line react/destructuring-assignment
          props.onSubmitted(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Label>First Name (full name, no abbreviations)</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                  className="form-control"
                />
                {
                  touched.firstname
                  && errors.firstname ? (
                    <div className="error-message">{errors.firstname}</div>
                    ) : null
      }
              </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  className="form-control"
                />
                {
                  touched.lastname
                  && errors.lastname ? (
                    <div className="error-message">{errors.lastname}</div>
                    ) : null
      }
              </Col>
            </Row>
            <Form.Row style={{ marginTop: '2em' }}>
              <Button
                type="submit"
                variant="primary"
                className="btn btn-success ml-auto"
                disabled={isSubmitting}
                block
              >
                {isSubmitting ? 'Submitting...' : 'Create' }
              </Button>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </Card.Body>
  </Card>
);

CreateExternalPeople.propTypes = {
  onSubmitted: PropTypes.func.isRequired,
  createExternalPeople: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  createExternalPeople: (data) => dispatch(CREATE_EXTERNAL_PEOPLE(data)),
});

export default connect(null, mapDispatchToProps)(CreateExternalPeople);
