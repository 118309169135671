import {
  DELETE_VENUE,
  DELETE_VENUE_ERROR,
  DELETE_VENUE_SUCCESS,
  FETCH_ALL_VENUES_ERROR,
  FETCH_ALL_VENUES_SUCCESS,
  SET_VENUES_FILTER_NAME,
} from './venuesActions';

const defaultState = {
  isLoading: true,
  journals: [],
  conferences: [],
  filter: '',
  error: '',
};

const venues = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_VENUES_SUCCESS:
      return {
        ...state,
        journals: action.data.journals,
        conferences: action.data.conferences,
        isLoading: false,
      };
    case FETCH_ALL_VENUES_ERROR:
      return { ...state, data: action.error, isLoading: false };
    case SET_VENUES_FILTER_NAME:
      return { ...state, filter: action.data };

    case DELETE_VENUE:
      return { ...state, isLoading: true };
    case DELETE_VENUE_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_VENUE_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default venues;
