import type { PeopleState, PeopleAction } from './types';

import {
  FETCH_ALL_PEOPLE_INCL_EXTERNAL,
  FETCH_ALL_PEOPLE_INCL_EXTERNAL_SUCCESS,
  FETCH_ALL_PEOPLE_INCL_EXTERNAL_ERROR,
} from './peopleActions';

const defaultState: PeopleState = {
  isLoading: true,
  data: [],
  authors: [],
  types: [],
  teams: [],
  peoplePromoters: [],
  listInclExternal: [],
  filter: {
    active: 'Active',
    statusType: 'Any',
    team: '',
    startDate: '',
  },
  error: '',
};

const people = (state: PeopleState = defaultState, action: PeopleAction): PeopleState => {
  switch (action.type) {
    case 'INIT_DATA_SUCCESS':
      return {
        ...state,
        data: action.data.allPeopleWithoutLists,
        authors: action.data.allPeopleWithoutLists,
        isLoading: false,
      };
    case 'FETCH_ALL_PEOPLE_SUCCESS':
      return { ...state, data: action.data.allPeopleWithoutLists, isLoading: false };
    case 'FETCH_ALL_FILTERED_PEOPLE':
      return { ...state, isLoading: true };
    case 'FETCH_ALL_FILTERED_PEOPLE_SUCCESS':
      return { ...state, data: action.data, isLoading: false };
    case 'FETCH_ALL_PEOPLE_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_ALL_FILTERED_PEOPLE_ERROR':
      return { ...state, error: action.error, isLoading: false };
    case 'FETCH_ALL_PEOPLE_PROMOTERS':
      return { ...state };
    case 'FETCH_ALL_PEOPLE_PROMOTERS_SUCCESS':
      return { ...state, peoplePromoters: action.data };
    case 'FETCH_ALL_PEOPLE_PROMOTERS_ERROR':
      return { ...state, error: action.error };
    case 'SET_PEOPLE_FILTER_ACTIVE':
      return { ...state, filter: { ...state.filter, active: action.data } };
    case 'SET_PEOPLE_FILTER_STATUSTYPE':
      return { ...state, filter: { ...state.filter, statusType: action.data } };
    case 'SET_PEOPLE_FILTER_TEAM':
      return { ...state, filter: { ...state.filter, team: action.data } };
    case 'SET_PEOPLE_FILTER_STARTDATE':
      return { ...state, filter: { ...state.filter, startDate: action.data } };
    case 'FETCH_ALL_PEOPLE_TYPES':
      return { ...state };
    case 'FETCH_ALL_PEOPLE_TYPES_SUCCESS':
      return { ...state, types: action.data };
    case 'FETCH_ALL_PEOPLE_TYPES_ERROR':
      return { ...state, error: action.error };
    case 'FETCH_ALL_PEOPLE_TEAMS':
      return { ...state };
    case 'FETCH_ALL_PEOPLE_TEAMS_SUCCESS':
      return { ...state, teams: action.data };
    case 'FETCH_ALL_PEOPLE_TEAMS_ERROR':
      return { ...state, error: action.error };

    case FETCH_ALL_PEOPLE_INCL_EXTERNAL:
      return { ...state, isLoading: true };
    case FETCH_ALL_PEOPLE_INCL_EXTERNAL_SUCCESS:
      return { ...state, listInclExternal: action.data, isLoading: false };
    case FETCH_ALL_PEOPLE_INCL_EXTERNAL_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default people;
