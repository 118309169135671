import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationJournal = get('publicationJournal');
export const selectPublicationJournalDetails = createSelector(
  selectPublicationJournal,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationJournal, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationJournalDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
