// @flow

import type { PeopleTypesAction, PeopleTypesState } from './types';

const defaultState: PeopleTypesState = {
  isLoading: true,
  data: [],
  error: '',
};

const peopleTypes = (state: PeopleTypesState = defaultState, action: PeopleTypesAction):
  PeopleTypesState => {
  switch (action.type) {
    case 'FETCH_ALL_PEOPLETYPES_SUCCESS':
      return { ...state, data: action.data, isLoading: false };
    case 'FETCH_ALL_PEOPLETYPES_ERROR':
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
};

export default peopleTypes;
