import {
  FETCH_ONE_VENUE,
  FETCH_ONE_VENUE_SUCCESS,
  FETCH_ONE_VENUE_ERROR,
  UPDATE_VENUE,
  UPDATE_VENUE_SUCCESS,
  UPDATE_VENUE_ERROR,
} from './venueActions';

const defaultState = {
  isLoading: false,
  data: {},
  error: '',
};

const venue = (state = defaultState, action) => {
  let tmp;
  switch (action.type) {
    case FETCH_ONE_VENUE:
      return { ...state, isLoading: true };
    case UPDATE_VENUE:
      return { ...state, isLoading: true };
    case FETCH_ONE_VENUE_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_VENUE_ERROR:
      return { ...state, data: action.error, isLoading: false };
    case UPDATE_VENUE_SUCCESS:
      if (action.data.data.updateJournal) {
        tmp = action.data.data.updateJournal;
      } else if (action.data.data.updateConferenceInput) {
        tmp = action.data.data.updateConferenceInput;
      } else if (action.data.data.createConferenceInput) {
        tmp = action.data.data.createConferenceInput;
      } else if (action.data.data.createJournal) {
        tmp = action.data.data.createJournal;
      }

      return { ...state, data: tmp, isLoading: false };
    case UPDATE_VENUE_ERROR:
      return { ...state, data: action.error, isLoading: false };

    default:
      return state;
  }
};

export default venue;
