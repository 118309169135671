import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  Container, Form, Row, Col, Card, Tab, Nav, Button, Image,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import Dropzone from 'react-dropzone';
import { Loader } from '../../../component/index';

import EditPeopleResearchDataForm from '../../../component/admin/people/editPeopleResearchDataForm';
import EditPeoplePhdForm from '../../../component/admin/people/editPeoplePhdForm';
import EditPeopleAlumnusForm from '../../../component/admin/people/editPeopleAlumnusForm';
import EditPeopleInternshipsForm from '../../../component/admin/people/editPeopleInternshipsForm';
import EditPeopleAwardsForm from '../../../component/admin/people/editPeopleAwardsForm';
import EditPeopleCommitteeForm from '../../../component/admin/people/editPeopleCommitteeForm';
import EditPeopleProjectsForm from '../../../component/admin/people/editPeopleProjectsForm';
import EditPeopleHistoryForm from '../../../component/admin/people/editPeopleHistoryForm';
import EditPeopleEventsForm from '../../../component/admin/people/editPeopleEventsForm';
import MemberOfTeams from '../../../component/admin/people/MemberOfTeams';
import RenderByPerson from '../../../component/publicationFormatTypes/renderPublicationsForPerson';
import {
  CLEAR_PERSON_FORM,
  FETCH_ONE_PERSON,
  MUTATE_PERSON,
  CREATE_PERSON,
  SET_PERSON_ROLE,
  FETCH_TEAMS,
  FETCH_ZAPS,
  addMembership as addMembershipAction,
  removeMembership as removeMembershipAction,
  fetchPersonPublications as fetchPersonPublicationsAction,
} from '../../../state/person/personActions';
import {
  fetchAllCountries as fetchAllCountriesAction,
} from '../../../state/countries/countriesActions';
import {
  fetchAllPartners as fetchAllPartnersAction,
} from '../../../state/partners/partnersActions';
import { FETCH_ALL_PEOPLETYPES } from '../../../state/peopleType/peopleTypeActions';
import '../../../styles/forms.scss';
import { genderOptions } from '../../../config/optionValues';
import config from '../../../config/config';
import { selectPersonPublications } from '../../../state/person/selectors';
import { userWithPermission } from '../../../utils';
import {
  selectIsAdmin,
  selectUserPermissions,
} from '../../../state/user/selectors';
import { UPLOAD_PROFILE_IMAGE } from '../../../state/profile/profileAction';
import defaultImage from '../../../assets/defaultProfileImage.png';

const roleOptions = ['ADMIN', 'RESEARCH_EXPERT', 'RESEARCHER', 'VISITOR'].map((p) => ({
  label: p,
  value: p,
}));

const Person = (props) => {
  const {
    personId: propsPersonId,
    isAdmin,
    uploadImage,
    isImageLoading,
    keyNr,
    loading,
    // eslint-disable-next-line react/prop-types
    initialValues,
    userPermissions,
    person,
    fetchPerson,
    // eslint-disable-next-line react/prop-types
    clearForm,
    peopleTypes,
    mutatePerson,
    createPerson,
    setPersonRole,
    fetchAllCountries,
    fetchAllPartners,
    fetchPeopleTypes,
    fetchTeamList,
    fetchZAPList,
    countriesOptions,
    availableTeams,
    addMembership,
    removeMembership,
    publications,
    fetchPublicationsByPerson,
    partners,
  } = props;

  const { id = propsPersonId } = useParams(); // from params, otherwise from props

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type.includes('image')) {
      const data = new FormData();
      data.append('file', acceptedFiles[0]);
      data.append('id', id);
      uploadImage(data);
    }
  };

  const countriesOptionsSelect = countriesOptions.map((c) => ({
    label: c.name,
    value: c.id,
  }));

  useEffect(() => {
    clearForm();
    fetchAllCountries();
    fetchAllPartners();
    fetchPeopleTypes();
    fetchTeamList();
    fetchZAPList();
    if (id) {
      fetchPerson({ id });
      fetchPublicationsByPerson(id);
    }
  }, [
    fetchAllCountries,
    fetchAllPartners,
    fetchPeopleTypes,
    fetchTeamList,
    fetchZAPList,
    fetchPerson,
    fetchPublicationsByPerson,
    id,
    clearForm,
  ]);

  let partnerOptions = partners && Array.isArray(partners) ? partners.map((p) => ({
    label: p.name,
    value: p.id,
  })) : [];
  partnerOptions = [
    { label: 'None', value: '' },
    ...partnerOptions,
  ];

  const handleDatetimeChange = (dt, fieldName, format, setFieldValue) => {
    /*
      Work-around for `react-datetime`

      Change Formik value using `setFieldValue`.
      See:
      https://stackoverflow.com/questions/54039555/problem-with-saving-dt-with-react-datetime-inside-formik-component
    */
    if (typeof (dt) === 'string') {
      setFieldValue(fieldName, dt);
    } else {
      setFieldValue(fieldName, dt.format(format));
    }
  };

  const SignupSchema = Yup.object()
    .shape({
      lastname: Yup.string().required('Lastname is mandatory'),
      firstname: Yup.string().required('Firstname is mandatory'),
      partner: Yup.string(),
      email: Yup.string().email().required('Email is mandatory'),
      postal: Yup.string().required('Postal code is mandatory'),
      biography: Yup.string(),
      birthDate: Yup.date(),
      userid: Yup.string().required(),
      room: Yup.string(),
      kulType: Yup.string().required('Current status type is mandatory'),
      mobile: Yup.string(),
      privateAddress: Yup.string(),
      privateEmail: Yup.string().email(),
      website: Yup.string().url('Must be a valid URL'),
      privatePhone: Yup.string(),
    });

  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  const hasPermission = (permission) => userWithPermission(userPermissions, permission);

  const confirmationText = id ? 'Save' : 'Create';

  const showTabs = () => (
    <Row>
      <Col>
        <Card>
          <Tab.Container id="left-tabs-example" defaultActiveKey="memberships">
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="memberships" disabled={loading}>Memberships</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="research" disabled={loading}>Research Data</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="phd" disabled={loading}>PhD Data</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="alumnus" disabled={loading}>Alumnus</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="internships" disabled={loading}>Internships</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="awards" disabled={loading}>Awards</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="committee" disabled={loading}>Member of program Committee</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="history" disabled={loading}>Status History</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="projects" disabled={loading}>Projects</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="publications" disabled={loading}>Publications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="events" disabled={loading}>Events</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="memberships">
                  {person.teams && (
                    <MemberOfTeams
                      loading={loading}
                      addMembership={addMembership}
                      allMemberships={availableTeams}
                      memberships={person.teams}
                      peopleId={id}
                      removeMembership={removeMembership}
                      canEdit={isAdmin}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="research">
                  <EditPeopleResearchDataForm />
                </Tab.Pane>
                <Tab.Pane eventKey="phd">
                  <EditPeoplePhdForm />
                </Tab.Pane>
                <Tab.Pane eventKey="alumnus">
                  <EditPeopleAlumnusForm canEdit={isAdmin} />
                </Tab.Pane>
                <Tab.Pane eventKey="internships">
                  <EditPeopleInternshipsForm />
                </Tab.Pane>
                <Tab.Pane eventKey="awards">
                  <EditPeopleAwardsForm publications={publications} />
                </Tab.Pane>
                <Tab.Pane eventKey="committee">
                  <EditPeopleCommitteeForm />
                </Tab.Pane>
                <Tab.Pane eventKey="history">
                  {person.userid && <EditPeopleHistoryForm canEdit={isAdmin} />}
                </Tab.Pane>
                <Tab.Pane eventKey="projects">
                  {person.userid && <EditPeopleProjectsForm userId={person.userid} />}
                </Tab.Pane>
                <Tab.Pane eventKey="publications">
                  {publications && <RenderByPerson publications={publications} canEdit={isAdmin} />}
                </Tab.Pane>
                <Tab.Pane eventKey="events">
                  <EditPeopleEventsForm eventData={{
                    loading,
                    events: person.events,
                  }}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="filter-card">
            <Card.Header>
              General Data
              <div style={{ float: 'right', width: '250px' }}>
                {loading
                  ? <Loader />
                  : (
                    person.id && hasPermission('ROLES_WRITE') && (
                    <Select
                      onChange={(s) => setPersonRole({ id: person.id, role: s.value })}
                      options={roleOptions}
                      value={person.role ? (
                        roleOptions
                          .find((role) => role.value === person.role)
                      ) : undefined}
                      defaultValue={roleOptions.find(
                        (role) => role.value === person.role,
                      )}
                    />
                    ))}
              </div>
            </Card.Header>
            <Card.Body>
              {loading
                ? <Loader />
                : (
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      if (id) {
                        mutatePerson(values);
                      } else {
                        createPerson(values);
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs={3}>
                            <Card style={{ height: 400 }}>
                              <Card.Header>Profile image</Card.Header>
                              <Card.Body>
                                {
                                  isImageLoading ? <Loader /> : (
                                    <Image
                                      key={keyNr}
                                      src={`${config.API_URL}/files/downloadProfilePicture/${id}?${keyNr}`}
                                        // eslint-disable-next-line
                                      onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                                      style={{
                                        display: 'block',
                                        margin: 'auto',
                                        height: '100%',
                                        width: 'auto',
                                      }}
                                      fluid
                                      rounded
                                    />
                                  )
                                }
                              </Card.Body>
                              <Card.Footer>
                                <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, 'public')}>
                                  {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      {/* eslint-disable-next-line max-len */}
                                      <p>Drag and drop profile image here, or click to select image</p>
                                    </div>
                                  )}
                                </Dropzone>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>Author id publications</Form.Label>
                                <Form.Text>{id}</Form.Text>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>ESAT Username</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="userid"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.userid}
                                  className="form-control"
                                />
                                {displayError(errors.userid, touched.userid)}
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>U-NR</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="uNumber"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.uNumber}
                                  className="form-control"
                                />
                                {displayError(errors.uNumber, touched.uNumber)}
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col} sm={3}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="firstname"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.firstname}
                                  className="form-control"
                                />
                                {displayError(errors.firstname, touched.firstname)}
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="lastname"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.lastname}
                                  className="form-control"
                                />
                                {displayError(errors.lastname, touched.lastname)}
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  className="form-control"
                                />
                                {displayError(errors.email, touched.email)}
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>Postal Address</Form.Label>
                                <Form.Text muted>
                                  {/* eslint-disable-next-line max-len */}
                                  Please use this format: 'Computerbeveiliging & Ind.Crypt.Leuven, Kasteelpark Arenberg 10 - bus 2452, 3001 Heverlee, België'
                                </Form.Text>
                                <Form.Control
                                  type="text"
                                  name="postal"
                                  disabled={!isAdmin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.postal}
                                  className="form-control"
                                />
                                {displayError(errors.postal, touched.postal)}
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Room</Form.Label>
                            <Form.Control
                              type="text"
                              name="room"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.room}
                              className="form-control"
                            />
                            {errors.roomm && touched.room && errors.room}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              className="form-control"
                            />
                            {displayError(errors.phone, touched.phone)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>KU Leuven Type</Form.Label>
                            <Form.Control
                              as="select"
                              name="kulType"
                              disabled={!isAdmin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.kulType}
                              className="form-control"
                            >
                              <option />
                              {peopleTypes.map((pt) => (
                                <option
                                  value={pt.id}
                                  key={`peopletype_${pt.id}`}
                                >
                                  {pt.name}
                                </option>
                              ))}
                            </Form.Control>
                            {displayError(errors.kulType, touched.kulType)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Needs desk?</Form.Label>
                            <Form.Check
                              name="needsDesk"
                              onChange={(e) => setFieldValue('needsDesk', e.target.checked)}
                              onBlur={(e) => setFieldValue('needsDesk', e.target.checked)}
                              checked={values.needsDesk}
                              value={values.needsDesk}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Visiting from [if relevant]</Form.Label>
                            <Form.Text muted>
                              Missing item? Add via Settings -> Partners
                            </Form.Text>
                            <Select
                              name="partner"
                              onChange={(s) => setFieldValue('partner', s.value)}
                              options={partnerOptions}
                              value={id ? (
                                partnerOptions
                                  .find((partner) => partner.value === values.partner)
                              ) : undefined}
                              defaultValue={partnerOptions.find(
                                (partner) => partner.value === values.partner,
                              )}
                            />
                            {displayError(errors.partner, touched.partner)}
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Active</Form.Label>
                            <Form.Check
                              name="active"
                              disabled={!isAdmin}
                              onChange={(e) => setFieldValue('active', e.target.checked)}
                              onBlur={(e) => setFieldValue('active', e.target.checked)}
                              checked={values.active}
                              value={values.active}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Nationality</Form.Label>
                            <Select
                              name="nationality"
                              onChange={(s) => setFieldValue('nationality', s ? s.value : '')}
                              options={countriesOptionsSelect}
                              value={id ? (
                                countriesOptionsSelect
                                  .find((country) => country.value === values.nationality)
                              ) : undefined}
                              defaultValue={countriesOptionsSelect.find(
                                (country) => country.value === values.nationality,
                              )}
                            />
                            {displayError(errors.nationality, touched.nationality)}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Gender</Form.Label>
                            <Select
                              name="gender"
                              options={genderOptions}
                              isClearable
                              value={id ? (
                                genderOptions.find((gender) => gender.value === values.gender)
                              ) : undefined}
                              onChange={(v) => setFieldValue('gender', v ? v.value : '')}
                            />
                            {displayError(errors.gender, touched.gender)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Birth Date</Form.Label>
                            <Datetime
                              name="birthDate"
                              value={values.birthDate}
                              dateFormat="YYYY-MM-DD"
                              timeFormat={false}
                              onChange={(date) => handleDatetimeChange(date, 'birthDate', 'YYYY-MM-DD', setFieldValue)}
                              onClose={(date) => handleDatetimeChange(date, 'birthDate', 'YYYY-MM-DD', setFieldValue)}
                            />
                            {displayError(errors.birthDate, touched.birthDate)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Speaks Dutch ?</Form.Label>
                            <Form.Check
                              name="speaksDutch"
                              onChange={(e) => setFieldValue('speaksDutch', e.target.checked)}
                              onBlur={(e) => setFieldValue('speaksDutch', e.target.checked)}
                              checked={values.speaksDutch}
                              value={values.speaksDutch}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label>Private Address</Form.Label>
                            <Form.Control
                              type="text"
                              name="privateAddress"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.privateAddress}
                              className="form-control"
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type="text"
                              name="mobile"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobile}
                              className="form-control"
                            />
                            {displayError(errors.mobile, touched.mobile)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Private phone</Form.Label>
                            <Form.Control
                              type="text"
                              name="privatePhone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.privatePhone}
                              className="form-control"
                            />
                            {displayError(errors.privatePhone, touched.privatePhone)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Private Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="privateEmail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.privateEmail}
                              className="form-control"
                            />
                            {displayError(errors.privateEmail, touched.privateEmail)}
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>Personal website</Form.Label>
                            <Form.Control
                              type="text"
                              name="website"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.website}
                              className="form-control"
                            />
                            {displayError(errors.website, touched.website)}
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            {/* eslint-disable-next-line max-len */}
                            <Form.Label>Text for website: biography, research description, achievements, etc</Form.Label>
                            <Form.Text muted>
                              Use MarkDown to style your text (see https://www.markdownguide.org/basic-syntax/)
                            </Form.Text>
                            <Form.Control
                              as="textarea"
                              type="text"
                              name="biography"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.biography}
                              maxLength={8000}
                              className="form-control"
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col}>
                            <Button
                              type="submit"
                              className="btn btn-success"
                              disabled={isSubmitting}
                              style={{ width: '100%', marginTop: '1em' }}
                            >
                              {isSubmitting ? 'Submitting...' : confirmationText}
                            </Button>
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {id ? showTabs() : <Row />}
    </Container>
  );
};

Person.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  personId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  fetchPerson: PropTypes.func.isRequired,
  mutatePerson: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  setPersonRole: PropTypes.func.isRequired,
  fetchAllCountries: PropTypes.func.isRequired,
  fetchAllPartners: PropTypes.func.isRequired,
  fetchPeopleTypes: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  peopleTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  // detail: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  person: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchTeamList: PropTypes.func.isRequired,
  fetchZAPList: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countriesOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  availableTeams: PropTypes.arrayOf(PropTypes.object).isRequired,
  addMembership: PropTypes.func.isRequired,
  removeMembership: PropTypes.func.isRequired,
  fetchPublicationsByPerson: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  publications: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  uploadImage: PropTypes.func.isRequired,
  isImageLoading: PropTypes.bool.isRequired,
  keyNr: PropTypes.number.isRequired,
};

Person.defaultProps = {
  personId: null,
};

const mapStateToProps = (state) => ({
  isAdmin: selectIsAdmin(state),
  loading: state.person.isLoading,
  initialValues: state.person.initialValues,
  person: state.person.data,
  countries: state.countries.data,
  partners: state.partners.data,
  peopleTypes: state.peopleTypes.data,
  countriesOptions: state.countries.data,
  availableTeams: state.person.teams,
  publications: selectPersonPublications(state),
  userPermissions: selectUserPermissions(state),
  isImageLoading: state.profile.isImageLoading,
  keyNr: state.profile.key,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPerson: (data) => dispatch(FETCH_ONE_PERSON(data)),
  clearForm: () => dispatch(CLEAR_PERSON_FORM()),
  setPersonRole: (data) => dispatch(SET_PERSON_ROLE(data)),
  mutatePerson: (data) => dispatch(MUTATE_PERSON(data)),
  createPerson: (data) => dispatch(CREATE_PERSON(data)),
  fetchAllCountries: () => dispatch(fetchAllCountriesAction()),
  fetchAllPartners: () => dispatch(fetchAllPartnersAction()),
  fetchPeopleTypes: () => dispatch(FETCH_ALL_PEOPLETYPES()),
  fetchTeamList: () => dispatch(FETCH_TEAMS()),
  fetchZAPList: () => dispatch(FETCH_ZAPS()),
  addMembership: (personId, teamId) => dispatch(addMembershipAction(personId, teamId)),
  removeMembership: (personId, teamId) => dispatch(removeMembershipAction(personId, teamId)),
  fetchPublicationsByPerson: (personId) => dispatch(fetchPersonPublicationsAction(personId)),
  uploadImage: (data) => dispatch(UPLOAD_PROFILE_IMAGE(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Person);
