import {
  FETCH_ALL_COMMITTEE_MEMBERS,
  FETCH_ALL_COMMITTEE_MEMBERS_ERROR,
  FETCH_ALL_COMMITTEE_MEMBERS_SUCCESS,
} from './actions';

const defaultState = {
  isLoading: false,
  data: [],
  error: '',
};

const committeeMembers = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMMITTEE_MEMBERS:
      return { ...state, isLoading: false };
    case FETCH_ALL_COMMITTEE_MEMBERS_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ALL_COMMITTEE_MEMBERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
};

export default committeeMembers;
