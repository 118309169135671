import React, { useState, useEffect } from 'react';
import { find } from 'lodash/fp';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { projectTeamRoles } from '../../../config/optionValues';

const ProjectTeamContainer = ({
  addMember,
  removeMember,
  members,
  people,
  id,
}) => {
  const [selectedPeople, setSelectedPeople] = useState(null);
  const [role, setRole] = useState('');
  const [formattedPeople, setFormattedPeople] = useState([]);

  const handleMemberChange = (val) => {
    setSelectedPeople(val);
  };

  const handleAddMember = () => {
    const data = {
      projectId: id,
      teamMember: {
        role: role.value,
        peopleId: selectedPeople.value,
      },
    };

    addMember(data);
    setFormattedPeople(
      formattedPeople.filter((pp) => pp.id !== selectedPeople.value),
    );
    setSelectedPeople(null);
    setRole('');
  };

  const handleDeleteMember = (teamMemberId) => {
    const data = { projectId: id, teamMemberId };
    removeMember(data);
  };

  useEffect(() => {
    const filtered = [];
    people.filter((l) => !members.map((ls) => ls.people
      && ls.people.id).includes(l.id)).forEach((pp) => {
      filtered.push({ value: pp.id, label: `${pp.firstname} ${pp.lastname}` });
    });
    setFormattedPeople(filtered);
  }, [people, members]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Select
            name="type"
            options={formattedPeople}
            onChange={(val) => handleMemberChange(val)}
            value={selectedPeople}
          />
        </Col>
        <Col>
          <Select
            name="role"
            options={projectTeamRoles}
            onChange={(val) => setRole(val)}
            value={role}
          />
        </Col>
        <Col>
          <Button disabled={!selectedPeople || role === ''} variant="primary" onClick={() => handleAddMember()}>Add member</Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Card className="filter-card">
            <table className="table table-card table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Project Role</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  members.map((member) => {
                    const memberRole = find({ value: member.role }, projectTeamRoles);
                    return (
                      <tr key={member.id}>
                        <th>{member.people.gn}</th>
                        <td>{member.people.sn}</td>
                        <td>{memberRole ? memberRole.label : ''}</td>
                        <td className="text-right" style={{ width: 1 }}>
                          <button className="btn btn-sm btn-danger ml-auto" onClick={() => handleDeleteMember(member.people.id)}>Delete</button>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ProjectTeamContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  members: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  addMember: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
};

export default ProjectTeamContainer;
