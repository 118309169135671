import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationTalk = get('publicationTalk');
export const selectPublicationTalkDetails = createSelector(
  selectPublicationTalk,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationTalk, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationTalkDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
