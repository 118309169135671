import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const selectPeopleTypes = get('peopleTypes');
export const selectPeopleTypesList = createSelector(
  selectPeopleTypes,
  get('data'),
);
export const selectIsLoading = createSelector(
  selectPeopleTypes,
  get('isLoading'),
);
