import { LinkContainer } from 'react-router-bootstrap';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { createStructuredSelector } from 'reselect';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button, Modal } from 'react-bootstrap';
import {
  selectUser,
  selectIsAuthenticated,
  selectUserPermissions,
} from '../state/user/selectors';
import { userWithPermission } from '../utils';
import { LOGOUT } from '../state/app/appActions';

function CustomNavbar({
  // eslint-disable-next-line react/prop-types
  user,
  // eslint-disable-next-line react/prop-types
  logout,
  // eslint-disable-next-line react/prop-types
  isAuthenticated,
  // eslint-disable-next-line react/prop-types
  userPermissions,
}) {
  const hasPermission = (permission) => userWithPermission(userPermissions, permission);

  // eslint-disable-next-line react/prop-types
  const RouterNavLink = ({ children, ...props }) => (
    <LinkContainer {...props}>
      <Nav.Link active={false}>
        {children}
      </Nav.Link>
    </LinkContainer>
  );

  // eslint-disable-next-line react/prop-types
  const { firstname, lastname, exp } = user;
  const [diffSeconds, setDiffSeconds] = useState(60 * 60);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAuthenticated) {
      const expirationDate = new Date(1000 * exp);

      const updateDiffSeconds = () => {
        const now = new Date();
        const diff = expirationDate - now;
        setDiffSeconds(Math.floor(diff / 1000));
      };
      updateDiffSeconds();
      const interval = setInterval(updateDiffSeconds, 1000);
      return () => clearInterval(interval);
    }
  }, [exp, isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <div>
        <nav className="top-navbar" />
      </div>
    );
  }

  const minutes = Math.floor(diffSeconds / 60);
  const seconds = Math.floor(diffSeconds % 60);
  const hasExpired = diffSeconds <= 0;
  let expirationString = `${seconds} seconds.`;
  if (minutes > 1) {
    expirationString = `${minutes} minutes.`;
  } else if (minutes === 1) {
    expirationString = `${minutes} minute.`;
  }

  return (
    <>
      <div>
        <nav className="top-navbar" />
        <Navbar bg="light" expand="lg">
          <Navbar.Brand />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {hasPermission('PUBLICATIONS_DATA_READ')
                && (
                  <RouterNavLink exact to="/researcher">My Publications</RouterNavLink>
                )}
              {hasPermission('PUBLICATIONS_DATA_WRITE')
                && (
                  <RouterNavLink exact to="/researcher/new">New Publication</RouterNavLink>
                )}
              {hasPermission('PEOPLE_STATISTICS')
              && (
              <RouterNavLink exact to="/admin">Statistics</RouterNavLink>
              )}
              {hasPermission('PEOPLE_READ')
              && (
                <RouterNavLink exact to="/admin/people">People</RouterNavLink>
              )}
              {hasPermission('PROJECTS_READ')
              && (
                <RouterNavLink exact to="/admin/projects">Projects</RouterNavLink>
              )}
              {hasPermission('PUBLICATIONS_DATA_READ')
              && (
                <RouterNavLink exact to="/admin/publications">Publications</RouterNavLink>
              )}
              {hasPermission('EVENTS_READ')
              && (
                <RouterNavLink exact to="/admin/events">Events</RouterNavLink>
              )}
              {(hasPermission('SETTINGS_READ') || hasPermission('VENUES_READ'))
              && (
                <NavDropdown title="Settings" id="basic-nav-dropdown">
                  {hasPermission('SETTINGS_READ') && (<LinkContainer exact to="/admin/settings/partners"><NavDropdown.Item>Partners</NavDropdown.Item></LinkContainer>)}
                  {hasPermission('SETTINGS_READ') && (<LinkContainer exact to="/admin/settings/funders"><NavDropdown.Item>Funders</NavDropdown.Item></LinkContainer>)}
                  {hasPermission('SETTINGS_READ') && (<LinkContainer exact to="/admin/settings/countries"><NavDropdown.Item>Countries</NavDropdown.Item></LinkContainer>)}
                  {hasPermission('VENUES_READ') && (<LinkContainer exact to="/admin/settings/venues"><NavDropdown.Item>Venues</NavDropdown.Item></LinkContainer>)}
                  {hasPermission('SETTINGS_READ') && (<LinkContainer exact to="/admin/settings/projecttypes"><NavDropdown.Item>Project Types</NavDropdown.Item></LinkContainer>)}
                  {hasPermission('SETTINGS_READ') && (<LinkContainer exact to="/admin/settings/projectCalls"><NavDropdown.Item>Project Calls</NavDropdown.Item></LinkContainer>)}
                </NavDropdown>

              )}
            </Nav>
            <Nav className="ml-auto">
              { hasExpired
                ? (
                  <Navbar.Text>
                    Session has expired
                  </Navbar.Text>
                )
                : (
                  <NavDropdown title={`Session expires in ${expirationString}`} id="basic-nav-dropdown" className="ml-auto">
                    <NavDropdown.Item href="/backend/authenticate">
                      Renew session
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

              <NavDropdown title={`${firstname} ${lastname}`} id="basic-nav-dropdown" className="ml-auto">
                <LinkContainer exact to="/profile"><NavDropdown.Item>My Profile</NavDropdown.Item></LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer
                  exact
                  to="/"
                  onClick={logout}
                  onKeyDown={logout}
                >
                  <NavDropdown.Item>
                    Logout
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <Modal show={hasExpired} backdrop="static">
        <Modal.Header>
          <Modal.Title>Session expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your session has expired, please log in again</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" href="/backend/authenticate">
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  isAuthenticated: selectIsAuthenticated,
  userPermissions: selectUserPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(LOGOUT()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar);
