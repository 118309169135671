import {
  call,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import { push } from 'connected-react-router';
import axios, { axiosClientNoGraphl } from '../../axios';
import { failedNotificationTimer } from '../../config/constants';
import catchErrors from '../../graphql/sagas';
import {
  QueryPerson,
  SetPersonRole,
  CreationPerson,
  MutationPerson,
  QuerPersonProjects,
  MutationPersonPhDData,
  MutationPersonAlumnusData,
  mutateCreateExternalPeople,
  QueryPersonInternships,
  MutationPersonAddInternship,
  MutationPersonUpdateInternship,
  MutationPersonRemoveInternship,
  QueryPersonAwards,
  MutationUpdateAward,
  MutationRemoveAwardFromPeople,
  MutationAddAwardToPeople,
  QueryZapList,
  QueryTeams,
  MutationUpdateResearchData,
  MutationUpdatePeopleProfile,
  QueryProgramVenues,
  MutationAddPgmComm,
  MutationUpdatePgmComm,
  MutationRemovePgmComm,
  addStatusHistoryToPeople,
  updateStatusHistory,
  removeStatusHistoryFromPeople,
  addTeamMembership,
  removeTeamMembership,
} from '../../graphql/queries/person';

import publicationsByPeople from '../../graphql/queries/publicationsByPeople';

import {
  FETCH_ONE_PERSON,
  FETCH_ONE_PERSON_SUCCESS,
  FETCH_ONE_PERSON_ERROR,
  FETCH_ONE_PERSON_PROJECTS_SUCCESS,
  FETCH_ONE_PERSON_PROJECTS_ERROR,
  SET_PERSON_ROLE_SUCCESS,
  SET_PERSON_ROLE_ERROR,
  CREATE_PERSON_SUCCESS,
  CREATE_PERSON_ERROR,
  MUTATE_PERSON_SUCCESS,
  MUTATE_PERSON_ERROR,
  MUTATE_PERSON_PHD_SUCCESS,
  MUTATE_PERSON_PHD_ERROR,
  MUTATE_PERSON_ALUMNUS_DATA_SUCCESS,
  MUTATE_PERSON_ALUMNUS_DATA_ERROR,
  FETCH_PERSON_INTERNSHIP_SUCCESS,
  FETCH_PERSON_INTERNSHIP_ERROR,
  MUTATE_PERSON_UPDATE_INTERNSHIP_SUCCESS,
  MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR,
  MUTATE_PERSON_ADD_INTERNSHIP_SUCCESS,
  MUTATE_PERSON_ADD_INTERNSHIP_ERROR,
  MUTATE_PERSON_REMOVE_INTERNSHIP_SUCCESS,
  MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR,
  FETCH_PERSON_AWARD,
  fetchPersonAwardError,
  fetchPersonAwardSuccess,
  MUTATE_PERSON_ADD_AWARD,
  mutatePersonAddAwardError,
  mutatePersonAddAwardSuccess,
  MUTATE_PERSON_UPDATE_AWARD,
  mutatePersonUpdateAwardError,
  mutatePersonUpdateAwardSuccess,
  MUTATE_PERSON_REMOVE_AWARD,
  mutatePersonRemoveAwardError,
  mutatePersonRemoveAwardSuccess,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_ERROR,
  FETCH_ZAPS_SUCCESS,
  FETCH_ZAPS_ERROR,
  MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS,
  MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR,
  MUTATE_PERSON_PROFILE_ERROR,
  MUTATE_PERSON_PROFILE_SUCCESS,
  FETCH_ALL_PGMCOMM_VENUES_SUCCESS,
  FETCH_ALL_PGMCOMM_VENUES_ERROR,
  MUTATE_PERSON_UPDATE_PGMCOMM_SUCCESS,
  MUTATE_PERSON_UPDATE_PGMCOMM_ERROR,
  MUTATE_PERSON_ADD_PGMCOMM_SUCCESS,
  MUTATE_PERSON_ADD_PGMCOMM_ERROR,
  MUTATE_PERSON_REMOVE_PGMCOMM_SUCCESS,
  MUTATE_PERSON_REMOVE_PGMCOMM_ERROR,

  ADD_HISTORY_STATUS,
  addHistoryStatusError,
  addHistoryStatusSuccess,
  UPDATE_HISTORY_STATUS,
  updateHistoryStatusError,
  updateHistoryStatusSuccess,
  REMOVE_HISTORY_STATUS,
  removeHistoryStatusError,
  removeHistoryStatusSuccess,

  ADD_MEMBERSHIP,
  addMembershipError,
  addMembershipSuccess,
  REMOVE_MEMBERSHIP,
  removeMembershipError,
  removeMembershipSuccess,
  CREATE_EXTERNAL_PEOPLE_SUCCESS,
  FETCH_PERSON_PUBLICATIONS,
  fetchPersonPublicationsError,
  // eslint-disable-next-line max-len
  fetchPersonPublicationsSuccess, UPLOAD_RESEARCHDATA_IMAGE_SUCCESS, UPLOAD_RESEARCHDATA_IMAGE_ERROR,
} from './personActions';

import { fetchAllPeopleInclExternal } from '../people/peopleActions';

const { addNotification } = store;

// ==== PERSON =================================================================
function* createExternalPeople(action) {
  try {
    const response = yield call(axios.post, '/', { query: mutateCreateExternalPeople(action.data) });
    yield put(CREATE_EXTERNAL_PEOPLE_SUCCESS(response.data.data.createExternalPeople));
    yield put(fetchAllPeopleInclExternal());
    store.addNotification({
      title: 'Saved',
      message: 'Succesfully to create person.',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  } catch (e) {
    store.addNotification({
      title: 'ERROR!',
      message: `Failed to create person. ${e.message}`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* getOnePerson(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryPerson(action.data.id) });
    yield put(FETCH_ONE_PERSON_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_ONE_PERSON_ERROR(e));
  }
}
function* fetchPersonSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ONE_PERSON');
    yield fork(getOnePerson, action);
  }
}
function* setPersonRole(action) {
  try {
    const response = yield call(axios.post, '/', { query: SetPersonRole(action.data.id, action.data.role) });
    yield put(SET_PERSON_ROLE_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'The role is changed!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(SET_PERSON_ROLE_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Role not updated!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePerson(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationPerson(action.data) });
    yield put(MUTATE_PERSON_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* createPerson(action) {
  try {
    const response = yield call(axios.post, '/', { query: CreationPerson(action.data) });
    const { id } = response.data.data.createPeople;
    yield put(CREATE_PERSON_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'The person is created!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
    yield put(push(`/admin/people/${id}`));
  } catch (e) {
    yield put(CREATE_PERSON_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: `The person could not be created! ${e.message}`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* setPersonRoleSagaWatcher() {
  while (true) {
    const action = yield take('SET_PERSON_ROLE');
    yield fork(setPersonRole, action);
  }
}
function* mutatePersonSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON');
    yield fork(mutatePerson, action);
  }
}
function* createPersonSagaWatcher() {
  while (true) {
    const action = yield take('CREATE_PERSON');
    yield fork(createPerson, action);
  }
}
// ==== PROFILE =================================================================
function* mutatePersonProfile(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationUpdatePeopleProfile(action.data) });
    yield put(MUTATE_PERSON_PROFILE_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_PROFILE_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonProfileSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_PROFILE');
    yield fork(mutatePersonProfile, action);
  }
}
// ==== PROJECTS =================================================================
function* getOnePersonProjects(action) {
  try {
    const response = yield call(axios.post, '/', { query: QuerPersonProjects(action.data) });
    yield put(FETCH_ONE_PERSON_PROJECTS_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_ONE_PERSON_PROJECTS_ERROR(e));
  }
}
function* fetchPersonProjectsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ONE_PERSON_PROJECTS');
    yield fork(getOnePersonProjects, action);
  }
}
// ==== PGMCOMM =================================================================
function* mutatePersonAddPgmComm(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationAddPgmComm(action.data) });
    yield put(MUTATE_PERSON_ADD_PGMCOMM_SUCCESS(response.data.data.addProgramCommitteeToPeople));
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_ADD_PGMCOMM_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonRemovePgmComm(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationRemovePgmComm(action.data) });
    yield put(
      MUTATE_PERSON_REMOVE_PGMCOMM_SUCCESS(response.data.data.removeProgramCommitteeFromPeople),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_REMOVE_PGMCOMM_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonUpdatePgmComm(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationUpdatePgmComm(action.data) });
    yield put(
      MUTATE_PERSON_UPDATE_PGMCOMM_SUCCESS(response.data.data.updateProgramCommitteeFromPeople),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_UPDATE_PGMCOMM_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* fetchProgramVenues() {
  try {
    const response = yield call(axios.post, '/', { query: QueryProgramVenues });
    yield put(FETCH_ALL_PGMCOMM_VENUES_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_ALL_PGMCOMM_VENUES_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Program venues not fetched!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* fetchProgramVenuesSagaWatcher() {
  while (true) {
    yield take('FETCH_ALL_PGMCOMM_VENUES');
    yield fork(fetchProgramVenues);
  }
}

function* mutatePersonAddPgmCommSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_ADD_PGMCOMM');
    yield fork(mutatePersonAddPgmComm, action);
  }
}
function* mutatePersonRemovePgmCommSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_REMOVE_PGMCOMM');
    yield fork(mutatePersonRemovePgmComm, action);
  }
}
function* mutatePersonUpdatePgmCommSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_UPDATE_PGMCOMM');
    yield fork(mutatePersonUpdatePgmComm, action);
  }
}

// ==== PHD =================================================================
function* mutatePersonPhd(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationPersonPhDData(action.data) });
    // console.log('MUTATE_PERSON_PHD_SUCCESS', response);
    yield put(MUTATE_PERSON_PHD_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_PHD_ERROR(e));
    // console.log('MUTATE_PERSON_PHD_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonPhdSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_PHD');
    yield fork(mutatePersonPhd, action);
  }
}
// ==== Alumnus =================================================================
function* mutatePersonAlumnus(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationPersonAlumnusData(action.data) });
    yield put(MUTATE_PERSON_ALUMNUS_DATA_SUCCESS(response.data.data));
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_ALUMNUS_DATA_ERROR(e));
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonAlumnusSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_ALUMNUS_DATA');
    yield fork(mutatePersonAlumnus, action);
  }
}
// ==== RESEARCHDATA =================================================================
function* mutatePersonUpdateResearchdata(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationUpdateResearchData(action.data) });
    // console.log('MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS', response);
    yield put(
      MUTATE_PERSON_UPDATE_RESEARCHDATA_SUCCESS(response.data.data.updateResearchDataToPeople),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR(e));
    // console.log('MUTATE_PERSON_UPDATE_RESEARCHDATA_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

function* mutatePersonUpdateResearchdataSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_UPDATE_RESEARCHDATA');
    yield fork(mutatePersonUpdateResearchdata, action);
  }
}

function* uploadResearchDataImage(action) {
  try {
    yield call(axiosClientNoGraphl.post, '/files/uploadResearchPicture', action.data, { headers: { 'content-type': 'multipart/form-data' } });
    yield put(UPLOAD_RESEARCHDATA_IMAGE_SUCCESS());
  } catch (e) {
    yield put(UPLOAD_RESEARCHDATA_IMAGE_ERROR(e));
    addNotification({
      title: 'ERROR!',
      message: `Failed to upload research image: ${e.response.data}`,
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}

export function* uploadResearchImageSagaWatcher() {
  while (true) {
    const action = yield take('UPLOAD_RESEARCHDATA_IMAGE');
    yield fork(uploadResearchDataImage, action);
  }
}

// ==== AWARDS =================================================================
export function* fetchAwardsForPerson(action) {
  const response = yield call(axios.post, '/', { query: QueryPersonAwards(action.data) });
  yield put(fetchPersonAwardSuccess(response.data.data.peopleDetail));
}

export function* addAwardForPerson(action) {
  const response = yield call(axios.post, '/', { query: MutationAddAwardToPeople(action.data) });
  yield put(mutatePersonAddAwardSuccess(response.data.data.addAwardToPeople));
  store.addNotification({
    title: 'Saved!',
    message: 'Your updates are properly saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
export function* removeAwardForPerson(action) {
  const response = yield call(axios.post, '/', { query: MutationRemoveAwardFromPeople(action.data.personid, action.data.awardid) });
  yield put(mutatePersonRemoveAwardSuccess(response.data.data.removeAwardFromPeople));
  store.addNotification({
    title: 'Saved!',
    message: 'Your updates are properly saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
export function* updateAwardForPerson(action) {
  const response = yield call(axios.post, '/', { query: MutationUpdateAward(action.data) });
  yield put(mutatePersonUpdateAwardSuccess(response.data.data.updateAward));
  store.addNotification({
    title: 'Saved!',
    message: 'Your updates are properly saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
// ==== INTERNSHIPS =================================================================
function* mutatePersonAddInternship(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationPersonAddInternship(action.data) });
    yield put(
      MUTATE_PERSON_ADD_INTERNSHIP_SUCCESS(response.data.data.addInternshipOrLongResearchToPeople),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_ADD_INTERNSHIP_ERROR(e));
    // console.log('MUTATE_PERSON_ADD_INTERNSHIP_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* fetchPersonInternship(action) {
  try {
    const response = yield call(axios.post, '/', { query: QueryPersonInternships(action.data.id) });
    yield put(FETCH_PERSON_INTERNSHIP_SUCCESS(response.data.data.getPeople));
  } catch (e) {
    yield put(FETCH_PERSON_INTERNSHIP_ERROR(e));
    // console.log('FETCH_PERSON_INTERNSHIP_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Internship list not fetched!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonUpdateInternship(action) {
  try {
    const response = yield call(axios.post, '/', { query: MutationPersonUpdateInternship(action.data) });
    yield put(
      MUTATE_PERSON_UPDATE_INTERNSHIP_SUCCESS(response.data.data.updateInternshipOrLongResearch),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR(e));
    // console.log('MUTATE_PERSON_UPDATE_INTERNSHIP_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* mutatePersonRemoveInternship(action) {
  try {
    const response = yield call(
      axios.post,
      '/',
      {
        query: MutationPersonRemoveInternship({
          id: action.data.id,
          personid: action.data.personid,
        }),
      },
    );
    yield put(
      MUTATE_PERSON_REMOVE_INTERNSHIP_SUCCESS(
        response.data.data.removeInternshipOrLongResearchFromPeople,
      ),
    );
    store.addNotification({
      title: 'Saved!',
      message: 'Your updates are properly saved!',
      type: 'success',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  } catch (e) {
    yield put(MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR(e));
    // console.log('MUTATE_PERSON_REMOVE_INTERNSHIP_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Data not saved!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* fetchPersonInternshipSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PERSON_INTERNSHIP');
    yield fork(fetchPersonInternship, action);
  }
}
function* mutatePersonAddInternshipSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_ADD_INTERNSHIP');
    yield fork(mutatePersonAddInternship, action);
  }
}
function* mutatePersonUpdateInternshipSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_UPDATE_INTERNSHIP');
    yield fork(mutatePersonUpdateInternship, action);
  }
}
function* mutatePersonRemoveInternshipSagaWatcher() {
  while (true) {
    const action = yield take('MUTATE_PERSON_REMOVE_INTERNSHIP');
    yield fork(mutatePersonRemoveInternship, action);
  }
}

function* mutateCreateExternalPeopleSagaWatcher() {
  while (true) {
    const action = yield take('CREATE_EXTERNAL_PEOPLE');
    yield fork(createExternalPeople, action);
  }
}

// ==== MISC LIST FEEDS =================================================================
function* fetchTeamList() {
  try {
    const response = yield call(axios.post, '/', { query: QueryTeams });
    yield put(FETCH_TEAMS_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_TEAMS_ERROR(e));
    // console.log('FETCH_TEAMS_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'Team list not fetched!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* fetchTeamListSagaWatcher() {
  while (true) {
    yield take('FETCH_TEAMS');
    yield fork(fetchTeamList);
  }
}
function* fetchZAPList() {
  try {
    const response = yield call(axios.post, '/', { query: QueryZapList });
    yield put(FETCH_ZAPS_SUCCESS(response.data.data));
  } catch (e) {
    yield put(FETCH_ZAPS_ERROR(e));
    // console.log('FETCH_ZAPS_ERROR', e);
    store.addNotification({
      title: 'ERROR!',
      message: 'ZAP list not fetched!',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: failedNotificationTimer,
        onScreen: true,
      },
    });
  }
}
function* fetchZAPListSagaWatcher() {
  while (true) {
    yield take('FETCH_ZAPS');
    yield fork(fetchZAPList);
  }
}
// ======================================================================================
export {
  mutateCreateExternalPeopleSagaWatcher,
  fetchPersonSagaWatcher,
  fetchPersonProjectsSagaWatcher,
  setPersonRoleSagaWatcher,
  createPersonSagaWatcher,
  mutatePersonSagaWatcher,
  mutatePersonPhdSagaWatcher,
  mutatePersonAlumnusSagaWatcher,

  fetchPersonInternshipSagaWatcher,
  mutatePersonAddInternshipSagaWatcher,
  mutatePersonUpdateInternshipSagaWatcher,
  mutatePersonRemoveInternshipSagaWatcher,

  fetchTeamListSagaWatcher,
  fetchZAPListSagaWatcher,

  mutatePersonUpdateResearchdataSagaWatcher,

  mutatePersonProfileSagaWatcher,

  fetchProgramVenuesSagaWatcher,
  mutatePersonAddPgmCommSagaWatcher,
  mutatePersonRemovePgmCommSagaWatcher,
  mutatePersonUpdatePgmCommSagaWatcher,
};

export function* addHistoryStatusEntry(action) {
  const query = addStatusHistoryToPeople(action.data, action.data.personId);
  yield call(axios.post, '/', { query });
  yield put(addHistoryStatusSuccess());
  yield put(FETCH_ONE_PERSON({ id: action.data.personId }));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Added entry in status history',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}
export function* updateHistoryStatusEntry(action) {
  const query = updateStatusHistory(action.data);
  yield call(axios.post, '/', { query });
  yield put(updateHistoryStatusSuccess());
  yield put(FETCH_ONE_PERSON({ id: action.data.personId }));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Updated entry in status history',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* addMembershipEntry(action) {
  const query = addTeamMembership(action.personId, action.teamId);
  yield call(axios.post, '/', { query });
  yield put(addMembershipSuccess());
  yield put(FETCH_ONE_PERSON({ id: action.personId }));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Added team membership',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* removeHistoryStatusEntry(action) {
  const query = removeStatusHistoryFromPeople(action.statusId, action.personId);
  yield call(axios.post, '/', { query });
  yield put(removeHistoryStatusSuccess());
  yield put(FETCH_ONE_PERSON({ id: action.personId }));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Entry in status history was removed',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* removeMembershipEntry(action) {
  const query = removeTeamMembership(action.personId, action.teamId);
  yield call(axios.post, '/', { query });
  yield put(removeMembershipSuccess());
  yield put(FETCH_ONE_PERSON({ id: action.personId }));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Removed team membership',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

export function* fetchPublicationsByPeople(action) {
  const response = yield call(axios.post, '/', { query: publicationsByPeople(action.personId) });
  yield put(fetchPersonPublicationsSuccess(response.data.data.publicationsByPeople.publications));
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    ADD_HISTORY_STATUS,
    catchErrors({
      onErrorAction: addHistoryStatusError,
      overrideErrorMessage: 'Unable to create a history status entry',
    })(addHistoryStatusEntry),
  );

  yield takeLatest(
    UPDATE_HISTORY_STATUS,
    catchErrors({
      onErrorAction: updateHistoryStatusError,
      overrideErrorMessage: 'Unable to update a history status entry',
    })(updateHistoryStatusEntry),
  );

  yield takeLatest(
    ADD_MEMBERSHIP,
    catchErrors({
      onErrorAction: addMembershipError,
      overrideErrorMessage: 'Unable to add team',
    })(addMembershipEntry),
  );

  yield takeLatest(
    REMOVE_HISTORY_STATUS,
    catchErrors({
      onErrorAction: removeHistoryStatusError,
      overrideErrorMessage: 'Unable to remove history status entry',
    })(removeHistoryStatusEntry),
  );

  yield takeLatest(
    REMOVE_MEMBERSHIP,
    catchErrors({
      onErrorAction: removeMembershipError,
      overrideErrorMessage: 'Unable to remove team',
    })(removeMembershipEntry),
  );

  yield takeLatest(
    FETCH_PERSON_PUBLICATIONS,
    catchErrors({
      onErrorAction: fetchPersonPublicationsError,
    })(fetchPublicationsByPeople),
  );

  yield takeLatest(
    FETCH_PERSON_AWARD,
    catchErrors({
      onErrorAction: fetchPersonAwardError,
      overrideErrorMessage: 'Person awards list not fetched.',
    })(fetchAwardsForPerson),
  );

  yield takeLatest(
    MUTATE_PERSON_ADD_AWARD,
    catchErrors({
      onErrorAction: mutatePersonAddAwardError,
      overrideErrorMessage: 'Award could not be added',
    })(addAwardForPerson),
  );

  yield takeLatest(
    MUTATE_PERSON_UPDATE_AWARD,
    catchErrors({
      onErrorAction: mutatePersonUpdateAwardError,
      overrideErrorMessage: 'Award could not be updated',
    })(updateAwardForPerson),
  );

  yield takeLatest(
    MUTATE_PERSON_REMOVE_AWARD,
    catchErrors({
      onErrorAction: mutatePersonRemoveAwardError,
      overrideErrorMessage: 'Award could not be removed',
    })(removeAwardForPerson),
  );
}
