import React, { useEffect, useState } from 'react';
import { merge } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import { bookValidationSchema as validationSchema } from '../ValidationSchema';
import { Loader } from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';
import {
  createBook as createBookAction,
  loadBookRequirements as loadBookRequirementsAction,
  updateBook as updateBookAction,
} from './actions';
import {
  selectPublicationBookDetails,
  selectProjects,
  selectIsLoading,
} from './selectors';
import { selectPublicationAuthors } from '../../../../state/publication/selectors';
import { selectAllCountriesAsOptions } from '../../../../state/countries/selectors';
import { calculatePageCount } from '../../../../utils';
import CreateExternalPeopleModal from '../../../../component/admin/people/createExternalPeopleModal';
import { customFilterMatchAll } from '../../../../component/publicationFormatTypes/typeHelper';

const Book = ({
  authors,
  countries,
  createBook,
  isLoading,
  isResearcher,
  loadBookRequirements,
  bookInfo,
  projects,
  updateBook,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  useEffect(() => {
    loadBookRequirements({ id, reset: true });
  }, [id, loadBookRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadBookRequirements({ reset: false });
    }
  }, [reloads, loadBookRequirements]);

  const initialFormValues = merge({
    types: 'book',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    publisher: '',
    volume: '',
    isbn: '',
    pages: '',
    pageCount: 0,
    editors: [],
    country: null,
  }, bookInfo);

  const handleBookSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      editors: values.editors.map((editor) => editor.value),
      type: 'book',
    };

    if (id) {
      updateBook({ ...updatedValues, id });
    } else {
      createBook(updatedValues);
    }
    setSubmitting(false);
  };

  const handlePageChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('pages', value);
    setFieldValue('pageCount', calculatePageCount(value));
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleBookSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="book"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Book specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Publisher</Form.Label>
                          <Form.Control
                            name="publisher"
                            className={touched.publisher && errors.publisher ? 'error' : null}
                            onChange={handleChange}
                            value={values.publisher}
                          />
                          <div className="error-message">
                            <ErrorMessage name="publisher" />
                          </div>
                        </Col>
                        <Col sm={1}>
                          <Form.Label>Volume</Form.Label>
                          <Form.Control
                            type="number"
                            name="volume"
                            onChange={handleChange}
                            value={values.volume}
                            className={touched.volume && errors.volume ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="volume" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>ISBN</Form.Label>
                          <Form.Control
                            name="isbn"
                            onChange={handleChange}
                            value={values.isbn}
                            className={touched.isbn && errors.isbn ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="isbn" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Editor(s)</Form.Label>
                          {' '}
                          <CreateExternalPeopleModal />
                          <Select
                            name="editors"
                            isMulti
                            onChange={(s) => setFieldValue('editors', s)}
                            options={authors}
                            filterOption={customFilterMatchAll}
                            value={values.editors}
                            className={touched.editors && errors.editors ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="editors" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col sm={2}>
                          <Form.Label>Page(s)</Form.Label>
                          <Form.Control
                            name="pages"
                            onChange={(event) => handlePageChange(event, setFieldValue)}
                            value={values.pages}
                            className={touched.pages && errors.pages ? 'error' : null}
                          />
                          <ErrorMessage name="pages" />
                        </Col>
                        <Col sm={2}>
                          <Form.Label>Page Count</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Fill pages..."
                            value={values.pageCount}
                            className={values.pageCount && !parseInt(values.pageCount, 10) ? 'error' : null}
                          />
                          <ErrorMessage name="pageCount" />
                        </Col>
                        <Col>
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            className={touched.country && errors.country ? 'error' : null}
                            onChange={(event) => setFieldValue('country', event.value)}
                            options={countries}
                            value={countries && values.country ? (
                              countries.find((country) => country.value === values.country)
                            ) : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="country" />
                          </div>
                        </Col>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="book"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={bookInfo.visibility}
        />
      )}
    </Container>
  );
};

Book.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  createBook: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadBookRequirements: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bookInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  updateBook: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  authors: selectPublicationAuthors,
  countries: selectAllCountriesAsOptions,
  isLoading: selectIsLoading,
  bookInfo: selectPublicationBookDetails,
  projects: selectProjects,
});

const mapDispatchToProps = {
  createBook: createBookAction,
  loadBookRequirements: loadBookRequirementsAction,
  updateBook: updateBookAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);
