import {
  put, take, fork, call,
} from 'redux-saga/effects';
import axios from '../../axios';
import QueryAllPeopleTypes from '../../graphql/queries/peopleType';
import { FETCH_ALL_PEOPLETYPES_SUCCESS, FETCH_ALL_PEOPLETYPES_ERROR } from './peopleTypeActions';

function* QueryAllPeopleType() {
  try {
    const response = yield call(axios.post, '/', { query: QueryAllPeopleTypes });
    yield put(FETCH_ALL_PEOPLETYPES_SUCCESS(response.data.data.peopleTypes));
  } catch (e) {
    yield put(FETCH_ALL_PEOPLETYPES_ERROR(e));
  }
}

function* fetchPeopleTypesSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_ALL_PEOPLETYPES');
    yield fork(QueryAllPeopleType, action);
  }
}

export default fetchPeopleTypesSagaWatcher;
