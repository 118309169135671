import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Datetime from 'react-datetime';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Formik } from 'formik';
import {
  createEvent as createEventAction,
} from '../../../state/event/eventActions';
import { FETCH_ALL_PEOPLE } from '../../../state/people/peopleActions';
import { fetchAllCountries } from '../../../state/countries/countriesActions';
import validationSchema from './validationSchema';
import { eventTypes } from '../../../config/optionValues';
import { selectUser } from '../../../state/user/selectors';

const EditEvent = (props) => {
  const { id } = useParams();
  const {
    people,
    fetchAllPeople,
    fetchCountries,
    createEvent,
    countriesOptions,
    currentUser,
  } = props;
  useEffect(() => {
    fetchCountries();
    fetchAllPeople();
  }, [fetchCountries, fetchAllPeople]);

  const handleDatetimeChange = (data, fieldName, format, setFieldValue) => {
    /*
      Work-around for `react-datetime`

      Change Formik value using `setFieldValue`.
      See:
      https://stackoverflow.com/questions/54039555/problem-with-saving-data-with-react-datetime-inside-formik-component
    */
    if (typeof (data) === 'string') {
      setFieldValue(fieldName, data);
    } else {
      setFieldValue(fieldName, data.format(format));
    }
  };

  const handleOrganizerChange = (val, setFieldValue) => {
    setFieldValue('organizers', val);
  };

  const displayForm = () => {
    const initialValues = {
      type: '',
      title: '',
      description: '',
      location: '',
      city: '',
      country: '',
      participant: '',
      organizers: [{
        label: `${currentUser.lastname}, ${currentUser.firstname}`,
        value: currentUser.id,
      }],
      date: '',
      endDate: '',
      url: '',
      start: '',
      end: '',
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            ...values,
            organizers: values.organizers.map((d) => d.value),
            id,
          };
          createEvent(data);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.title && errors.title ? 'error' : null}
                  />
                  {touched.title && errors.title ? (<div className="error-message">{errors.title}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <CreatableSelect
                    options={eventTypes}
                    onChange={(e) => setFieldValue('type', e.value)}
                    value={eventTypes.find((t) => t.value === values.type)}
                  />
                  {touched.type && errors.type ? (<div className="error-message">{errors.type}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    defaultValue={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.description && errors.description ? 'error' : null}
                  />
                  {touched.description && errors.description ? (<div className="error-message">{errors.description}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Start date</Form.Label>
                  <Datetime
                    name="date"
                    value={values.date}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    onChange={(data) => handleDatetimeChange(data, 'date', 'YYYY-MM-DD', setFieldValue)}
                    className={touched.date && errors.date ? 'error' : null}
                  />
                  {touched.date && errors.date ? (<div className="error-message">{errors.date}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>End date</Form.Label>
                  <Datetime
                    name="endDate"
                    value={values.endDate}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    onChange={(data) => handleDatetimeChange(data, 'endDate', 'YYYY-MM-DD', setFieldValue)}
                    className={touched.endDate && errors.endDate ? 'error' : null}
                  />
                  {touched.endDate && errors.endDate ? (<div className="error-message">{errors.endDate}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Start Time</Form.Label>
                  <Datetime
                    name="start"
                    value={values.start}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    onChange={(data) => handleDatetimeChange(data, 'start', 'HH:mm', setFieldValue)}
                  />
                  {touched.start && errors.start ? (<div className="error-message">{errors.start}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>End time</Form.Label>
                  <Datetime
                    name="end"
                    value={values.end}
                    dateFormat={false}
                    timeFormat="HH:mm"
                    onChange={(data) => handleDatetimeChange(data, 'end', 'HH:mm', setFieldValue)}
                  />
                  {touched.end && errors.end ? (<div className="error-message">{errors.end}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Participants</Form.Label>
                  <Form.Text muted>
                    An estimate of the number of participants is required for EU project reporting
                  </Form.Text>
                  <Form.Control
                    type="number"
                    min={1}
                    default={1}
                    name="participant"
                    value={values.participant}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.participant && errors.participant ? 'error' : null}
                  />
                  {touched.participant && errors.participant ? (<div className="error-message">{errors.participant}</div>) : null}
                </Form.Group>
              </Col>
              <Col sm={8}>
                <Form.Group>
                  <Form.Label>COSIC organizers</Form.Label>
                  <Select
                    name="organizers"
                    onChange={(val) => handleOrganizerChange(val, setFieldValue)}
                    value={values.organizers}
                    options={people ? (
                      people.map((p) => ({
                        label: `${p.last}, ${p.first}`,
                        value: p.id,
                      }))
                    ) : []}
                    isMulti
                  />
                  {touched.organizers && errors.organizers ? (<div className="error-message">{errors.organizers}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Link to event</Form.Label>
                  <Form.Control
                    name="url"
                    type="url"
                    defaultValue={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    name="location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.location && errors.location ? 'error' : null}
                  />
                  {touched.location && errors.location ? (<div className="error-message">{errors.location}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.city && errors.city ? 'error' : null}
                  />
                  {touched.city && errors.city ? (<div className="error-message">{errors.city}</div>) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Select
                    name="country"
                    onChange={(s) => setFieldValue('country', s.value)}
                    options={countriesOptions}
                    value={countriesOptions.find((country) => country.value === values.country)}
                    defaultValue={countriesOptions.find((country) => (
                      country.value === values.country
                    ))}
                  />
                  {touched.country && errors.country ? (<div className="error-message">{errors.country}</div>) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row style={{ marginTop: '2rem' }}>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Create
              </Button>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>Create Event</Card.Header>
            <Card.Body>
              <div>
                {displayForm()}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

EditEvent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  people: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetchAllPeople: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countriesOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  people: state.people.data,
  countriesOptions: state.publication.countriesOptions,
  currentUser: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  createEvent: (data) => dispatch(createEventAction(data)),
  fetchAllPeople: () => dispatch(FETCH_ALL_PEOPLE()),
  fetchCountries: () => dispatch(fetchAllCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
