import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_ABSTRACT_OF_TALK,
  createAbstractOfTalkError,
  createAbstractOfTalkSuccess,
  FETCH_ABSTRACT_OF_TALK,
  fetchAbstractOfTalkError,
  fetchAbstractOfTalkSuccess,
  LOAD_ABSTRACT_OF_TALK_REQUIREMENTS,
  loadAbstractOfTalkRequirementsError,
  loadAbstractOfTalkRequirementsSuccess,
  UPDATE_ABSTRACT_OF_TALK,
  updateAbstractOfTalkError,
  updateAbstractOfTalkSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createAbstractOfTalk as createAbstractOfTalkMutation,
  QuerytalkArticleById as fetchAbstractOfTalkQuery,
  updateAbstractOfTalk as updateAbstractOfTalkMutation,
} from '../../../../graphql/queries/publications';

export function* createAbstractOfTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: createAbstractOfTalkMutation(payload) });
  yield put(createAbstractOfTalkSuccess());

  const { id } = response.data.data.createTalkArticle;
  yield put(push(`/researcher/publications/updateAbstractOfTalk/${id}`));
  showSuccessMessage('Abstract of talk article created successfully');
}

export function* fetchAbstractOfTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchAbstractOfTalkQuery(payload.id) });
  yield put(fetchAbstractOfTalkSuccess(response.data.data.talkArticleById));
}

export function* updateAbstractOfTalk({ payload }) {
  const response = yield call(axios.post, '/', { query: updateAbstractOfTalkMutation(payload) });
  yield put(updateAbstractOfTalkSuccess(response.data.data.updateTalkArticle));
  showSuccessMessage('Abstract of talk article updated successfully');
}

export function* loadAbstractOfTalk({ payload }) {
  if (payload.id) {
    yield call(fetchAbstractOfTalk, { payload: { id: payload.id } });
  } else if (payload.reset) {
    yield put(loadAbstractOfTalkRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_ABSTRACT_OF_TALK,
    catchErrors({
      onErrorAction: createAbstractOfTalkError,
      overrideErrorMessage: 'Could not create publication of type abstractOfTalk',
    })(createAbstractOfTalk),
  );

  yield takeLatest(
    FETCH_ABSTRACT_OF_TALK,
    catchErrors({
      onErrorAction: fetchAbstractOfTalkError,
      overrideErrorMessage: 'Could not fetch abstractOfTalk',
    })(fetchAbstractOfTalk),
  );

  yield takeLatest(
    LOAD_ABSTRACT_OF_TALK_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadAbstractOfTalkRequirementsError,
    })(loadAbstractOfTalk),
  );

  yield takeLatest(
    UPDATE_ABSTRACT_OF_TALK,
    catchErrors({
      onErrorAction: updateAbstractOfTalkError,
      overrideErrorMessage: 'Could not update publication of type abstractOfTalk',
    })(updateAbstractOfTalk),
  );
}
