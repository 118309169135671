import React from 'react';
import { checkPubType } from './typeHelper';

const renderPublicationsFlat = (pby, canEdit) => {
  const pubsRender = pby.map((pub) => (
    checkPubType(pub.publications, canEdit)
  ));
  return (
    <div>
      <table className="table table-striped">
        <tbody>
          {pubsRender}
        </tbody>
      </table>
    </div>
  );
};

function RenderPublicationsFlat(props) {
  const { pby, canEdit } = props;
  return (
    renderPublicationsFlat(pby, canEdit)
  );
}

export default RenderPublicationsFlat;
