import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationConferencePaper = get('publicationConferencePaper');
export const selectPublicationConferencePaperDetails = createSelector(
  selectPublicationConferencePaper,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationConferencePaper, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationConferencePaperDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
