import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_JOURNAL,
  createJournalError,
  createJournalSuccess,
  FETCH_JOURNAL,
  fetchJournalError,
  fetchJournalSuccess,
  fetchJournal as fetchJournalAction,
  LOAD_JOURNAL_REQUIREMENTS,
  loadJournalRequirementsError,
  loadJournalRequirementsSuccess,
  UPDATE_JOURNAL,
  updateJournalError,
  updateJournalSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createJournalArticle as createJournalMutation,
  QueryjournalArticleById as fetchJournalQuery,
  updateJournalArticle as updateJournalMutation,
} from '../../../../graphql/queries/publications';

export function* createJournal({ payload }) {
  const response = yield call(axios.post, '/', { query: createJournalMutation(payload) });
  yield put(createJournalSuccess());

  const { id } = response.data.data.createJournalArticle;
  yield put(push(`/researcher/publications/updateJournal/${id}`));
  showSuccessMessage('Journal article created successfully');
}

export function* fetchJournal({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchJournalQuery(payload.id) });
  yield put(fetchJournalSuccess(response.data.data.journalArticleById));
}

export function* updateJournal({ payload }) {
  const response = yield call(axios.post, '/', { query: updateJournalMutation(payload) });
  yield put(updateJournalSuccess(response.data.data.updateJournalArticle));
  showSuccessMessage('Journal article updated successfully');
}

export function* loadJournal({ payload }) {
  if (payload.id) {
    yield put(fetchJournalAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadJournalRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_JOURNAL,
    catchErrors({
      onErrorAction: createJournalError,
      overrideErrorMessage: 'Could not create publication of type journal',
    })(createJournal),
  );

  yield takeLatest(
    FETCH_JOURNAL,
    catchErrors({
      onErrorAction: fetchJournalError,
      overrideErrorMessage: 'Could not fetch journal',
    })(fetchJournal),
  );

  yield takeLatest(
    LOAD_JOURNAL_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadJournalRequirementsError,
    })(loadJournal),
  );

  yield takeLatest(
    UPDATE_JOURNAL,
    catchErrors({
      onErrorAction: updateJournalError,
      overrideErrorMessage: 'Could not update publication of type journal',
    })(updateJournal),
  );
}
