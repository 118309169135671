export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_PROJECT_ERROR = 'REMOVE_PROJECT_ERROR';
export const REMOVE_PROJECT_SUCCESS = 'REMOVE_PROJECT_SUCCESS';

export const FETCH_ALL_PROJECTS = () => ({ type: 'FETCH_ALL_PROJECTS' });
export const FETCH_PROJECT_TYPES = () => ({ type: 'FETCH_PROJECT_TYPES' });
export const FETCH_PROJECT_CALLS = () => ({ type: 'FETCH_PROJECT_CALLS' });
export const FETCH_ALL_PROJECTS_SUCCESS = (data) => ({ type: 'FETCH_ALL_PROJECTS_SUCCESS', data });
export const FETCH_ALL_PROJECTS_ERROR = (error) => ({ type: 'FETCH_ALL_PROJECTS_ERROR', error });

export const FETCH_PROJECT = (id) => ({ type: 'FETCH_PROJECT', selectedId: id });
export const FETCH_PROJECT_SUCCESS = (data) => ({ type: 'FETCH_PROJECT_SUCCESS', data });
export const FETCH_PROJECT_ERROR = (error) => ({ type: 'FETCH_PROJECT_ERROR', error });
export const FETCH_PROJECT_TYPES_SUCCESS = (data) => ({ type: 'FETCH_PROJECT_TYPES_SUCCESS', data });
export const FETCH_PROJECT_TYPES_ERROR = (error) => ({ type: 'FETCH_PROJECT_TYPES_ERROR', error });
export const FETCH_PROJECT_CALLS_SUCCESS = (data) => ({ type: 'FETCH_PROJECT_CALLS_SUCCESS', data });
export const FETCH_PROJECT_CALLS_ERROR = (error) => ({ type: 'FETCH_PROJECT_CALLS_ERROR', error });
export const FETCH_PROJECT_WORKPACKETS_SUCCESS = (data) => ({ type: 'FETCH_PROJECT_WORKPACKETS_SUCCESS', data });
export const FETCH_PROJECT_WORKPACKETS_ERROR = (error) => ({ type: 'FETCH_PROJECT_WORKPACKETS_ERROR', error });

export const CLEAR_PROJECT_FORM = () => ({ type: 'CLEAR_PROJECT_FORM' });
export const CLEAR_PROJECT_FILTERS = () => ({ type: 'CLEAR_PROJECT_FILTERS' });
export const SET_PROJECT_FILTERS = (data) => ({ type: 'SET_PROJECT_FILTERS', data });
export const UPDATE_ONE_PROJECT = (data) => ({ type: 'UPDATE_ONE_PROJECT', data });
export const CREATE_ONE_PROJECT = (data) => ({ type: 'CREATE_ONE_PROJECT', data });
export const ADD_WORKPACKETS_TO_PROJECT = (data) => ({ type: 'ADD_WORKPACKETS_TO_PROJECT', data });
export const UPDATE_PROJECT_WORKPACKET = (data) => ({ type: 'UPDATE_PROJECT_WORKPACKET', data });
export const DELETE_PROJECT_WORKPACKET = (id) => ({ type: 'DELETE_PROJECT_WORKPACKET', id });
export const FETCH_PROJECT_WORKPACKETS = (id) => ({ type: 'FETCH_PROJECT_WORKPACKETS', id });

export const FETCH_PROJECT_FILES = (id) => ({ type: 'FETCH_PROJECT_FILES', id });
export const FETCH_PROJECT_FILES_SUCCESS = (data) => ({ type: 'FETCH_PROJECT_FILES_SUCCESS', data });
export const FETCH_PROJECT_FILES_ERROR = (error) => ({ type: 'FETCH_PROJECT_FILES_ERROR', error });

export const POST_PROJECT_FILE = (data) => ({ type: 'POST_PROJECT_FILE', data });
export const POST_PROJECT_FILE_SUCCESS = (data, attribute) => ({ type: 'POST_PROJECT_FILE_SUCCESS', data, attribute });
export const POST_PROJECT_FILE_ERROR = (error, attribute) => ({ type: 'POST_PROJECT_FILE_ERROR', error, attribute });

export const DELETE_PROJECT_FILE = (data) => ({ type: 'DELETE_PROJECT_FILE', data });
export const DELETE_PROJECT_FILE_SUCCESS = (data) => ({ type: 'DELETE_PROJECT_FILE_SUCCESS', data });
export const DELETE_PROJECT_FILE_ERROR = (error) => ({ type: 'DELETE_PROJECT_FILE_ERROR', error });

export const DOWNLOAD_PROJECT_FILE = (data) => ({ type: 'DOWNLOAD_PROJECT_FILE', data });

export const ADD_PROJECT_PARTNER = (data) => ({ type: 'ADD_PROJECT_PARTNER', data });
export const ADD_PROJECT_PARTNER_SUCCESS = (data) => ({ type: 'ADD_PROJECT_PARTNER_SUCCESS', data });
export const ADD_PROJECT_PARTNER_ERROR = (error) => ({ type: 'ADD_PROJECT_PARTNER_ERROR', error });

export const REMOVE_PROJECT_PARTNER = (data) => ({ type: 'REMOVE_PROJECT_PARTNER', data });
export const REMOVE_PROJECT_PARTNER_SUCCESS = (data) => ({ type: 'REMOVE_PROJECT_PARTNER_SUCCESS', data });
export const REMOVE_PROJECT_PARTNER_ERROR = (error) => ({ type: 'REMOVE_PROJECT_PARTNER_ERROR', error });

export const ADD_PROJECT_MEMBER = (data) => ({ type: 'ADD_PROJECT_MEMBER', data });
export const ADD_PROJECT_MEMBER_SUCCESS = (data) => ({ type: 'ADD_PROJECT_MEMBER_SUCCESS', data });
export const ADD_PROJECT_MEMBER_ERROR = (error) => ({ type: 'ADD_PROJECT_MEMBER_ERROR', error });

export const REMOVE_PROJECT_MEMBER = (data) => ({ type: 'REMOVE_PROJECT_MEMBER', data });
export const REMOVE_PROJECT_MEMBER_SUCCESS = (data) => ({ type: 'REMOVE_PROJECT_MEMBER_SUCCESS', data });
export const REMOVE_PROJECT_MEMBER_ERROR = (error) => ({ type: 'REMOVE_PROJECT_MEMBER_ERROR', error });

export const removeProject = (projectId) => ({ type: REMOVE_PROJECT, projectId });
export const removeProjectError = (error) => ({ type: REMOVE_PROJECT_ERROR, error });
export const removeProjectSuccess = () => ({ type: REMOVE_PROJECT_SUCCESS });
