import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  authorNameFormatter, authorNameFormatterText, getYearFromDate, idFormatter, statusFormatter,
} from './typeHelper';
import { ClickText } from '..';
import {
  findPdf,
  pdfIcon,
  shouldShowPublication,
  titleView,
  webVersionIcon,
  webVersionRecordingIcon,
} from '../../utils';
import EditButton from '../EditButton';

function Talk(props) {
  const [isBibTexShown, showBibTex] = useState(false);
  const [isLaTexShown, showLaTex] = useState(false);
  const {
    // eslint-disable-next-line max-len
    id, title, status, event, city, country, from, authors, index, webVersion, webVersionRecording, visibility, files, canEdit, isAuthenticated,
  } = props;

  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const pdfFile = findPdf(files);
  const containsPdf = pdfFile !== undefined;
  const showPublication = shouldShowPublication(isAdmin, containsPdf, visibility);

  const handleBTClick = () => {
    showLaTex(false);
    showBibTex(!isBibTexShown);
  };
  const handleLTClick = () => {
    showBibTex(false);
    showLaTex(!isLaTexShown);
  };
  const bibTex = (`
    @MISC{talk${id},
      author =    {${authors ? authors.map((a) => `${a.first} ${a.last}`).join(' and\n\t\t ') : ''}},
      title =     {${title}},
      howpublished = {${event}, ${city}, ${country}},
      year =      {${getYearFromDate(from)}},
    }
  `);
  const laTex = (`
    \\item ${authorNameFormatterText(authors)}, \`\`${title}'' ${event || ''}, ${city}, ${country}, ${getYearFromDate(from)}
  `);

  return (
    <tr>
      <td>{index}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td>
                {statusFormatter(status)}
                {isAuthenticated && `${idFormatter(id)} `}
                {authorNameFormatter(authors, true)}
                ,
                &quot;
                {titleView(id, title, visibility, pdfFile, showPublication, 'talk', isAdmin)}
                &quot;
                {event ? `, ${event}` : ''}
                {city ? `, ${city}` : ''}
                {country ? `, ${country}` : ''}
                ,
                {' '}
                {getYearFromDate(from)}
                .
                {' '}
                {showPublication && containsPdf
                  ? pdfIcon(id, pdfFile, 'talk')
                  : null}
                {webVersionRecordingIcon(webVersionRecording)}
                {webVersionIcon(webVersion)}
                <EditButton canEdit={canEdit} to={`/researcher/publications/updateTalk/${id}`} />
              </td>
              <td>
                <ClickText text="BibTex" onClick={handleBTClick} />
                {' '}
                <ClickText text="LaTex" onClick={handleLTClick} />
              </td>
              {isBibTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre' }}>
                    {bibTex}
                  </pre>
                </td>
              ) : null}
              {isLaTexShown ? (
                <td style={{ border: '1px solid black' }}>
                  <pre style={{ whiteSpace: 'pre-line' }}>
                    {laTex}
                  </pre>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

Talk.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.any).isRequired,
  webVersion: PropTypes.string,
  webVersionRecording: PropTypes.string,
  visibility: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

Talk.defaultProps = {
  webVersion: '',
  visibility: false,
  webVersionRecording: '',
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});
export default connect(mapStateToProps)(Talk);
