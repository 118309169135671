import { createAction } from 'redux-actions';

export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_ERROR = 'CREATE_REPORT_ERROR';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const LOAD_REPORT_REQUIREMENTS = 'LOAD_REQUIREMENTS_REPORT';
export const LOAD_REPORT_REQUIREMENTS_ERROR = 'LOAD_REPORT_REQUIREMENTS_ERROR';
export const LOAD_REPORT_REQUIREMENTS_SUCCESS = 'LOAD_REPORT_REQUIREMENTS_SUCCESS';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_ERROR = 'UPDATE_REPORT_ERROR';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';

export const createReport = createAction(CREATE_REPORT);
export const createReportError = createAction(CREATE_REPORT_ERROR);
export const createReportSuccess = createAction(CREATE_REPORT_SUCCESS);

export const fetchReport = createAction(FETCH_REPORT);
export const fetchReportError = createAction(FETCH_REPORT_ERROR);
export const fetchReportSuccess = createAction(FETCH_REPORT_SUCCESS);

export const loadReportRequirements = createAction(LOAD_REPORT_REQUIREMENTS);
export const loadReportRequirementsError = createAction(LOAD_REPORT_REQUIREMENTS_ERROR);
export const loadReportRequirementsSuccess = createAction(LOAD_REPORT_REQUIREMENTS_SUCCESS);

export const updateReport = createAction(UPDATE_REPORT);
export const updateReportError = createAction(UPDATE_REPORT_ERROR);
export const updateReportSuccess = createAction(UPDATE_REPORT_SUCCESS);
