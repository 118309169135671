const QueryAllEvents = `
{
  events {
    id
    type
    title
    city
    country
    date
    endDate
    description
    location
    participant
    start
    end
    url
  }
}`;

const queryOneEvent = (id) => `
{
  event (id:${id}){
    id
    people {
      id
      gn
      sn
    }
    type
    title
    description
    location
    city
    country
    participant
    date
    people {
      id
      sn
      gn
    }
    date
    endDate
    start
    end
    url
  }
}`;

const createOneEvent = (data) => `
mutation{
  createEvent(event: {
    organizers: [${data.organizers.join()}]
    type: "${data.type}",
    title: """${data.title}\n"""
    description: """${data.description}\n"""
    location: "${data.location}"
    city: "${data.city}"
    country: "${data.country}"
    participant: "${data.participant}"
    date: "${data.date}"
    endDate: "${data.endDate}"
    url: "${data.url}"
    start: "${data.start}"
    end: "${data.end}"
  }){
    id
    people {
      id
      gn
      sn
    }
    type
    title
    description
    location
    city
    country
    participant
    date
    endDate
    start
    end
    url
  }
}
`;

const updateOneEvent = (data) => `
mutation{
  updateEvent(event: {
    id: ${data.id}
    organizers: [${data.organizers.join()}]
    type: "${data.type}",
    title: """${data.title}\n"""
    description: """${data.description}\n"""
    location: "${data.location}"
    city: "${data.city}"
    country: "${data.country}"
    participant: "${data.participant}"
    date: "${data.date}"
    endDate: "${data.endDate}"
    url: "${data.url}"
    start: "${data.start}"
    end: "${data.end}"
  }){
    id
    people {
      id
      gn
      sn
    }
    type
    title
    description
    location
    city
    country
    participant
    date
    endDate
    start
    end
    url
  }
}
`;

const deleteOneEvent = (eventId) => `
mutation {
  deleteEvent(id:${eventId})
}`;

export default {
  createOneEvent,
  deleteOneEvent,
  updateOneEvent,
  queryOneEvent,
  QueryAllEvents,
};
