import { createAction } from 'redux-actions';

export const CREATE_BOOK = 'CREATE_BOOK';
export const CREATE_BOOK_ERROR = 'CREATE_BOOK_ERROR';
export const CREATE_BOOK_SUCCESS = 'CREATE_BOOK_SUCCESS';
export const FETCH_BOOK = 'FETCH_BOOK';
export const FETCH_BOOK_ERROR = 'FETCH_BOOK_ERROR';
export const FETCH_BOOK_SUCCESS = 'FETCH_BOOK_SUCCESS';
export const LOAD_BOOK_REQUIREMENTS = 'LOAD_REQUIREMENTS_BOOK';
export const LOAD_BOOK_REQUIREMENTS_ERROR = 'LOAD_BOOK_REQUIREMENTS_ERROR';
export const LOAD_BOOK_REQUIREMENTS_SUCCESS = 'LOAD_BOOK_REQUIREMENTS_SUCCESS';
export const UPDATE_BOOK = 'UPDATE_BOOK';
export const UPDATE_BOOK_ERROR = 'UPDATE_BOOK_ERROR';
export const UPDATE_BOOK_SUCCESS = 'UPDATE_BOOK_SUCCESS';

export const createBook = createAction(CREATE_BOOK);
export const createBookError = createAction(CREATE_BOOK_ERROR);
export const createBookSuccess = createAction(CREATE_BOOK_SUCCESS);

export const fetchBook = createAction(FETCH_BOOK);
export const fetchBookError = createAction(FETCH_BOOK_ERROR);
export const fetchBookSuccess = createAction(FETCH_BOOK_SUCCESS);

export const loadBookRequirements = createAction(LOAD_BOOK_REQUIREMENTS);
export const loadBookRequirementsError = createAction(LOAD_BOOK_REQUIREMENTS_ERROR);
export const loadBookRequirementsSuccess = createAction(LOAD_BOOK_REQUIREMENTS_SUCCESS);

export const updateBook = createAction(UPDATE_BOOK);
export const updateBookError = createAction(UPDATE_BOOK_ERROR);
export const updateBookSuccess = createAction(UPDATE_BOOK_SUCCESS);
