import { createAction } from 'redux-actions';

export const CREATE_PATENT = 'CREATE_PATENT';
export const CREATE_PATENT_ERROR = 'CREATE_PATENT_ERROR';
export const CREATE_PATENT_SUCCESS = 'CREATE_PATENT_SUCCESS';
export const FETCH_PATENT = 'FETCH_PATENT';
export const FETCH_PATENT_ERROR = 'FETCH_PATENT_ERROR';
export const FETCH_PATENT_SUCCESS = 'FETCH_PATENT_SUCCESS';
export const LOAD_PATENT_REQUIREMENTS = 'LOAD_REQUIREMENTS_PATENT';
export const LOAD_PATENT_REQUIREMENTS_ERROR = 'LOAD_PATENT_REQUIREMENTS_ERROR';
export const LOAD_PATENT_REQUIREMENTS_SUCCESS = 'LOAD_PATENT_REQUIREMENTS_SUCCESS';
export const UPDATE_PATENT = 'UPDATE_PATENT';
export const UPDATE_PATENT_ERROR = 'UPDATE_PATENT_ERROR';
export const UPDATE_PATENT_SUCCESS = 'UPDATE_PATENT_SUCCESS';

export const createPatent = createAction(CREATE_PATENT);
export const createPatentError = createAction(CREATE_PATENT_ERROR);
export const createPatentSuccess = createAction(CREATE_PATENT_SUCCESS);

export const fetchPatent = createAction(FETCH_PATENT);
export const fetchPatentError = createAction(FETCH_PATENT_ERROR);
export const fetchPatentSuccess = createAction(FETCH_PATENT_SUCCESS);

export const loadPatentRequirements = createAction(LOAD_PATENT_REQUIREMENTS);
export const loadPatentRequirementsError = createAction(LOAD_PATENT_REQUIREMENTS_ERROR);
export const loadPatentRequirementsSuccess = createAction(LOAD_PATENT_REQUIREMENTS_SUCCESS);

export const updatePatent = createAction(UPDATE_PATENT);
export const updatePatentError = createAction(UPDATE_PATENT_ERROR);
export const updatePatentSuccess = createAction(UPDATE_PATENT_SUCCESS);
