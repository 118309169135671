import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationBookchapter = get('publicationBookchapter');
export const selectPublicationBookchapterDetails = createSelector(
  selectPublicationBookchapter,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationBookchapter, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationBookchapterDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
