import React from 'react';
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';

function EntityNotFound() {
  const { state } = useLocation();
  const { entity, id } = state || { entity: 'entry', id: undefined };
  const idMsg = `(with id: ${id})`;
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="error-template text-center">
            <h3>{`Error fetching ${entity ? _.capitalize(entity) : 'Data'}`}</h3>

            <div className="error-details">
              {`Unexpected error fetching ${entity || 'data'} ${id ? idMsg : ''}, fetching data failed or it does not exist.`}
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EntityNotFound;
