import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Table, Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Loader } from '../../index';
import {
  FETCH_ALL_PGMCOMM_VENUES,
  MUTATE_PERSON_ADD_PGMCOMM,
  MUTATE_PERSON_REMOVE_PGMCOMM,
  MUTATE_PERSON_UPDATE_PGMCOMM,
} from '../../../state/person/personActions';

const EditPeopleCommitteeForm = (props) => {
  const {
    loading,
    personid,
    programCommittees,
    mutateAddPgmComm,
    mutateUpdatePgmComm,
    mutateRemovePgmComm,
    programVenues,
    fetchProgramVenues,
  } = props;
  useEffect(() => {
    fetchProgramVenues();
  }, [fetchProgramVenues]);

  const SignupSchema = Yup.object().shape({
    id: Yup.string(),
  });

  const [entryIdToDelete, setEntryIdToDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const handleShowDelete = (idx) => {
    setEntryIdToDelete(idx);
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    setEntryIdToDelete(null);
  };
  const handleDelete = () => {
    mutateRemovePgmComm({ id: programCommittees[entryIdToDelete].id, personid });
    handleCloseDelete();
  };
  const handleVenueChange = (venue, setFieldValue) => {
    setFieldValue('venue', venue.value);
  };

  const wpModalFormDelete = () => (
    <Modal
      show={showDelete}
      onHide={handleCloseDelete}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Program Committee entry?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div style={{ display: 'inline-block', fontWeight: 'bold', minWidth: '10rem' }}>Venue: </div>
          { (entryIdToDelete !== null) ? programCommittees[entryIdToDelete].venueName : ''}
          {' ('}
          { (entryIdToDelete !== null) ? programCommittees[entryIdToDelete].type : ''}
          ')'
        </div>
        <div>
          <div style={{ display: 'inline-block', fontWeight: 'bold', minWidth: '10rem' }}>Year: </div>
          { (entryIdToDelete !== null) ? programCommittees[entryIdToDelete].year : ''}
        </div>
        <div>
          <div style={{ display: 'inline-block', fontWeight: 'bold', minWidth: '10rem' }}>Role: </div>
          { (entryIdToDelete !== null) ? programCommittees[entryIdToDelete].role : ''}
        </div>
        <div>
          <div style={{ display: 'inline-block', fontWeight: 'bold', minWidth: '10rem' }}>Location: </div>
          { (entryIdToDelete !== null) ? programCommittees[entryIdToDelete].location : ''}
        </div>
        <div style={{
          marginTop: '1rem', color: 'Red', fontWeight: 'bold', fontSize: '1.4rem',
        }}
        >
          This action is permanent!
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDelete}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const handleCloseEdit = () => {
    setShow(false);
  };

  const [inputVals, setInputVals] = useState({
    personid,
    id: -1,
    venue: '',
    year: '',
    location: '',
    role: '',
  });
  const selectARow = (idx) => {
    if (idx === -1) {
      setInputVals({
        personid,
        id: -1,
        venue: '',
        year: '',
        location: '',
        role: '',
      });
    } else {
      setInputVals({
        personid,
        id: programCommittees[idx].id,
        venue: JSON.stringify({
          id: programCommittees[idx].venue,
          type: programCommittees[idx].type,
        }),
        year: programCommittees[idx].year,
        location: programCommittees[idx].location,
        role: programCommittees[idx].role,
      });
    }
    setShow(true);
  };

  const venuesOptions = programVenues.map(
    (venue) => (
      {
        value: JSON.stringify({ id: venue.id, type: venue.type }),
        key: `programVenues_${venue.id}_${venue.type}`,
        label: `${venue.name} (${venue.type})`,
      }
    ),
  );

  const yearsDropdown = () => {
    const beginYear = 1970;
    const endYear = new Date().getFullYear();
    const result = [];
    for (let y = beginYear; y <= endYear; y += 1) {
      result.push(y);
    }
    return result;
  };

  const displayForm = () => (
    <Formik
      initialValues={inputVals}
      enableReinitialize
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.id === -1) {
          mutateAddPgmComm({ ...values, venue: JSON.parse(values.venue) });
        } else {
          mutateUpdatePgmComm({ ...values, venue: JSON.parse(values.venue) });
        }
        setSubmitting(false);
        handleCloseEdit();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Venue</Form.Label>
              <Select
                name="venue"
                options={venuesOptions}
                onChange={(val) => handleVenueChange(val, setFieldValue)}
                value={venuesOptions.find(
                  (v) => v.value === values.venue,
                )}
                className={touched.venue && errors.venue ? 'error' : null}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Year</Form.Label>
              <Form.Control
                as="select"
                name="year"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.year}
                className="form-control"
              >
                <option value={null} />
                {
                  yearsDropdown().map((year) => <option value={year}>{year}</option>)
                }
              </Form.Control>
              {
                errors.year
                && touched.year
                && errors.year
              }
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.location}
                className="form-control"
              />
              {
                errors.location
                && touched.location
                && errors.location
              }
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                className="form-control"
              >
                <option />
                <option>General chair</option>
                <option>Program chair</option>
                <option>Committee member</option>
                <option>Member of editorial board of a journal</option>
                <option>Other</option>
              </Form.Control>
              {
                errors.role
                && touched.role
                && errors.role
              }
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Button
                type="submit"
                className="btn btn-success"
                style={{ width: '100%' }}
                disabled={
                  isSubmitting || !values.role || !values.location || !values.venue || !values.year
                }
              >
                Save
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );
  const wpModalFormEdit = () => (
    <Modal
      show={show}
      onHide={handleCloseEdit}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>{displayForm()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Venue</th>
                  <th>Year</th>
                  <th>Location</th>
                  <th>Role</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                { programCommittees.map((el, idx) => (
                  <tr key={el.id}>
                    <td>
                      {el.venueName}
                      {' ('}
                      {el.type}
                      ')'
                    </td>
                    <td>{el.year}</td>
                    <td>{el.location}</td>
                    <td>{el.role}</td>
                    <td>
                      <Button className="btn btn-primary fixed-size-button" onClick={() => selectARow(idx)}>
                        Edit
                      </Button>
                      <Button className="btn btn-danger fixed-size-button" onClick={() => handleShowDelete(idx)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <Button className="btn btn-primary fixed-size-button" onClick={() => selectARow(-1)}>
                      New
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {wpModalFormDelete()}
            {wpModalFormEdit()}
          </>
        )}
    </div>
  );
};

EditPeopleCommitteeForm.propTypes = {
  loading: PropTypes.bool,
  personid: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  programCommittees: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  programVenues: PropTypes.arrayOf(PropTypes.any).isRequired,
  mutateAddPgmComm: PropTypes.func.isRequired,
  mutateUpdatePgmComm: PropTypes.func.isRequired,
  mutateRemovePgmComm: PropTypes.func.isRequired,
  fetchProgramVenues: PropTypes.func.isRequired,
};

EditPeopleCommitteeForm.defaultProps = {
  loading: false,
  programCommittees: [],
  personid: '-1',
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  personid: state.person.data.id,
  programVenues: state.person.programVenues,
  programCommittees: state.person.data.programCommittees,
});

const mapDispatchToProps = (dispatch) => ({
  mutateAddPgmComm: (data) => dispatch(MUTATE_PERSON_ADD_PGMCOMM(data)),
  mutateUpdatePgmComm: (data) => dispatch(MUTATE_PERSON_UPDATE_PGMCOMM(data)),
  mutateRemovePgmComm: (data) => dispatch(MUTATE_PERSON_REMOVE_PGMCOMM(data)),
  fetchProgramVenues: () => dispatch(FETCH_ALL_PGMCOMM_VENUES()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleCommitteeForm);
