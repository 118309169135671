import {
  DELETE_COUNTRY,
  DELETE_COUNTRY_ERROR,
  DELETE_COUNTRY_SUCCESS,
  FETCH_ALL_COUNTRIES,
  FETCH_ALL_COUNTRIES_ERROR,
  FETCH_ALL_COUNTRIES_SUCCESS,
  SET_COUNTRY_FILTER_CODE,
  SET_COUNTRY_FILTER_NAME,
} from './countriesActions';

const defaultState = {
  isLoading: false,
  data: [],
  error: '',
  filter: {
    name: '',
    code: '',
  },
};

const countries = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_COUNTRIES:
      return { ...state, isLoading: true };
    case FETCH_ALL_COUNTRIES_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ALL_COUNTRIES_ERROR:
      return { ...state, data: action.error, isLoading: false };

    case SET_COUNTRY_FILTER_NAME:
      return { ...state, filter: { name: action.data, code: state.filter.code }, isLoading: false };
    case SET_COUNTRY_FILTER_CODE:
      return { ...state, filter: { name: state.filter.name, code: action.data }, isLoading: false };

    case DELETE_COUNTRY:
      return { ...state, isLoading: true };
    case DELETE_COUNTRY_SUCCESS:
      return { ...state, isLoading: false };
    case DELETE_COUNTRY_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default countries;
