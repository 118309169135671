import { put, call, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  MutateDeleteCountry,
  QueryAllCountries,
} from '../../graphql/queries/country';
import catchErrors from '../../graphql/sagas';
import {
  DELETE_COUNTRY,
  deleteCountryError,
  deleteCountrySuccess,
  FETCH_ALL_COUNTRIES,
  fetchAllCountries,
  fetchAllCountriesError,
  fetchAllCountriesSuccess,
} from './countriesActions';
import {
  FETCH_PUBLICATION_COUNTRIES_SUCCESS,
} from '../publication/publicationActions';

export function* getAllCountries() {
  const response = yield call(axios.post, '/', { query: QueryAllCountries });
  yield put(fetchAllCountriesSuccess(response.data.data.countries));
  yield put(FETCH_PUBLICATION_COUNTRIES_SUCCESS(response.data.data.countries));
}

export function* deleteCountry(action) {
  const response = yield call(axios.post, '/', { query: MutateDeleteCountry(action.countryId) });
  if (response.data.data.deleteCountry === false) {
    throw new Error('Country could not be deleted');
  }
  yield put(fetchAllCountries());
  yield put(deleteCountrySuccess(response.data));
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Country deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    DELETE_COUNTRY,
    catchErrors({
      onErrorAction: deleteCountryError,
      overrideErrorMessage: 'Unable to delete the country',
    })(deleteCountry),
  );

  yield takeLatest(
    FETCH_ALL_COUNTRIES,
    catchErrors({
      onErrorAction: fetchAllCountriesError,
      overrideErrorMessage: 'Unable to retrieve entry',
    })(getAllCountries),
  );
}
