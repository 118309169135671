import {
  put, take, fork, call,
} from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { QueryAllDatesAndPeople } from '../../graphql/queries';
import {
  INIT_DATA_SUCCESS,
  INIT_DATA_ERROR,
  FETCH_USER_ID_SUCCESS,
  FETCH_USER_ID_ERROR, INIT_DATA,
  USER_NOT_AUTHENTICATED,
} from './appActions';
import axios from '../../axios';
import { setUserDetails, setUserToken } from '../user/appActions';
// import { getUserIdFromHeader } from '../../service/authService';

function* fetchInitialData() {
  try {
    const response = yield call(axios.post, '/', { query: QueryAllDatesAndPeople });
    yield put(INIT_DATA_SUCCESS(response.data.data));
  } catch (e) {
    yield put(INIT_DATA_ERROR(e));
  }
}

// DO NOT TOUCH
function* fetchUserIdAndToken() {
  try {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (Date.now() >= decoded.exp * 1000) {
        // Expired
        yield put(USER_NOT_AUTHENTICATED());
      } else {
        decoded.permissions = JSON.parse(decoded.permissions);
        const { userid } = decoded;
        yield put(FETCH_USER_ID_SUCCESS({ userId: userid }));
        yield put(setUserToken(token));
        yield put(setUserDetails(decoded));
      }
    } else {
      yield put(USER_NOT_AUTHENTICATED());
    }
    yield put(INIT_DATA());
  } catch (e) {
    yield put(USER_NOT_AUTHENTICATED());
    yield put(FETCH_USER_ID_ERROR(e));
  }
}

export function* fetchDatesSagaWatcher() {
  while (true) {
    const action = yield take('INIT_DATA');
    yield fork(fetchInitialData, action);
  }
}

export function* fetchUserIdAndTokenSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_USER_ID');
    yield fork(fetchUserIdAndToken, action);
  }
}
