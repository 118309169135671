import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const EditButton = ({
  canEdit, to,
}) => (
  canEdit ? (
    <Link
      to={to}
      className="btn btn-primary"
      role="button"
      style={{
        fontSize: '.8em',
        padding: '.0rem .4rem',
      }}
    >
      Edit...
    </Link>
  ) : null
);

EditButton.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};

export default EditButton;
