import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

export const selectPublicationReport = get('publicationReport');
export const selectPublicationReportDetails = createSelector(
  selectPublicationReport,
  get('details'),
);

export const selectIsLoading = createSelector(selectPublicationReport, get('isLoading'));
export const selectProjects = createSelector(
  selectPublicationReportDetails,
  (publication) => (publication.projects ? publication.projects : []),
);
