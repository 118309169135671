const QueryAllCommitteeMembers = `
{
  allPhdCommitee {
    id
    commiteeMember {
      id
      gn
      sn
    }
  }
}`;

export default QueryAllCommitteeMembers;
