import {
  put, take, fork, call,
} from 'redux-saga/effects';

import axios from '../../axios';
import {
  FETCH_CONFERENCE_BY_YEAR_SUCCESS,
  FETCH_CONFERENCE_BY_YEAR_ERROR,
  FETCH_JOURNAL_BY_YEAR_ERROR,
  FETCH_JOURNAL_BY_YEAR_SUCCESS,
  FETCH_STATUS_HISTORY_COUNT_BY_YEAR_SUCCESS,
  FETCH_STATUS_HISTORY_COUNT_BY_YEAR_ERROR,
  FETCH_BEST_PAPER_BY_YEAR_ERROR,
  FETCH_BEST_PAPER_BY_YEAR_SUCCESS,
  FETCH_AWARDS_BY_YEAR_ERROR,
  FETCH_AWARDS_BY_YEAR_SUCCESS,
  FETCH_PROJECTS_RATE_ERROR,
  FETCH_PROJECTS_RATE_SUCCESS,
  FETCH_GRANT_RATE_ERROR,
  FETCH_GRANT_RATE_SUCCESS,
  FETCH_BILATERAL_RATE_ERROR,
  FETCH_BILATERAL_RATE_SUCCESS,
  FETCH_TOTAL_RATE_ERROR,
  FETCH_TOTAL_RATE_SUCCESS,
  FETCH_LOCATION_RATE_ERROR,
  FETCH_LOCATION_RATE_SUCCESS,
  FETCH_FUNDER_RATE_SUCCESS,
  FETCH_FUNDER_RATE_ERROR,
} from './dashboardActions';

import {
  conferencesByYear,
  journalsByYear,
  statusHistoryCountByYear,
  bestPapersByYear,
  awardsWonByYear,
  projectSuccessRate,
  grantSuccessRate,
  bilateralSuccessRate,
  totalSuccessRate,
  projectLocation,
  projectFunder,
} from '../../graphql/queries/dashboard';

function* getJournalByYear(data) {
  try {
    const response = yield call(axios.post, '/', { query: journalsByYear(data.data) });
    yield put(FETCH_JOURNAL_BY_YEAR_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_JOURNAL_BY_YEAR_ERROR(e));
  }
}

export function* fetchJournalsSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_JOURNAL_BY_YEAR');
    yield fork(getJournalByYear, action);
  }
}

function* getConferenceByYear(data) {
  try {
    const response = yield call(axios.post, '/', { query: conferencesByYear(data.data) });
    yield put(FETCH_CONFERENCE_BY_YEAR_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_CONFERENCE_BY_YEAR_ERROR(e));
  }
}

export function* fetchConferenceSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_CONFERENCE_BY_YEAR');
    yield fork(getConferenceByYear, action);
  }
}

function* getStatusHistoryCountByYear(data) {
  try {
    const response = yield call(axios.post, '/', { query: statusHistoryCountByYear(data.data) });
    yield put(FETCH_STATUS_HISTORY_COUNT_BY_YEAR_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_STATUS_HISTORY_COUNT_BY_YEAR_ERROR(e));
  }
}

export function* fetchStatusHistoryCountByYearSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_STATUS_HISTORY_COUNT_BY_YEAR');
    yield fork(getStatusHistoryCountByYear, action);
  }
}

function* getBestPaperByYear(data) {
  try {
    const response = yield call(axios.post, '/', { query: bestPapersByYear(data.data) });
    yield put(FETCH_BEST_PAPER_BY_YEAR_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_BEST_PAPER_BY_YEAR_ERROR(e));
  }
}

export function* fetchBestPaperByYearSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_BEST_PAPER_BY_YEAR');
    yield fork(getBestPaperByYear, action);
  }
}

function* getAwardsByYear(data) {
  try {
    const response = yield call(axios.post, '/', { query: awardsWonByYear(data.data) });
    yield put(FETCH_AWARDS_BY_YEAR_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_AWARDS_BY_YEAR_ERROR(e));
  }
}

export function* fetchAwardsByYearSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_AWARDS_BY_YEAR');
    yield fork(getAwardsByYear, action);
  }
}

function* getProjectSuccessRate() {
  try {
    const response = yield call(axios.post, '/', { query: projectSuccessRate() });
    yield put(FETCH_PROJECTS_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_PROJECTS_RATE_ERROR(e));
  }
}

export function* fetchProjectSuccessRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_PROJECTS_RATE');
    yield fork(getProjectSuccessRate, action);
  }
}

function* getGrantSuccessRate() {
  try {
    const response = yield call(axios.post, '/', { query: grantSuccessRate() });
    yield put(FETCH_GRANT_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_GRANT_RATE_ERROR(e));
  }
}

export function* fetchGrantSuccessRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_GRANT_RATE');
    yield fork(getGrantSuccessRate, action);
  }
}

function* getBilateralSuccessRate() {
  try {
    const response = yield call(axios.post, '/', { query: bilateralSuccessRate() });
    yield put(FETCH_BILATERAL_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_BILATERAL_RATE_ERROR(e));
  }
}

export function* fetchBilateralSuccessRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_BILATERAL_RATE');
    yield fork(getBilateralSuccessRate, action);
  }
}

function* getTotalSuccessRate() {
  try {
    const response = yield call(axios.post, '/', { query: totalSuccessRate() });
    yield put(FETCH_TOTAL_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_TOTAL_RATE_ERROR(e));
  }
}

export function* fetchTotalSuccessRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_TOTAL_RATE');
    yield fork(getTotalSuccessRate, action);
  }
}

function* getProjectLocationRate() {
  try {
    const response = yield call(axios.post, '/', { query: projectLocation() });
    yield put(FETCH_LOCATION_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_LOCATION_RATE_ERROR(e));
  }
}

export function* fetchProjectLocationRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_LOCATION_RATE');
    yield fork(getProjectLocationRate, action);
  }
}

function* getProjectFunderRate() {
  try {
    const response = yield call(axios.post, '/', { query: projectFunder() });
    yield put(FETCH_FUNDER_RATE_SUCCESS(response.data));
  } catch (e) {
    yield put(FETCH_FUNDER_RATE_ERROR(e));
  }
}

export function* fetchProjectFunderRateSagaWatcher() {
  while (true) {
    const action = yield take('FETCH_FUNDER_RATE');
    yield fork(getProjectFunderRate, action);
  }
}
