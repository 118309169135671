import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  QueryOneProjectCall,
  MutateCreateProjectCall,
  MutateUpdateProjectCall,
} from '../../graphql/queries/projectCalls';
import {
  FETCH_ONE_PROJECT_CALL,
  fetchOneProjectCallError,
  fetchOneProjectCallSuccess,
  UPDATE_PROJECT_CALL,
  updateProjectCallError,
  updateProjectCallSuccess,
} from './projectCallActions';
import catchErrors from '../../graphql/sagas';

export function* getOneProjectCall(action) {
  if (action.data.id) {
    const response = yield call(axios.post, '/', { query: QueryOneProjectCall(action.data.id) });
    yield put(fetchOneProjectCallSuccess(response.data.data.call));
  } else {
    yield put(fetchOneProjectCallSuccess({
      id: null,
      name: '',
      parent: null,
    }));
  }
}

export function* updateProjectCall(action) {
  let query;

  if (action.data.id) {
    query = MutateUpdateProjectCall(action.data);
  } else {
    query = MutateCreateProjectCall(action.data);
  }

  yield call(axios.post, '/', { query });
  yield put(updateProjectCallSuccess());
  yield put(push('/admin/settings/projectCalls'));

  store.addNotification({
    title: 'SUCCESS!',
    message: 'Update successfully saved!',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    FETCH_ONE_PROJECT_CALL,
    catchErrors({
      onErrorAction: fetchOneProjectCallError,
      overrideErrorMessage: 'Unable to retrieve Project Call',
    })(getOneProjectCall),
  );

  yield takeLatest(
    UPDATE_PROJECT_CALL,
    catchErrors({
      onErrorAction: updateProjectCallError,
      overrideErrorMessage: 'Unable to update Project Call',
      includeTechnicalError: true,
    })(updateProjectCall),
  );
}
