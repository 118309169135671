import { put, call, takeLatest } from 'redux-saga/effects';
import { store } from 'react-notifications-component';
import axios from '../../axios';
import {
  MutateDeleteFunder,
  QueryAllFunders,
} from '../../graphql/queries/funders';
import {
  DELETE_FUNDER,
  deleteFunderError,
  deleteFunderSuccess,
  FETCH_ALL_FUNDERS,
  fetchAllFunders,
  fetchAllFundersError,
  fetchAllFundersSuccess,
} from './fundersActions';
import catchErrors from '../../graphql/sagas';

export function* getAllFunders() {
  const response = yield call(axios.post, '/', { query: QueryAllFunders });
  yield put(fetchAllFundersSuccess(response.data.data.funders));
}

export function* deleteFunder(action) {
  const response = yield call(axios.post, '/', { query: MutateDeleteFunder(action.funderId) });
  if (response.data.data.deleteFunder === false) {
    throw new Error('Funder could not be deleted');
  }

  yield put(deleteFunderSuccess(response.data));
  yield put(fetchAllFunders());
  store.addNotification({
    title: 'SUCCESS!',
    message: 'Funder deleted successfully',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    DELETE_FUNDER,
    catchErrors({
      onErrorAction: deleteFunderError,
      overrideErrorMessage: 'Unable to delete funder',
    })(deleteFunder),
  );

  yield takeLatest(
    FETCH_ALL_FUNDERS,
    catchErrors({
      onErrorAction: fetchAllFundersError,
      overrideErrorMessage: 'Unable to retrieve funders',
    })(getAllFunders),
  );
}
