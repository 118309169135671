import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import axios from '../../../../axios';
import catchErrors, { showSuccessMessage } from '../../../../graphql/sagas';
import {
  CREATE_PROCEEDING,
  createProceedingError,
  createProceedingSuccess,
  FETCH_PROCEEDING,
  fetchProceedingError,
  fetchProceedingSuccess,
  fetchProceeding as fetchProceedingAction,
  LOAD_PROCEEDING_REQUIREMENTS,
  loadProceedingRequirementsError,
  loadProceedingRequirementsSuccess,
  UPDATE_PROCEEDING,
  updateProceedingError,
  updateProceedingSuccess,
} from './actions';
import {
  ensurePeople,
  ensureProceedings,
  ensureTeams,
} from '../../../../state/publication/publicationSaga';
import {
  getAllCountries,
} from '../../../../state/countries/countriesSaga';
import {
  createProceeding as createProceedingMutation,
  QueryproceedingById as fetchProceedingQuery,
  updateProceeding as updateProceedingMutation,
} from '../../../../graphql/queries/publications';

export function* createProceeding({ payload }) {
  const response = yield call(axios.post, '/', { query: createProceedingMutation(payload) });
  yield put(createProceedingSuccess());

  const { id } = response.data.data.createProceeding;
  yield put(push(`/researcher/publications/updateProceeding/${id}`));
  showSuccessMessage('Proceeding created successfully');
}

export function* fetchProceeding({ payload }) {
  const response = yield call(axios.post, '/', { query: fetchProceedingQuery(payload.id) });
  yield put(fetchProceedingSuccess(response.data.data.proceedingById));
}

export function* updateProceeding({ payload }) {
  const response = yield call(axios.post, '/', { query: updateProceedingMutation(payload) });
  yield put(updateProceedingSuccess(response.data.data.updateProceeding));
  showSuccessMessage('Proceeding updated successfully');
}

export function* loadProceeding({ payload }) {
  if (payload.id) {
    yield put(fetchProceedingAction({ id: payload.id }));
  } else if (payload.reset) {
    yield put(loadProceedingRequirementsSuccess());
  }

  yield all([
    call(ensureTeams),
    call(ensurePeople),
    call(getAllCountries),
    call(ensureProceedings),
  ]);
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(
    CREATE_PROCEEDING,
    catchErrors({
      onErrorAction: createProceedingError,
      overrideErrorMessage: 'Could not create publication of type proceeding',
      includeTechnicalError: true,
    })(createProceeding),
  );

  yield takeLatest(
    FETCH_PROCEEDING,
    catchErrors({
      onErrorAction: fetchProceedingError,
      overrideErrorMessage: 'Could not fetch proceeding',
    })(fetchProceeding),
  );

  yield takeLatest(
    LOAD_PROCEEDING_REQUIREMENTS,
    catchErrors({
      onErrorAction: loadProceedingRequirementsError,
    })(loadProceeding),
  );

  yield takeLatest(
    UPDATE_PROCEEDING,
    catchErrors({
      onErrorAction: updateProceedingError,
      overrideErrorMessage: 'Could not update publication of type proceeding',
      includeTechnicalError: true,
    })(updateProceeding),
  );
}
