import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Loader from '../../../component/loader/loader';
import {
  SET_PUBLICATION_FILTER_PUBLICATIONTYPES,
  SET_PUBLICATION_FILTER_YEARS,
} from '../../../state/publication/publicationActions';
import { fetchPersonPublications } from '../../../state/person/personActions';
import RenderByPerson from '../../../component/publicationFormatTypes/renderPublicationsForPerson';
import {
  selectPersonPublications,
} from '../../../state/person/selectors';

const MyPublications = (props) => {
  const {
    id,
    loading,
    fetchPublicationsByPerson,
    publications,
  } = props;

  useEffect(() => {
    fetchPublicationsByPerson(id);
  }, [fetchPublicationsByPerson, id]);

  return (
    <Container fluid>
      {loading
        ? <Loader centered />
        : (
          <Row>
            <Col>
              <Card>
                {
                    publications ? (
                      <RenderByPerson publications={publications} />
                    ) : <div />
                  }
              </Card>
            </Col>
          </Row>
        )}
    </Container>
  );
};

MyPublications.propTypes = {
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchPublicationsByPerson: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  publications: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  years: state.app.years,
  publicationTypes: state.app.publicationTypes,
  id: state.user.user.id,
  publications: selectPersonPublications(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPublicationFilterYears: (value) => dispatch(SET_PUBLICATION_FILTER_YEARS(value)),
  setPublicationFilterTypes: (value) => dispatch(SET_PUBLICATION_FILTER_PUBLICATIONTYPES(value)),
  fetchPublicationsByPerson: (personId) => dispatch(fetchPersonPublications(personId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPublications);
