import {
  FETCH_ONE_FUNDER,
  FETCH_ONE_FUNDER_SUCCESS,
  FETCH_ONE_FUNDER_ERROR,
  UPDATE_FUNDER,
  UPDATE_FUNDER_SUCCESS,
  UPDATE_FUNDER_ERROR,
} from './funderActions';

const defaultState = {
  isLoading: false,
  data: {},
  error: '',
};

const funder = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ONE_FUNDER:
    case UPDATE_FUNDER:
      return { ...state, isLoading: true };

    case FETCH_ONE_FUNDER_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_FUNDER_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case UPDATE_FUNDER_SUCCESS:
      return { ...state, data: {}, isLoading: false };
    case UPDATE_FUNDER_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default funder;
