import React from 'react';
import { Link } from 'react-router-dom';
import AbstractOfTalk from './AbstractOfTalk';
import Book from './Book';
import BookChapter from './BookChapter';
import JournalArticle from './JournalArticle';
import Patent from './Patent';
import Proceeding from './Proceeding';
import Talk from './Talk';
import Thesis from './Thesis';
import Rump from './Rump';
import Report from './Report';
import ConferenceArticle from './ConferenceArticle';
import config from '../../config/config';
import { withHost } from '../../utils';

const authorFirstAndLastNameFormatter = (author, generateLink = false) => {
  const text = `${author.first.slice(0, 1).toUpperCase()}. ${author.last}`;
  return (
    generateLink && author.author && author.cosic
      ? (
        <Link key={author.author} to={`/iframe?author=${author.author}`}>
          {text}
        </Link>
      )
      : text
  );
};

const authorNameFormatter = (authors, generateLink) => {
  const allButLastAuthor = [];
  const lastAuthor = [];
  if (authors !== null && typeof authors !== 'undefined' && authors.length === 1) {
    const author = authors[0];
    if (author.first && author.last) {
      return [authorFirstAndLastNameFormatter(author, generateLink)];
    }
  } else if (authors !== null && typeof authors !== 'undefined' && authors.length > 0) {
    for (let i = 0; i < authors.length - 1; i += 1) {
      const author = authors[i];
      if (author.first && author.last) {
        if (i > 0) {
          allButLastAuthor.push(', ');
        }
        allButLastAuthor.push(authorFirstAndLastNameFormatter(author, generateLink));
      }
    }
    const author = authors[authors.length - 1];
    if (author.first && author.last) {
      lastAuthor.push(' and ');
      lastAuthor.push(authorFirstAndLastNameFormatter(author, generateLink));
    }
  }

  return [...allButLastAuthor, ...lastAuthor];
};

const authorNameFormatterText = (authors) => authorNameFormatter(authors, false).join('');

const editorNameFormatter = (editors, generateLink) => {
  const editorsList = authorNameFormatter(editors, generateLink);
  if (editors !== null && typeof editors !== 'undefined' && editors.length === 1) {
    editorsList.push(' (Ed.)');
  } else if (editors !== null && typeof editors !== 'undefined' && editors.length > 0) {
    editorsList.push(' (Eds.)');
  }
  return editorsList;
};
const editorNameFormatterText = (authors) => editorNameFormatter(authors, false).join('');
const idFormatter = (id) => (`[${id}]`);

const statusFormatter = (status) => {
  switch (status) {
    case 'published':
      return ('');
    case 'accepted':
      return ('[Accepted] ');
    case 'submitted':
      return ('[Submitted only] ');
    case 'rejected':
      return ('[Rejected] ');
    default:
      return (`[${status}] `);
  }
};

export const urlMakerBackend = (id, type) => withHost(`${config.API_URL}/publications/files/${type}/${id}`);
export const urlMakerFrontend = (id, type, visibility) => withHost(`/files/${visibility ? 'public' : 'private'}/publications/${type}/${id}`);
export const fileUrlMakerBackend = (id, type, category, attribute, fileName) => withHost(`${config.API_URL}/files/getFile/${type}/${id}/${category}/${attribute}/${fileName}`);
export const fileUrlMakerFrontend = (id, type, category, attribute, fileName) => withHost(`/files/${attribute}/${type}/${id}/${category}/${fileName}`);

const getYearFromDate = (dateString) => {
  const d = new Date(Date.parse(dateString));
  return d.getFullYear();
};

const customFilterMatchAll = (option, searchText) => searchText.split(' ').every((r) => option.label.toLowerCase().includes(r.toLowerCase()));

const replaceEdition = (edition, proceedings, from) => {
  let edProceeding = proceedings;
  if (edition && proceedings && proceedings.includes('$E')) {
    edProceeding = edProceeding.replaceAll('$E', edition);
  }
  if (from && proceedings && proceedings.includes('$Y')) {
    edProceeding = edProceeding.replaceAll('$Y', getYearFromDate(from));
  }
  return edProceeding;
};

const checkPubType = (publications, canEdit) => {
  const pubs = [];
  let count = 0;
  if (publications.conferenceArticles) {
    pubs.push(publications.conferenceArticles.map((ca) => {
      count += 1;
      return (
        <ConferenceArticle
          index={count}
          key={ca.id}
          id={ca.id}
          status={ca.status}
          title={ca.title}
          pages={ca.pages}
          from={ca.from}
          proceedingsFrom={ca.proceedingsFrom}
          conference={ca.conference}
          authors={ca.authors}
          editors={ca.editors}
          proceedingTitle={ca.proceedingTitle}
          edition={ca.edition}
          webVersion={ca.webVersion}
          webVersionRecording={ca.webVersionRecording}
          visibility={ca.visibility}
          files={ca.files}
          canEdit={canEdit}
          series={ca.series}
          volume={ca.volume}
          publisher={ca.publisher}
        />
      );
    }));
  }
  if (publications.journalArticles) {
    pubs.push(publications.journalArticles.map((ja) => {
      count += 1;
      return (
        <JournalArticle
          index={count}
          key={ja.id}
          id={ja.id}
          authors={ja.authors}
          title={ja.title}
          status={ja.status}
          journalTitle={ja.journalTitle}
          number={ja.number}
          volume={ja.volume}
          pages={ja.pages}
          date={ja.date}
          from={ja.from}
          webVersion={ja.webVersion}
          webVersionRecording={ja.webVersionRecording}
          visibility={ja.visibility}
          files={ja.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.bookArticles) {
    pubs.push(publications.bookArticles.map((ba) => {
      count += 1;
      return (
        <BookChapter
          index={count}
          key={ba.id}
          id={ba.id}
          title={ba.title}
          status={ba.status}
          publisher={ba.publisher}
          date={ba.date}
          pages={ba.pages}
          authors={ba.authors}
          editors={ba.editors}
          bookTitle={ba.bookTitle}
          visibility={ba.visibility}
          files={ba.files}
          webVersionRecording={ba.webVersionRecording}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.theses) {
    pubs.push(publications.theses.map((th) => {
      count += 1;
      return (
        <Thesis
          index={count}
          key={th.id}
          id={th.id}
          title={th.title}
          status={th.status}
          type={th.type}
          pages={th.pages}
          promotors={th.promotors}
          authors={th.authors}
          institution={th.institution}
          date={th.date}
          webVersion={th.webVersion}
          webVersionRecording={th.webVersionRecording}
          visibility={th.visibility}
          files={th.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.proceedings) {
    pubs.push(publications.proceedings.map((pr) => {
      count += 1;
      return (
        <Proceeding
          index={count}
          key={pr.id}
          id={pr.id}
          volume={pr.volume}
          appeared={pr.appeared}
          publisher={pr.publisher}
          editors={pr.editors}
          title={pr.proceedings}
          status={pr.status}
          edition={pr.edition}
          city={pr.city}
          country={pr.country}
          seriesName={pr.seriesName}
          webVersion={pr.webVersion}
          webVersionRecording={pr.webVersionRecording}
          visibility={pr.visibility}
          files={pr.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.books) {
    pubs.push(publications.books.map((b) => {
      count += 1;
      return (
        <Book
          index={count}
          key={b.id}
          id={b.id}
          title={b.title}
          status={b.status}
          publisher={b.publisher}
          date={b.date}
          pages={b.pages}
          authors={b.authors}
          editors={b.editors}
          seriesName={b.seriesName}
          volume={b.volume}
          webVersion={b.webVersion}
          webVersionRecording={b.webVersionRecording}
          visibility={b.visibility}
          files={b.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.reports) {
    pubs.push(publications.reports.map((rp) => {
      count += 1;
      return (
        <Report
          index={count}
          key={rp.id}
          id={rp.id}
          title={rp.title}
          status={rp.status}
          pages={rp.pages}
          date={rp.date}
          authors={rp.authors}
          note={rp.note}
          webVersion={rp.webVersion}
          webVersionRecording={rp.webVersionRecording}
          visibility={rp.visibility}
          files={rp.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.abstractOfTalks) {
    pubs.push(publications.abstractOfTalks.map((abstractOfTalk) => {
      count += 1;
      return (
        <AbstractOfTalk
          key={abstractOfTalk.id}
          index={count}
          id={abstractOfTalk.id}
          title={abstractOfTalk.title}
          status={abstractOfTalk.status}
          event={abstractOfTalk.event}
          city={abstractOfTalk.city}
          state={abstractOfTalk.state}
          country={abstractOfTalk.country}
          from={abstractOfTalk.from}
          proceedingsFrom={abstractOfTalk.proceedingsFrom}
          edition={abstractOfTalk.edition}
          pages={abstractOfTalk.pages}
          authors={abstractOfTalk.authors}
          proceedings={abstractOfTalk.proceedings}
          publisher={abstractOfTalk.publisher}
          volume={abstractOfTalk.volume}
          webVersion={abstractOfTalk.webVersion}
          webVersionRecording={abstractOfTalk.webVersionRecording}
          visibility={abstractOfTalk.visibility}
          files={abstractOfTalk.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.talks) {
    pubs.push(publications.talks.map((t) => {
      count += 1;
      return (
        <Talk
          index={count}
          key={t.id}
          id={t.id}
          title={t.title}
          status={t.status}
          event={t.event}
          city={t.city}
          country={t.country}
          from={t.from}
          authors={t.authors}
          webVersion={t.webVersion}
          webVersionRecording={t.webVersionRecording}
          visibility={t.visibility}
          files={t.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.rumps) {
    pubs.push(publications.rumps.map((r) => {
      count += 1;
      return (
        <Rump
          index={count}
          key={r.id}
          id={r.id}
          title={r.title}
          status={r.status}
          pages={r.pages}
          from={r.from}
          edition={r.edition}
          authors={r.authors}
          proceedings={r.proceedings}
          webVersion={r.webVersion}
          webVersionRecording={r.webVersionRecording}
          visibility={r.visibility}
          files={r.files}
          canEdit={canEdit}
        />
      );
    }));
  }
  if (publications.patents) {
    pubs.push(
      publications.patents.map(
        (patent) => {
          count += 1;
          return (
            <Patent
              key={patent.id}
              index={count}
              id={patent.id}
              title={patent.title}
              status={patent.status}
              description={patent.description}
              date={patent.date}
              authors={patent.authors}
              number={patent.number}
              applicant={patent.applicant}
              webVersion={patent.webVersion}
              webVersionRecording={patent.webVersionRecording}
              visibility={patent.visibility}
              files={patent.files}
              canEdit={canEdit}
            />
          );
        },
      ),
    );
  }

  return pubs;
};

export {
  authorNameFormatter, authorNameFormatterText, idFormatter, statusFormatter,
  // eslint-disable-next-line max-len
  checkPubType, getYearFromDate, replaceEdition, editorNameFormatter, editorNameFormatterText, customFilterMatchAll,
};
