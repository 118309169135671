import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app/appReducer';
import people from './people/peopleReducer';
import person from './person/personReducer';
import project from './project/projectReducer';
import events from './events/eventsReducer';
import event from './event/eventReducer';
import countries from './countries/countriesReducer';
import country from './country/countryReducer';
import partners from './partners/partnersReducer';
import partner from './partner/partnerReducer';
import funders from './funders/fundersReducer';
import funder from './funder/funderReducer';
import types from './types/typesReducer';
import type from './type/typeReducer';
import projectCalls from './projectCalls/projectCallsReducer';
import projectCall from './projectCall/projectCallReducer';
import venues from './venues/venuesReducer';
import venue from './venue/venueReducer';
import publication from './publication/publicationReducer';
import dashboard from './dashboard/dashboardReducer';
import peopleTypes from './peopleType/peopleTypeReducer';
import user from './user/userReducer';
import committeeMembers from './committeeMembers/reducer';
import profile from './profile/profileReducer';

import publicationAbstractOfTalk from '../container/admin/publications/AbstractOfTalk/reducer';
import publicationBook from '../container/admin/publications/Book/reducer';
import publicationBookchapter from '../container/admin/publications/Bookchapter/reducer';
import publicationConferencePaper from '../container/admin/publications/ConferencePaper/reducer';
import publicationJournal from '../container/admin/publications/Journal/reducer';
import publicationPatent from '../container/admin/publications/Patent/reducer';
import publicationProceeding from '../container/admin/publications/Proceeding/reducer';
import publicationReport from '../container/admin/publications/Report/reducer';
import publicationTalk from '../container/admin/publications/Talk/reducer';
import publicationThese from '../container/admin/publications/These/reducer';

const rootReducer = (history) => combineReducers({
  app,
  people,
  person,
  project,
  events,
  event,
  countries,
  country,
  partners,
  partner,
  funders,
  funder,
  types,
  projectCalls,
  projectCall,
  type,
  router: connectRouter(history),
  venues,
  venue,
  publication,
  dashboard,
  peopleTypes,
  user,
  committeeMembers,
  profile,
  publicationAbstractOfTalk,
  publicationBook,
  publicationBookchapter,
  publicationConferencePaper,
  publicationJournal,
  publicationPatent,
  publicationProceeding,
  publicationReport,
  publicationTalk,
  publicationThese,
});

export default rootReducer;
