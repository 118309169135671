export const FETCH_ONE_COUNTRY = 'FETCH_ONE_COUNTRY';
export const FETCH_ONE_COUNTRY_SUCCESS = 'FETCH_ONE_COUNTRY_SUCCESS';
export const FETCH_ONE_COUNTRY_ERROR = 'FETCH_ONE_COUNTRY_ERROR';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_ERROR = 'UPDATE_COUNTRY_ERROR';

export const fetchOneCountry = (data) => ({ type: FETCH_ONE_COUNTRY, data });
export const fetchOneCountrySuccess = (data) => ({ type: FETCH_ONE_COUNTRY_SUCCESS, data });
export const fetchOneCountryError = (error) => ({ type: FETCH_ONE_COUNTRY_ERROR, error });

export const updateCountry = (data) => ({ type: UPDATE_COUNTRY, data });
export const updateCountrySuccess = (data) => ({ type: UPDATE_COUNTRY_SUCCESS, data });
export const updateCountryError = (error) => ({ type: UPDATE_COUNTRY_ERROR, error });
