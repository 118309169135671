import yup from '../../../vendor/yup';

const validationSchema = yup.object().shape({
  title: yup.string().required('A title is mandatory'),
  description: yup.string().required('A description is mandatory').nullable(),
  organizers: yup.array()
    .required('There must be at least one organizer for the event.')
    .nullable(),
  date: yup.date().required('A start date is required.').nullable(),
  endDate: yup.date().required('An end date is required.').nullable().min(yup.ref('date'), "end date can't be before from date"),
  participant: yup.number().required('A number of participant is required.').nullable(),
  type: yup.string().required('A type must be picked.'),
  city: yup.string().required('A city must be picked.').nullable(),
  location: yup.string().required('A location must be picked.').nullable(),
  country: yup.string().required('A country must be picked.'),
  start: yup.string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g,
      {
        message: 'Invalid time format',
        excludeEmptyString: true,
      },
    ),
  end: yup.string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g,
      {
        message: 'Invalid time format',
        excludeEmptyString: true,
      },
    ),
});

export default validationSchema;
