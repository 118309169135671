import {
  CREATE_CONFERENCE_PAPER,
  CREATE_CONFERENCE_PAPER_ERROR,
  CREATE_CONFERENCE_PAPER_SUCCESS,
  FETCH_CONFERENCE_PAPER,
  FETCH_CONFERENCE_PAPER_ERROR,
  FETCH_CONFERENCE_PAPER_SUCCESS,
  LOAD_CONFERENCE_PAPER_REQUIREMENTS_SUCCESS,
  UPDATE_CONFERENCE_PAPER,
  UPDATE_CONFERENCE_PAPER_ERROR,
  UPDATE_CONFERENCE_PAPER_SUCCESS,
} from './actions';
import { calculatePageCount } from '../../../../utils';

const defaultState = {
  isLoading: false,
  details: {},
  error: '',
};

const dehydrateConferencePaperRespones = (response) => ({
  types: 'conferencePaper',
  title: response.title,
  status: response.status,
  teams: response.teams.map((team) => ({
    value: team.id, label: `${team.name}`,
  })),
  primaryAuthor: response.firstAuthor.id,
  authors: response.authors.map((author) => ({
    value: author.id, label: `${author.last}, ${author.first}`, order: author.order,
  })),
  date: response.date,
  acceptedDate: response.acceptedDate,
  doi: response.doi,
  series: response.series,
  webVersion: response.webVersion,
  webVersionSourceCode: response.webVersionSourceCode,
  webVersionRecording: response.webVersionRecording,
  visibility: response.visibility,
  hideFromPublic: response.hideFromPublic,
  dataset: response.dataset,
  datasetRDRStored: response.datasetRDRStored,
  earlyAccess: response.earlyAccess,
  proceedingTitle: response.proceedingTitle,
  from: response.publication.from,
  to: response.publication.to,
  proceedingsId: response.publication.id,
  conference: response.publication.conference.id,
  publisher: response.publisher,
  state: response.publication.state,
  volume: response.publication.volume,
  city: response.publication.city,
  country: response.publication.country,
  pages: response.pages,
  pageCount: response.pages ? calculatePageCount(response.pages) : 0,
  complete: response.complete,
  presentedAtRump: response.presentedAtRump,
  reviewed: response.reviewed,
  projects: response.projects,
  editors: response.editors.map((editor) => ({
    value: editor.id, label: `${editor.last}, ${editor.first}`,
  })),
});

const publicationConferencePaper = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_CONFERENCE_PAPER_SUCCESS:
    case FETCH_CONFERENCE_PAPER_SUCCESS:
      return {
        ...state,
        details: dehydrateConferencePaperRespones(action.payload),
        isLoading: false,
      };

    case CREATE_CONFERENCE_PAPER_ERROR:
    case UPDATE_CONFERENCE_PAPER_ERROR:
    case FETCH_CONFERENCE_PAPER_ERROR:
      return { ...state, error: action.payload.error, isLoading: false };

    case CREATE_CONFERENCE_PAPER_SUCCESS:
    case LOAD_CONFERENCE_PAPER_REQUIREMENTS_SUCCESS:
      return { ...state, details: {}, isLoading: false };

    case CREATE_CONFERENCE_PAPER:
    case FETCH_CONFERENCE_PAPER:
    case UPDATE_CONFERENCE_PAPER:
      return { ...state, details: {}, isLoading: true };

    default:
      return { ...state };
  }
};

export default publicationConferencePaper;
