import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Button, Table, Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loader } from '../../index';
import {
  MUTATE_PERSON_ADD_INTERNSHIP,
  MUTATE_PERSON_REMOVE_INTERNSHIP,
  MUTATE_PERSON_UPDATE_INTERNSHIP,
} from '../../../state/person/personActions';

const EditPeopleInternshipsForm = (props) => {
  const {
    loading,
    personid,
    countries,
    partners,
    internshipOrResearches,
    mutateAddInternship,
    mutateRemoveInternship,
    mutateUpdateInternship,
  } = props;
  const [editVals, setEditVals] = useState({
    personid,
    id: -1,
    description: '',
    startDate: '',
    endDate: '',
    partner: '',
    city: '',
    country: '',
  });
  const SignupSchema = Yup.object().shape({
    description: Yup.string()
      .required('description is mandatory'),
    startDate: Yup.string()
      .required('Start date is mandatory'),
    endDate: Yup.string()
      .required('End date is mandatory'),
    partner: Yup.string()
      .required('Partner is mandatory'),
    city: Yup.string()
      .required('City is mandatory'),
    country: Yup.string()
      .required('Country is mandatory'),
  });
  const [show, setShow] = useState(false);
  const [awardIdToDelete, setAwardIdToDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const selectaRow = (idx) => {
    if (idx === -1) {
      setEditVals({
        personid,
        id: -1,
        description: '',
        startDate: '',
        endDate: '',
        partner: '',
        city: '',
        country: '',
      });
    } else {
      setEditVals({
        personid,
        id: internshipOrResearches[idx].id,
        description: internshipOrResearches[idx].description,
        startDate: moment(internshipOrResearches[idx].startDate).format('YYYY-MM-DD'),
        endDate: moment(internshipOrResearches[idx].endDate).format('YYYY-MM-DD'),
        partner: internshipOrResearches[idx].partner.id,
        city: internshipOrResearches[idx].city,
        country: internshipOrResearches[idx].country.id || undefined,
      });
    }
    setShow(true);
  };
  const handleCloseEdit = () => {
    setShow(false);
  };

  const displayError = (errorText, isTouched) => errorText && isTouched && (<div className="error-message">{errorText}</div>);

  const displayForm = () => (
    <Formik
      enableReinitialize
      initialValues={{
        personid,
        id: editVals.id,
        startDate: (editVals.startDate) ? moment(editVals.startDate).format('YYYY-MM-DD') : '',
        endDate: (editVals.endDate) ? moment(editVals.endDate).format('YYYY-MM-DD') : '',
        partner: editVals.partner,
        city: editVals.city,
        country: editVals.country,
        description: editVals.description,
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        if (values.id === -1) {
          mutateAddInternship(values);
        } else {
          mutateUpdateInternship(values);
        }
        handleCloseEdit();
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Start date</Form.Label>
              <Datetime
                name="startDate"
                value={values.startDate}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(val) => setFieldValue('startDate', moment(val).format('YYYY-MM-DD'))}
                onClose={(val) => setFieldValue('startDate', moment(val).format('YYYY-MM-DD'))}
              />
              {displayError(errors.startDate, touched.startDate)}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>End date</Form.Label>
              <Datetime
                name="endDate"
                value={values.endDate}
                dateFormat="YYYY-MM-DD"
                timeFormat={false}
                onChange={(val) => setFieldValue('endDate', moment(val).format('YYYY-MM-DD'))}
                onClose={(val) => setFieldValue('endDate', moment(val).format('YYYY-MM-DD'))}
              />
              {displayError(errors.endDate, touched.endDate)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Partner</Form.Label>
              <Form.Control
                as="select"
                name="partner"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.partner}
                className="form-control"
              >
                <option value="-1">Select a partner..</option>
                {partners.map(
                  (el) => <option value={el.id} key={`partner_${el.id}`}>{el.name}</option>,
                )}
              </Form.Control>
              {displayError(errors.partner, touched.partner)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                className="form-control"
              />
              {displayError(errors.city, touched.city)}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.country}
                className="form-control"
              >
                <option value="-1">Select a country...</option>
                {countries.map(
                  (cty) => <option value={cty.id} key={`countries_${cty.id}`}>{cty.name}</option>,
                )}
              </Form.Control>
              {displayError(errors.country, touched.country)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                className="form-control"
              />
              {displayError(errors.description, touched.description)}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <Button type="submit" className="btn btn-success" disabled={isSubmitting} style={{ width: '100%' }}>
                Save
              </Button>
            </Form.Group>
          </Row>
        </Form>
      )}
    </Formik>
  );

  const wpModalFormEdit = () => (
    <Modal
      show={show}
      onHide={handleCloseEdit}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>{displayForm()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const handleShowDelete = (idx) => {
    setAwardIdToDelete(idx);
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    setAwardIdToDelete(null);
  };
  const handleDelete = () => {
    // mutateRemoveAward({ awardid: awards[awardIdToDelete].id, personid });
    mutateRemoveInternship({ id: internshipOrResearches[awardIdToDelete].id, personid });
    handleCloseDelete();
  };
  const wpModalFormDelete = () => (
    <Modal
      show={showDelete}
      onHide={handleCloseDelete}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Delete internshipr or research (
          {(awardIdToDelete !== null) ? internshipOrResearches[awardIdToDelete].description : ''}
          )?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>This action is permanent.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDelete}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => handleDelete()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {loading
        ? <Loader />
        : (
          <div>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                  <th>Start date</th>
                  <th>End Date</th>
                  <th>Partner</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {internshipOrResearches.map((el, idx) => (
                  <tr key={`internships_${el.id}`}>
                    <td>{el.id}</td>
                    <td>{el.description}</td>
                    <td>{(el.startDate) ? moment(el.startDate).format('ddd Do of MMMM YYYY') : ''}</td>
                    <td>{(el.endDate) ? moment(el.endDate).format('ddd Do of MMMM YYYY') : ''}</td>
                    <td>{el.partner.name}</td>
                    <td>{el.city}</td>
                    <td>{el.country.name}</td>
                    <td>
                      <Button className="btn btn-primary fixed-size-button" onClick={() => selectaRow(idx)}>
                        Edit
                      </Button>
                      <Button className="btn btn-danger fixed-size-button" onClick={() => handleShowDelete(idx)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    <Button className="btn btn-primary fixed-size-button" onClick={() => selectaRow(-1)}>
                      New
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {wpModalFormDelete()}
            {wpModalFormEdit()}
          </div>
        )}
    </div>
  );
};

EditPeopleInternshipsForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  mutateAddInternship: PropTypes.func.isRequired,
  mutateRemoveInternship: PropTypes.func.isRequired,
  mutateUpdateInternship: PropTypes.func.isRequired,
  personid: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  internshipOrResearches: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.any).isRequired,
};

EditPeopleInternshipsForm.defaultProps = {
  internshipOrResearches: [],
  personid: '-1',

};

const mapStateToProps = (state) => ({
  loading: state.person.isLoading,
  personid: state.person.data.id,
  internshipOrResearches: state.person.data.internshipOrResearches,
  countries: state.countries.data,
  partners: state.partners.data,
});

const mapDispatchToProps = (dispatch) => ({
  mutateAddInternship: (data) => dispatch(MUTATE_PERSON_ADD_INTERNSHIP(data)),
  mutateRemoveInternship: (data) => dispatch(MUTATE_PERSON_REMOVE_INTERNSHIP(data)),
  mutateUpdateInternship: (data) => dispatch(MUTATE_PERSON_UPDATE_INTERNSHIP(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPeopleInternshipsForm);
