export const QueryAllPartners = `
{
  partners {
    id
    name
    country
    projects { id name }
    type
  }
}`;

export const QueryOnePartner = (id) => `
query { 
  partner (id:"${id}"){ 
    id 
    name
    country
    contact
    type
  }
}`;

export const MutateCreatePartner = (partner) => `
mutation {
    createPartner (
      partner: {
        name:"${partner.name}",
        country: "${partner.country ? partner.country : ''}",
        contact: "${partner.contact ? partner.contact : ''}",
        type: "${partner.type}"
      }
    ) {
      id
      name
      country
      contact
      type
    }
}`;

export const MutateUpdatePartner = (partner) => `
mutation {
    updatePartner (
      id:"${partner.id}",
      partner: {
        name:"${partner.name}",
        country: "${partner.country ? partner.country : ''}",
        contact: "${partner.contact ? partner.contact : ''}",
        type: "${partner.type}"
      }
    ) {
      id
      name
      country
      contact
      type
    }
}`;

export const MutateDeletePartner = (partnerId) => `
mutation {
  deletePartner(id:"${partnerId}")
}`;
