import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { merge } from 'lodash/fp';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import Select from 'react-select';
import {
  Col,
  Button,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import { talkValidationSchema as validationSchema } from '../ValidationSchema';
import { talkTypeOptions } from '../../../../config/optionValues';
import { Loader } from '../../../../component';
import GeneralDataForm from '../GeneralDataForm';
import {
  createTalk as createTalkAction,
  loadTalkRequirements as loadTalkRequirementsAction,
  updateTalk as updateTalkAction,
} from './actions';
import {
  selectIsLoading,
  selectProjects,
  selectPublicationTalkDetails,
} from './selectors';
import {
  selectConferences,
  selectPublicationAuthors,
} from '../../../../state/publication/selectors';
import {
  selectAllCountriesAsOptions,
} from '../../../../state/countries/selectors';

const Talk = ({
  authors,
  conferences,
  countries,
  createTalk,
  isLoading,
  isResearcher,
  loadTalkRequirements,
  projects,
  talkInfo,
  updateTalk,
}) => {
  const { id } = useParams();
  const [reloads, setReloads] = useState(0);

  const [showConferences, setShowConferences] = useState(false);

  useEffect(() => {
    loadTalkRequirements({ id, reset: true });
  }, [id, loadTalkRequirements]);

  useEffect(() => {
    if (reloads > 0) {
      loadTalkRequirements({ reset: false });
    }
  }, [reloads, loadTalkRequirements]);

  const initialFormValues = merge({
    types: 'talk',
    title: '',
    status: null,
    teams: [],
    primaryAuthor: null,
    authors: [],
    date: '',
    acceptedDate: '',
    doi: '',
    webVersion: '',
    webVersionSourceCode: '',
    webVersionRecording: '',
    visibility: false,
    hideFromPublic: false,
    dataset: null,
    datasetRDRStored: null,
    presenters: [],
    country: null,
    city: '',
    typeEvent: null,
    event: null,
    eventSelectInput: null,
    eventFreeInput: '',
  }, talkInfo);

  const handleTypeEventChange = (val, setFieldValue) => {
    setFieldValue('typeEvent', val.value);
    setShowConferences(val.value === 'Invited talk at a conference/workshop');
  };

  const handleTalkSave = (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      event: showConferences ? values.eventSelectInput : values.eventFreeInput,
      authors: values.authors.map((author) => author.value),
      teams: values.teams.map((team) => team.value),
      presenters: values.presenters.map((presenters) => presenters.value),
      type: 'talk',
    };

    if (id) {
      updateTalk({ ...updatedValues, id });
    } else {
      createTalk(updatedValues);
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {!isLoading ? (
            <Formik
              enableReinitialize
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleTalkSave}
            >
              {({
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <GeneralDataForm
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    type="talk"
                    id={id}
                    reload={() => setReloads(reloads + 1)}
                  />
                  <Card className="filter-card">
                    <Card.Header>Talk specific</Card.Header>
                    <Card.Body>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Presenters</Form.Label>
                          <Form.Text muted>
                            {/* eslint-disable-next-line max-len */}
                            You can use the name of the presenter(s) to fill in the primary author box (see above) if there is no author
                          </Form.Text>
                          <Select
                            name="presenters"
                            isMulti
                            onChange={(a) => setFieldValue('presenters', a)}
                            options={authors}
                            value={values.presenters}
                            className={touched.presenters && errors.presenters ? 'error' : null}
                          />
                          <div className="error-message">
                            <ErrorMessage name="presenter" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            name="city"
                            onChange={handleChange}
                            className={touched.city && errors.city ? 'error' : null}
                            value={values.city}
                          />
                          <div className="error-message">
                            <ErrorMessage name="city" />
                          </div>
                        </Col>
                        <Col>
                          <Form.Label>Country</Form.Label>
                          <Select
                            name="country"
                            className={touched.country && errors.country ? 'error' : null}
                            onChange={(event) => setFieldValue('country', event.value)}
                            options={countries}
                            value={countries && values.country ? (
                              countries.find((country) => country.value === values.country)
                            ) : ''}
                          />
                          <div className="error-message">
                            <ErrorMessage name="country" />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row style={{ marginTop: '2rem' }}>
                        <Col>
                          <Form.Label>Type of Event</Form.Label>
                          <Select
                            className={touched.typeEvent && errors.typeEvent ? 'error' : null}
                            name="typeEvent"
                            onChange={(val) => handleTypeEventChange(val, setFieldValue)}
                            options={talkTypeOptions}
                            value={talkTypeOptions && values.typeEvent ? (
                              (() => {
                                // nothing trucare, only a little foefelare ...
                                setShowConferences(values.typeEvent === 'Invited talk at a conference/workshop');
                                return talkTypeOptions.find(
                                  (type) => type.value === values.typeEvent,
                                );
                              })()
                            ) : ''}
                          />
                          <div className="error-message">
                            <ErrorMessage name="typeEvent" />
                          </div>
                        </Col>
                      </Form.Row>
                      {showConferences && (
                        <Form.Row style={{ marginTop: '2rem' }}>
                          <Col>
                            <Form.Label>Venue</Form.Label>
                            <Select
                              className={touched.eventSelectInput && errors.eventSelectInput ? 'error' : null}
                              name="eventSelectInput"
                              onChange={(event) => setFieldValue('eventSelectInput', event.value)}
                              options={conferences}
                              value={conferences && values.event ? (
                              // eslint-disable-next-line max-len
                                conferences.find((conference) => conference.value === values.eventSelectInput)
                              ) : ''}
                            />
                            <div className="error-message">
                              <ErrorMessage name="eventSelectInput" />
                            </div>
                          </Col>
                        </Form.Row>
                      )}
                      {showConferences === false && (
                        <Form.Row style={{ marginTop: '2rem' }}>
                          <Col>
                            <Form.Label>Specify name of event</Form.Label>
                            <Form.Control
                              className={touched.eventFreeInput && errors.eventFreeInput ? 'error' : null}
                              name="eventFreeInput"
                              onChange={handleChange}
                              value={values.eventFreeInput}
                            />
                            <div className="error-message">
                              <ErrorMessage name="eventFreeInput" />
                            </div>
                          </Col>
                        </Form.Row>
                      )}
                    </Card.Body>
                  </Card>
                  <Form.Row style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn btn-success ml-auto"
                      disabled={isSubmitting}
                      block
                    >
                      {id ? 'Update' : 'Create'}
                    </Button>
                  </Form.Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
      {id && (
        <Tabs
          id={id}
          type="talk"
          isResearcher={isResearcher}
          projects={projects}
          pdfVisibility={talkInfo.visibility}
        />
      )}
    </Container>
  );
};

Talk.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  conferences: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  createTalk: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isResearcher: PropTypes.bool.isRequired,
  loadTalkRequirements: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  talkInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  updateTalk: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  authors: selectPublicationAuthors,
  countries: selectAllCountriesAsOptions,
  conferences: selectConferences,
  isLoading: selectIsLoading,
  projects: selectProjects,
  talkInfo: selectPublicationTalkDetails,
});

const mapDispatchToProps = {
  createTalk: createTalkAction,
  loadTalkRequirements: loadTalkRequirementsAction,
  updateTalk: updateTalkAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Talk);
