import {
  FETCH_ONE_PARTNER,
  FETCH_ONE_PARTNER_ERROR,
  FETCH_ONE_PARTNER_SUCCESS,
  UPDATE_PARTNER,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_SUCCESS,
} from './partnerActions';

const defaultState = {
  isLoading: true,
  data: {},
  error: '',
};

const partner = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ONE_PARTNER:
    case UPDATE_PARTNER:
      return { ...state, isLoading: true };

    case FETCH_ONE_PARTNER_SUCCESS:
      return { ...state, data: action.data, isLoading: false };
    case FETCH_ONE_PARTNER_ERROR:
      return { ...state, error: action.error, isLoading: false };

    case UPDATE_PARTNER_SUCCESS:
      return { ...state, data: {}, isLoading: false };
    case UPDATE_PARTNER_ERROR:
      return { ...state, error: action.error, isLoading: false };

    default:
      return state;
  }
};

export default partner;
